import { useIdle } from 'react-use'

const MAX_TIMEOUT = 2147483647

export const useConditionalIdle = (isEnabled: boolean, ...args: Parameters<typeof useIdle>) => {
  const [timeout, initialState, events] = args
  // "disabling" idle state by an extra long timeout
  const isIdle = useIdle(isEnabled ? timeout : MAX_TIMEOUT, initialState, events)

  // if hook is disabled - should not be idle
  return isEnabled ? isIdle : false
}
