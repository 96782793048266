import { projectApi } from 'api'
import { ProjectAttributeClass } from 'types/projects/attributes'

export interface ProjectViewSettings {
  id: string
  classification: ProjectAttributeClass
  name: string
  enabled: boolean
}

export const fetchProjectsViewSettingsApi = () =>
  projectApi.get<{
    data: ProjectViewSettings[]
  }>('/project-attribute-view-settings/')
