import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showTaskDetailsModal,
  useShowTaskDetailsModal,
} from 'pages/project/components/tasks/components/taskDetailsModal/TaskDetailsModal'
import { DetailsModalType } from 'types/common/utils'

export const useOpenTaskPreviewModal = () => {
  const { visible, hide } = useShowTaskDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, id } = Object.fromEntries(params.entries()) as { view: string; id: string }

    if (view && view === DetailsModalType.TASK_DETAILS_PREVIEW) {
      showTaskDetailsModal({ taskId: id, onClose: setParams })
    } else visible && hide()
  }, [hide, params, setParams, visible])
}
