import { AcceptConfig } from '@platform-ui-kit/components-library'

export const acceptConfigToMap = (acceptConfig: AcceptConfig) => {
  return Object.entries(acceptConfig).reduce((acc, [mimeType, extensions]) => {
    extensions.forEach(ext => {
      acc[ext] = mimeType
    })

    return acc
  }, {} as Record<string, string>)
}

export const getFileType = (fileName: string, extensionsMap: Record<string, string> = {}): string => {
  const extension = fileName.split('.').pop() || ''
  const fileType = extensionsMap[`.${extension}`]

  if (fileType) {
    return fileType
  }

  return 'application/octet-stream'
}
