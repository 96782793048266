import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showPhaseDetailsModal,
  useShowPhaseDetailsModal,
} from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModal'
import { DetailsModalType } from 'types/common/utils'
import { Project } from 'types/projects/projects'

export const useOpenPhasePreviewModal = (project: Project) => {
  const { visible, hide } = useShowPhaseDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, phaseId, appId, activityId } = Object.fromEntries(params.entries()) as {
      view: string
      phaseId: string
      activityId?: string
      appId?: string
    }

    if (view && view === DetailsModalType.PHASE_DETAILS_PREVIEW) {
      showPhaseDetailsModal({
        phaseId,
        appId,
        activityId,
        onClose: setParams,
        projectId: project.id,
        isWrikeConnected: !!project.wrike?.isConnected,
      })
    } else visible && hide()
  }, [hide, params, project.id, project.wrike?.isConnected, setParams, visible])
}
