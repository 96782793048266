// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L8gUk{margin-top:8px}.L8gUk .DraftEditor-root{box-sizing:border-box;padding:9px 12px;color:var(--wpp-grey-color-1000);font-weight:400;font-size:14px;font-family:var(--wpp-font-family);font-style:normal;line-height:22px;border:1px solid var(--wpp-grey-color-600);border-bottom:0;border-radius:8px 8px 0 0}.L8gUk .DraftEditor-root,.L8gUk .DraftEditor-root > div,.L8gUk .DraftEditor-root > div > div{min-height:130px}.AQmmE{--wpp-typography-color: var(--wpp-grey-color-800);gap:1ch}.AQmmE.KzlQC{--wpp-typography-color: var(--wpp-danger-color-500)}`, "",{"version":3,"sources":["webpack://./src/components/richText/textEditor/TextEditor.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CAEA,yBACE,qBAAA,CACA,gBAAA,CACA,gCAAA,CACA,eAAA,CACA,cAAA,CACA,kCAAA,CACA,iBAAA,CACA,gBAAA,CACA,0CAAA,CACA,eAAA,CACA,yBAAA,CAGF,6FAGE,gBAAA,CAIJ,OACE,iDAAA,CAEA,OAAA,CAEA,aACE,mDAAA","sourcesContent":[".textEditorWrapper {\n  margin-top: 8px;\n\n  :global(.DraftEditor-root) {\n    box-sizing: border-box;\n    padding: 9px 12px;\n    color: var(--wpp-grey-color-1000);\n    font-weight: 400;\n    font-size: 14px;\n    font-family: var(--wpp-font-family);\n    font-style: normal;\n    line-height: 22px;\n    border: 1px solid var(--wpp-grey-color-600);\n    border-bottom: 0;\n    border-radius: 8px 8px 0 0;\n  }\n\n  :global(.DraftEditor-root),\n  :global(.DraftEditor-root > div),\n  :global(.DraftEditor-root > div > div) {\n    min-height: 130px;\n  }\n}\n\n.charactersLimit {\n  --wpp-typography-color: var(--wpp-grey-color-800);\n\n  gap: 1ch;\n\n  &.isError {\n    --wpp-typography-color: var(--wpp-danger-color-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textEditorWrapper": `L8gUk`,
	"charactersLimit": `AQmmE`,
	"isError": `KzlQC`
};
export default ___CSS_LOADER_EXPORT___;
