import { useEffect, useRef } from 'react'

import { useThrottleFn } from 'hooks/useThrottleFn'

enum BOX {
  OFFSET = 200,
  SCROLL = 10,
  TOP_OFFSET = 100,
}

export const useDragScrolling = () => {
  const isScrolling = useRef(false)

  const verticalScrollElement = useRef<HTMLElement>(document.querySelector('html')!)
  const horizontalScrollElement = useRef<HTMLElement>(document.getElementById('horizontal-scroll-dnd')!)

  useEffect(() => {
    verticalScrollElement.current = document.querySelector('html')!
    horizontalScrollElement.current = document.getElementById('horizontal-scroll-dnd')!
  }, [])

  const goDown = () => {
    verticalScrollElement.current.scrollTop += BOX.SCROLL

    const { offsetHeight, scrollTop, scrollHeight } = verticalScrollElement.current
    const isScrollEnd = offsetHeight + scrollTop >= scrollHeight

    if (isScrolling.current && !isScrollEnd) {
      window.requestAnimationFrame(goDown)
    }
  }

  const goUp = () => {
    verticalScrollElement.current.scrollTop -= BOX.SCROLL

    if (isScrolling.current && verticalScrollElement.current.scrollTop > BOX.TOP_OFFSET) {
      window.requestAnimationFrame(goUp)
    }
  }

  const goRight = () => {
    horizontalScrollElement.current.scrollLeft += BOX.SCROLL

    const { clientWidth, scrollLeft, scrollWidth } = horizontalScrollElement.current
    const isScrollEnd = clientWidth + scrollLeft >= scrollWidth

    if (isScrolling.current && !isScrollEnd) {
      window.requestAnimationFrame(goRight)
    }
  }

  const goLeft = () => {
    horizontalScrollElement.current.scrollLeft -= BOX.SCROLL

    if (isScrolling.current && horizontalScrollElement.current.scrollLeft > 0) {
      window.requestAnimationFrame(goLeft)
    }
  }

  const onDragOver = (event: DragEvent) => {
    const isMouseOnTop = event.clientY < BOX.OFFSET
    const isMouseOnDown = window.innerHeight - event.clientY < BOX.OFFSET
    const isMouseOnLeft = event.clientX < BOX.OFFSET
    const isMouseOnRight = window.innerWidth - event.clientX < BOX.OFFSET

    if (!isScrolling.current && (isMouseOnTop || isMouseOnDown || isMouseOnLeft || isMouseOnRight)) {
      isScrolling.current = true
      if (isMouseOnTop) goUp()
      if (isMouseOnDown) goDown()
      if (isMouseOnLeft) goLeft()
      if (isMouseOnRight) goRight()
    } else if (!isMouseOnTop && !isMouseOnDown && !isMouseOnLeft && !isMouseOnRight) {
      isScrolling.current = false
    }
  }

  const debounce = useThrottleFn(onDragOver, 300)

  const listenWindow = () => {
    window.addEventListener('dragover', debounce, false)
  }

  const removeListenerWindow = () => {
    window.removeEventListener('dragover', debounce, false)
    isScrolling.current = false
  }

  return {
    listenWindow,
    removeListenerWindow,
  }
}
