import fastDeepEqual from 'fast-deep-equal/es6'

import { initialProjectFilters } from 'pages/dashboard/Dashboard'
import { ProjectFilter } from 'types/projects/projects'

export const useIsFiltersActive = (filters: ProjectFilter) => {
  const { search, workspace } = filters

  const isOtherFilterSet = (['ownership', 'type', 'status'] as const).some(
    key => !fastDeepEqual(filters[key], initialProjectFilters[key]),
  )
  return !!search || isOtherFilterSet || Array.isArray(workspace)
}
