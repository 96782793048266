import { WppButton, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useIntegrationsApi } from 'api/tenant/queries/useIntegrationsApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { IntegrationCard } from 'pages/settings/integrations/components/IntegrationCard'
import styles from 'pages/settings/integrations/Integrations.module.scss'
import { getIntegrationsUrl } from 'utils/common'

export const IntegrationsTab = () => {
  const { t } = useTranslation()
  const { data: integrations, isLoading: isIntegrationsLoading } = useIntegrationsApi({ staleTime: 60 * 1000 })

  const availableIntegrations = useMemo(() => integrations.filter(({ available }) => available), [integrations])

  const isEmpty = useMemo(
    () => !integrations || integrations.length === 0 || availableIntegrations.length === 0,
    [integrations, availableIntegrations],
  )

  const navigateToIntegrations = useCallback(() => {
    window.location.href = getIntegrationsUrl()
  }, [])

  if (isIntegrationsLoading) {
    return (
      <div className={styles.container}>
        <WppSkeleton variant="rectangle" height="154px" />
      </div>
    )
  }

  if (isEmpty) {
    return (
      <Flex direction="column" align="center" justify="center" className={styles.emptyState}>
        <EmptyState
          title={t('dashboard.settings.integrations_empty_title')}
          description={t('dashboard.settings.integrations_empty_description')}
        >
          <WppButton variant="secondary" size="m" onClick={navigateToIntegrations} data-testid="confirm-btn">
            {t('dashboard.settings.integrations_empty_button')}
          </WppButton>
        </EmptyState>
      </Flex>
    )
  }

  return (
    <div className={styles.listContainer}>
      {availableIntegrations.map(({ type, id, name, activeForUser }) => (
        <IntegrationCard type={type} id={id} key={id} name={name} activeForUser={activeForUser} />
      ))}
    </div>
  )
}
