import { PropsWithChildren } from 'react'

import { useTemplate } from 'hooks/useTemplate'
import { useMoveLinearActivity } from 'pages/project/components/canvas/hooks/useMoveLinearActivity'
import { useMoveLinearApp } from 'pages/project/components/canvas/hooks/useMoveLinearApp'
import { usePatchLinearWorkflow } from 'pages/project/components/canvas/hooks/usePatchLinearWorkflow'
import { GenericLinearProvider } from 'providers/common/LinearGenericProvider'

export const LinearTemplateProvider = ({ children }: PropsWithChildren<{}>) => {
  const { template, linearData } = useTemplate()

  const { patchWorkflow } = usePatchLinearWorkflow({ projectId: template.id })
  const { moveActivity } = useMoveLinearActivity({ projectId: template.id })
  const { moveApp } = useMoveLinearApp({ projectId: template.id })

  return (
    <GenericLinearProvider
      id={template.id}
      linearData={linearData}
      patchWorkflow={patchWorkflow}
      moveActivity={moveActivity}
      moveApp={moveApp}
    >
      {children}
    </GenericLinearProvider>
  )
}
