// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eNlwo{display:flex;padding:6px;border-radius:6px;cursor:pointer;padding:0}.eNlwo:focus-within,.eNlwo:hover{background:var(--wpp-grey-color-300)}.yI64h{padding:4px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/selectPersonInline/SelectPersonInline.module.scss","webpack://./src/pages/project/components/canvas/utils.scss"],"names":[],"mappings":"AAEA,OACE,YAAA,CCFA,WAAA,CACA,iBAAA,CACA,cAAA,CDIA,SAAA,CCFA,iCAEE,oCAAA,CDGJ,OACE,WAAA","sourcesContent":["@import '~pages/project/components/canvas/utils';\n\n.personLabel {\n  display: flex;\n\n  @include activeInlineButton();\n\n  padding: 0;\n}\n\n.selectedPersonPadding {\n  padding: 4px;\n}\n","@mixin activeInlineButton() {\n  padding: 6px;\n  border-radius: 6px;\n  cursor: pointer;\n\n  &:focus-within,\n  &:hover {\n    background: var(--wpp-grey-color-300);\n  }\n}\n\n@mixin multilineEllipsis($lines: 2) {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: $lines;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"personLabel": `eNlwo`,
	"selectedPersonPadding": `yI64h`
};
export default ___CSS_LOADER_EXPORT___;
