import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { Editor, EditorState, convertFromRaw, ContentState, EditorProps } from 'draft-js'
import { forwardRef, useState, useEffect } from 'react'
import 'draft-js/dist/Draft.css'

import { RichText } from 'components/richText/utils'

interface Props extends Partial<EditorProps> {
  text?: MayBeNull<RichText>
}

export const TextViewer = forwardRef<Editor, Props>(({ text, ...rest }, ref) => {
  const [editorState, setEditorState] = useState<EditorState>(() => {
    return text
      ? EditorState.createWithContent(
          text.formatted ? convertFromRaw(text.formatted) : ContentState.createFromText(text.plain || ''),
        )
      : EditorState.createEmpty()
  })

  useEffect(() => {
    setEditorState(
      text?.formatted ? EditorState.createWithContent(convertFromRaw(text?.formatted)) : EditorState.createEmpty(),
    )
  }, [text])

  return (
    <WppTypography type="s-body" data-testid="text-viewer">
      <Editor ref={ref} readOnly editorState={editorState} onChange={setEditorState} {...rest} />
    </WppTypography>
  )
})
