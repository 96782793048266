import { WppCard, WppTypography, WppActionButton, WppIconGear } from '@platform-ui-kit/components-library-react'
import { useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { WrikeSettingsLogo } from 'components/svg/WrikeSettingsLogo'
import { showIntegrationDetailsModal } from 'pages/settings/integrations/components/IntegrationDetailsModal'
import styles from 'pages/settings/integrations/Integrations.module.scss'
import { IntegrationType } from 'types/projects/Integration'
import { capitalizeFirstLetter } from 'utils/common'

interface IntegrationCardProps {
  type: IntegrationType
  id: string
  name: string
  activeForUser: boolean
}

export const IntegrationCard = ({ type, id, name, activeForUser }: IntegrationCardProps) => {
  const handleOpenDetails = useCallback(
    (integrationId: string) => {
      showIntegrationDetailsModal({ integrationId, name, type })
    },
    [name, type],
  )
  return (
    <WppCard className={styles.card} size="l">
      <Flex direction="column" gap={20}>
        <Flex justify="between">
          <WrikeSettingsLogo className={styles.logoWrapper} data-testid={`${type}-settings-logo`} />
          {activeForUser && (
            <WppActionButton
              className={styles.actionButton}
              variant="secondary"
              data-testid="open-integration-button"
              onClick={() => handleOpenDetails(id)}
            >
              <WppIconGear height={20} width={20} className={styles.greyColor800} />
            </WppActionButton>
          )}
        </Flex>
        <Flex direction="column" gap={4}>
          <WppTypography type="m-strong">{capitalizeFirstLetter(type)}</WppTypography>
          <WppTypography type="s-body" className={styles.greyColor800}>
            {name}
          </WppTypography>
        </Flex>
      </Flex>
    </WppCard>
  )
}
