import { WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import React, { JSX, PropsWithChildren, useMemo } from 'react'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/common/userInfo/UserInfo.module.scss'
import { User } from 'types/projects/template'

type Props = PropsWithChildren<{
  user?: User
  avatarUrl?: MayBeNull<string>
  prefix?: string
  templateView?: boolean
}> &
  JSX.IntrinsicElements['div']

export const UserInfo = ({ user, avatarUrl, children, className, prefix, templateView, ...props }: Props) => {
  const fullName = useMemo(() => {
    return [user?.firstname, user?.lastname].join(' ').trim()
  }, [user])

  return (
    <Flex align="center" gap={8} className={clsx(styles.userInfo, className)} {...props} data-testid="user-info">
      {templateView ? (
        <>
          <Avatar size="xs" name={fullName} src={avatarUrl || ''} />
          <WppTypography type="s-midi">
            {prefix ? `${prefix} ` : ''}
            {fullName}
          </WppTypography>
        </>
      ) : (
        <WppTooltip text={fullName}>
          <Avatar size="xs" name={fullName} src={avatarUrl || ''} />
        </WppTooltip>
      )}

      {children}
    </Flex>
  )
}
