import { WppInlineMessage, WppLabel, WppTypography } from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { HierarchyContainerNodeId, HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect, FormSelectOption } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { useHierarchy } from 'hooks/useHierarchy'
import { DateRangeAttribute } from 'pages/components/projectModal/components/attributes/DateRangeAttribute'
import { MultiSelectAttribute } from 'pages/components/projectModal/components/attributes/MultiSelectAttribute'
import { SingleSelectAttribute } from 'pages/components/projectModal/components/attributes/SingleSelectAttribute'
import { TextAreaAttribute } from 'pages/components/projectModal/components/attributes/TextAreaAttribute'
import { TextInputAttribute } from 'pages/components/projectModal/components/attributes/TextInputAttribute'
import styles from 'pages/components/projectModal/components/projectForm/ProjectForm.module.scss'
import { WorkspaceWarning } from 'pages/components/workspaceWarning/WorkspaceWarning'
import { SelectProcessType } from 'pages/project/components/canvas/components/selectProcessType/SelectProcessType'
import { ProjectAttribute, ProjectAttributeType } from 'types/projects/attributes'
import { ContextHierarchy, ProjectType } from 'types/projects/projects'
import { capitalizeFirstLetter } from 'utils/common'

export const ProjectForm = ({
  showProcessType = false,
  contextHierarchy = [],
  showWarning = false,
  systemAttributes,
  customAttributes,
}: {
  showProcessType?: boolean
  showWarning?: boolean
  contextHierarchy?: ContextHierarchy[]
  systemAttributes?: ProjectAttribute[]
  customAttributes?: ProjectAttribute[]
}) => {
  const { t } = useTranslation()
  const { watch, setValue, trigger } = useFormContext()
  const {
    osContext: { navigationTree, tenant },
  } = useOs()
  const { hierarchyOrder } = useHierarchy()
  const { mapping } = navigationTree
  const isAgencyWorkspace = (tenant as any).tenantType === TenantType.Agency

  const mapNavigationToOption = useCallback(
    (children: string[]) => {
      const options = (children || [])
        .map(id => {
          const { name, type, customTypeName } = mapping[id] || {}

          const handleThisLvl = tenant.hierarchyLevels.some(level => {
            return level.type === (type === HierarchyCustomNodeType ? customTypeName : type)
          })

          if (!handleThisLvl) return null
          return { label: name, value: id }
        })
        .filter(option => option?.label) as FormSelectOption[]

      options.sort((a, b) => a.label?.localeCompare(b.label))

      return options
    },
    [mapping, tenant.hierarchyLevels],
  )

  const formWatch = watch(hierarchyOrder.map(levelType => `hierarchy.${levelType}`))

  const {
    contextWorkspace: attributeContext,
    name: attributeName,
    description: attributeDescription,
    type: attributeType,
    processType: attributeProcessType,
    startDate_endDate: attributeDueDate,
    ...systemAttributesMap
  } = useMemo(
    () => Object.fromEntries(systemAttributes?.map(attribute => [attribute.contractName, attribute]) ?? []),
    [systemAttributes],
  )

  const statusOptions = useMemo(() => {
    if (attributeType) {
      return attributeType.valueOptions?.map(option => ({
        value: option,
        label: capitalizeFirstLetter(option.toLowerCase()),
      }))
    }

    // default options
    return Object.entries(ProjectType).map(([key, value]) => ({
      value: key,
      label: t(`modals.create_project.project_type_${value.toLowerCase()}`),
    }))
  }, [attributeType, t])

  const updateDownstreamHierarchy = useCallback(
    (nodeId: string, levelIndex: number) => {
      let lastSelectedNodeId = nodeId

      // clear all next levels or set default value (if only one option)
      hierarchyOrder.slice(levelIndex + 1).forEach(levelType => {
        const nextLevelOptions = lastSelectedNodeId ? mapping[lastSelectedNodeId]?.children : []
        const preselectedId = nextLevelOptions.length === 1 ? nextLevelOptions[0] : ''

        setValue(`hierarchy.${levelType}`, preselectedId)
        lastSelectedNodeId = preselectedId
      })
    },
    [hierarchyOrder, mapping, setValue],
  )

  return (
    <Flex direction="column" gap={69} className={styles.paddingBottom}>
      <Flex direction="column" gap={24}>
        <Flex direction="column" gap={4}>
          <FormInput
            name="name"
            data-testid="modal-project-name"
            labelConfig={{ text: t('modals.create_project.field_name_label') }}
            placeholder={t('modals.create_project.field_name_placeholder')!}
            required={attributeName?.required ?? true}
            disabled={attributeName?.enabled === false}
          />

          {isAgencyWorkspace && (
            <WppTypography type="xs-strong" className={styles.greyColor800}>
              {t('modals.create_project.workspace_description', { name: tenant.name })}
            </WppTypography>
          )}
        </Flex>

        <FormSelect
          name="type"
          type="single"
          data-testid="project-type-select"
          options={statusOptions!}
          labelConfig={{ text: t('modals.create_project.field_type_label') }}
          onWppChange={() => trigger()}
          required={attributeType?.required ?? true}
          disabled={attributeType?.enabled === false}
        />

        {showProcessType && (
          <Flex direction="column" gap={8}>
            <WppLabel
              data-testid="project-process-type-label"
              config={{ text: t('modals.create_project.field_process_type_label') }}
              typography="s-strong"
            />
            <SelectProcessType
              name="processType"
              required={attributeProcessType?.required ?? true}
              disabled={attributeProcessType?.enabled === false}
            />
          </Flex>
        )}

        <Flex direction="column" gap={12}>
          {showWarning && (
            <WppInlineMessage
              size="m"
              message={t('project.about.incorrect_workspace')}
              type="warning"
              showTooltipFrom={100}
            />
          )}
          <WorkspaceWarning contextHierarchy={contextHierarchy} />
          <div className={styles.grid}>
            {hierarchyOrder.map((hierarchy, index) => {
              const mappingLvl = index < 1 ? HierarchyContainerNodeId : formWatch[index - 1 || 0]
              const optionsList = mapNavigationToOption(mapping[mappingLvl]?.children)
              const isDisabled = index < 1 ? false : !mappingLvl || !optionsList.length

              return (
                <FormSelect
                  key={hierarchy}
                  name={`hierarchy.${hierarchy}`}
                  withSearch
                  data-testid={`${hierarchy}-select`}
                  options={optionsList}
                  labelConfig={{
                    text: t(`modals.create_project.field_${hierarchy}_label`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    }),
                  }}
                  placeholder={
                    t(`modals.create_project.field_${hierarchy}_placeholder`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    })!
                  }
                  required
                  toggleSelection
                  disabled={isDisabled}
                  onWppChange={event => updateDownstreamHierarchy(event.detail.value, index)}
                  className={styles.hierarchySelect}
                />
              )
            })}
            {hierarchyOrder.length % 2 !== 0 && <div />}
            <FormDatepicker
              className={styles.datePicker}
              name="dueDate"
              placeholder={t('modals.create_project.field_due_date_placeholder')}
              labelConfig={{ text: t('modals.create_project.field_due_date_label') }}
              range
              required={attributeDueDate?.required ?? true}
              disabled={attributeDueDate?.enabled === false}
            />
          </div>
        </Flex>
        <FormTextareaInput
          name="description"
          data-testid="project-description"
          labelConfig={{ text: t('modals.create_project.field_description_label') }}
          placeholder={t('modals.create_project.field_description_placeholder')!}
          warningThreshold={980}
          charactersLimit={1000}
          required={attributeDescription?.required ?? true}
          disabled={attributeDescription?.enabled === false}
        />

        {Object.values(systemAttributesMap).map(attribute => {
          switch (attribute.type) {
            case ProjectAttributeType.TEXT_INPUT:
              return <TextInputAttribute key={attribute.id} attribute={attribute} />
            case ProjectAttributeType.TEXT_AREA:
              return <TextAreaAttribute key={attribute.id} attribute={attribute} />
            case ProjectAttributeType.DATE_RANGE:
              return <DateRangeAttribute key={attribute.id} attribute={attribute} />
            case ProjectAttributeType.SINGLE_SELECT:
              return <SingleSelectAttribute key={attribute.id} attribute={attribute} />
            case ProjectAttributeType.MULTI_SELECT:
              return <MultiSelectAttribute key={attribute.id} attribute={attribute} />
            default:
              return null
          }
        })}

        {customAttributes?.map(attribute => {
          switch (attribute.type) {
            case ProjectAttributeType.TEXT_INPUT:
              return <TextInputAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            case ProjectAttributeType.TEXT_AREA:
              return <TextAreaAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            case ProjectAttributeType.DATE_RANGE:
              return <DateRangeAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            case ProjectAttributeType.SINGLE_SELECT:
              return <SingleSelectAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            case ProjectAttributeType.MULTI_SELECT:
              return <MultiSelectAttribute key={attribute.id} attribute={attribute} formGroup="customAttributes" />
            default:
              return null
          }
        })}
      </Flex>
    </Flex>
  )
}
