import { WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { TasksFilter } from 'types/projects/tasks'

interface TaskStatusFilterProps {
  filters: TasksFilter
  setFilters: (filters: (prev: TasksFilter) => TasksFilter) => void
  statusesOptions: string[]
  useExternalStatuses: boolean
  externalStatusesEntries?: Record<string, string>
  wrikeWorkflowsLoading?: boolean
}

export const TaskStatusFilter = ({
  filters,
  setFilters,
  statusesOptions,
  externalStatusesEntries,
  useExternalStatuses,
  wrikeWorkflowsLoading,
}: TaskStatusFilterProps) => {
  const { t } = useTranslation()

  return (
    <WppSelect
      size="s"
      placeholder={t('tasksDashboard.select_status_placeholder')}
      className="select"
      value={filters.selectedStatuses}
      onWppChange={e => setFilters(filters => ({ ...filters, selectedStatuses: e.detail.value }))}
      type="multiple"
      withFolder
      withSearch
      loading={useExternalStatuses ? wrikeWorkflowsLoading : false}
      data-testid="statuses-select"
      locales={{
        allSelectedText: t('tasksDashboard.all_statuses_selected'),
        clearAllText: t('common.clear_all'),
        selectAllText: t('common.select_all'),
        selectLabel: t('common.selected_label'),
      }}
    >
      {statusesOptions.map(status => (
        <WppListItem key={status} value={status} data-testid={status}>
          <p slot="label">
            {useExternalStatuses ? externalStatusesEntries?.[status] : t(`project.tasks.status.${status}`)}
          </p>
        </WppListItem>
      ))}
    </WppSelect>
  )
}
