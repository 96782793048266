import { useMemo } from 'react'

import { useProject } from 'hooks/useProject'

export const useExternalStatusList = () => {
  const { wrikeWorkflows, useExternalStatuses } = useProject() ?? {}

  return useMemo(
    () =>
      useExternalStatuses
        ? wrikeWorkflows?.customStatuses?.map(({ wrikeId, name }: { wrikeId: string; name: string }) => ({
            value: wrikeId,
            label: name,
          }))
        : [],
    [useExternalStatuses, wrikeWorkflows?.customStatuses],
  )
}
