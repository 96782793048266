import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useNativeAppErrors } from 'hooks/application/useAppErrors'
import { AppIssueCard } from 'pages/components/appIssueCard/AppIssueCard'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { NativeAppDTO, NativeAppVersionDTO } from 'types/products/nativeApp'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  app: NativeAppDTO
  selectedVersions: NativeAppVersionDTO[]
}

const AppCreateIssuesModal = ({ app, selectedVersions, isOpen, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()

  const allAppVersions = useMemo(() => app.versions ?? [], [app.versions])
  const appErrors = useNativeAppErrors(app.id, app.name, selectedVersions.length ? selectedVersions : allAppVersions)

  useEffect(() => {
    if (!appErrors.length) {
      onClose()
    }
  }, [appErrors, onClose])

  return (
    <SideModal open={isOpen} onWppSideModalClose={onClose} onWppSideModalCloseComplete={onCloseComplete} size="m">
      <WppTypography slot="header" type="2xl-heading">
        {t('modals.app_issues.title')}
      </WppTypography>

      <div slot="body">
        <Flex align="center" gap={12}>
          <ApplicationLogo logo={app?.logoUrl} size={40} />

          <Truncate lines={1} type="xl-heading">
            {app.name}
          </Truncate>
        </Flex>

        <WppTypography type="s-body" className="wpp-spacing-12-top">
          {t('modals.app_issues.info')}
        </WppTypography>

        <Flex direction="column" gap={12} className="wpp-spacing-24-top">
          {appErrors.map((appError, idx) => (
            <AppIssueCard key={idx} issueType={appError.issue} missingCtx={appError.missedCtx} />
          ))}
        </Flex>
      </div>
      <Flex justify="end" slot="actions">
        <Flex gap={12}>
          <WppButton variant="primary" size="m" onClick={onClose}>
            {t('common.btn_done')}
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showAppCreateIssuesModal } = createProjectModal<Props>(
  AppCreateIssuesModal,
  'apps-create-issue-modal',
)
