import { Outlet } from 'react-router-dom'

import { ProjectModalProvider } from 'providers/project/ProjectModalProvider'
import { TemplateProvider } from 'providers/template/TemplateProvider'

export const TemplatePage = () => {
  return (
    <TemplateProvider>
      <ProjectModalProvider>
        <Outlet />
      </ProjectModalProvider>
    </TemplateProvider>
  )
}
