import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showAppDetailsModal,
  useShowAppDetailsModal,
} from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/detailsModal/AppDetailsModal'
import { DetailsModalType } from 'types/common/utils'

export const useOpenAppPreviewModal = () => {
  const { visible, hide } = useShowAppDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, appId, phaseId, activityId } = Object.fromEntries(params.entries()) as {
      view: string
      appId: string
      activityId?: string
      phaseId?: string
    }

    if (view && view === DetailsModalType.APP_DETAILS_PREVIEW) {
      showAppDetailsModal({ appId, phaseId, activityId, onClose: setParams })
    } else visible && hide()
  }, [hide, params, setParams, visible])
}
