// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RNyUI{min-width:240px}.OxxpQ{margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/timeline/components/timelineActionBar/TimelineActionBar.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAGF,OACE,gBAAA","sourcesContent":[".searchInput {\n  min-width: 240px;\n}\n\n.rightPart {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": `RNyUI`,
	"rightPart": `OxxpQ`
};
export default ___CSS_LOADER_EXPORT___;
