import { WppTag } from '@platform-ui-kit/components-library-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ProjectType } from 'types/projects/projects'

interface Props {
  type: ProjectType
  className?: string
}

interface ProjectTypePreset {
  value: ProjectType
  title: string
}

const projectTypePreset: Record<ProjectType, ProjectTypePreset> = {
  [ProjectType.MISCELLANEOUS]: {
    value: ProjectType.MISCELLANEOUS,
    title: 'project.list.tag_project_type_miscellaneous',
  },
  [ProjectType.PITCH]: {
    value: ProjectType.PITCH,
    title: 'project.list.tag_project_type_pitch',
  },
  [ProjectType.WORKSHOP]: {
    value: ProjectType.WORKSHOP,
    title: 'project.list.tag_project_type_workshop',
  },
  [ProjectType.FINANCE]: {
    value: ProjectType.FINANCE,
    title: 'project.list.tag_project_type_finance',
  },
  [ProjectType.CAMPAIGN]: {
    value: ProjectType.CAMPAIGN,
    title: 'project.list.tag_project_type_campaign',
  },
}

export const ProjectTypeTag: FC<Props> = ({ type, className }) => {
  const { t } = useTranslation()
  const { title } = projectTypePreset[type]

  return (
    <Flex inline align="center" gap={6} className={className}>
      <WppTag variant="neutral" label={t(title)!} data-testid="project-type" />
    </Flex>
  )
}
