import { WppIconArchive, WppIconSuccess } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'

import { ProjectStatus as Status } from 'types/projects/projects'

interface StyleConfig {
  icon: ReactNode
  label: string
  variant: 'neutral' | 'positive'
}

export const styleConfig: Record<Exclude<Status, Status.TO_DO | Status.IN_PROGRESS>, StyleConfig> = {
  [Status.ARCHIVED]: {
    icon: <WppIconArchive slot="icon-start" />,
    label: 'project.status.archived',
    variant: 'neutral',
  },
  [Status.COMPLETED]: {
    icon: <WppIconSuccess slot="icon-start" />,
    label: 'project.status.completed',
    variant: 'positive',
  },
}
