// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IMW6q{padding-bottom:24px}.Ujhez{grid-column:1/-1;padding-bottom:40px}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/projectTemplateForm/ProjectTemplateForm.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,gBAAA,CACA,mBAAA","sourcesContent":[".container {\n  padding-bottom: 24px;\n}\n\n.nextPageSpinner {\n  grid-column: 1/-1;\n  padding-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IMW6q`,
	"nextPageSpinner": `Ujhez`
};
export default ___CSS_LOADER_EXPORT___;
