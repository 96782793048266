import * as z from 'zod'

import { useFetchMiroAuthLinkApi } from 'api/miro/queries/useFetchMiroAuthLinkApi'
import { useStableCallback } from 'hooks/useStableCallback'
import { routesManager } from 'utils/routesManager'

export enum MiroAuthRedirectReason {
  Settings = 'settings',
  AuthToken = 'auth-token',
}

const miroAuthRedirectParamsSchema = z.object({
  authCode: z.string().min(1),
  clientId: z.string().min(1),
  teamId: z.string().min(1),
  state: z.object({
    from: z.string().min(1),
    reason: z.nativeEnum(MiroAuthRedirectReason),
    context: z.any().optional(),
  }),
})

export type MiroAuthCallbackParams = z.infer<typeof miroAuthRedirectParamsSchema>

export const isValidMiroAuthCallbackParams = (value: Record<string, any>): value is MiroAuthCallbackParams =>
  miroAuthRedirectParamsSchema.safeParse(value).success

export interface MiroAuthRedirectState {
  from: string
  reason: MiroAuthRedirectReason
  context?: any
}

export const useInitiateMiroOAuth2Flow = () => {
  const fetchMiroAuthLink = useFetchMiroAuthLinkApi()

  return {
    handleInitiateMiroOAuth2Flow: useStableCallback(
      async ({
        clientId,
        teamId,
        context,
        reason = MiroAuthRedirectReason.AuthToken,
      }: {
        clientId?: string
        teamId?: string
        reason?: MiroAuthRedirectReason
        context?: any
      } = {}) => {
        const state = {
          from: orchestrationMiroUrl(),
          reason,
          ...(!!context && { context }),
        } satisfies MiroAuthRedirectState

        const { data } = await fetchMiroAuthLink({
          clientId,
          teamId,
          state: JSON.stringify(state),
        })

        window.open(data!.url, '_blank')
      },
    ),
  }
}

export const getMiroUrl = () => `${window.location.origin}${routesManager.miroAuthCallback.getURL()}`

export const orchestrationMiroUrl = () => `/orchestration${routesManager.miroAuthCallback.getURL()}`
