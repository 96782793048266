import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/projectSkeleton/components/workflow/WorkflowSkeleton.module.scss'
import { FluidSkeleton } from 'pages/project/components/canvas/components/canvasSkeleton/FluidSkeleton'
import { LinearSkeleton } from 'pages/project/components/canvas/components/canvasSkeleton/LinearSkeleton'

export const WorkflowSkeleton = ({ isCanvasLoading }: { isCanvasLoading: boolean }) => {
  return (
    <Flex direction="column" className={styles.container} gap={24}>
      {isCanvasLoading ? <LinearSkeleton /> : <FluidSkeleton />}
    </Flex>
  )
}
