import { FileItemType, FileUploadLocales } from '@platform-ui-kit/components-library'
import { WppFileUpload } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { forwardRef, ComponentPropsWithoutRef, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import styles from 'components/form/formFileUpload/FormFileUpload.module.scss'
import { acceptConfigToMap, getFileType } from 'components/form/formFileUpload/utils'
import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'

type Props = Omit<
  ComponentPropsWithoutRef<typeof WppFileUpload>,
  'name' | 'onFocus' | 'onBlur' | 'onChange' | 'locales' | 'value'
> & {
  name: string
  locales?: Partial<FileUploadLocales>
}

export const FormFileUpload = forwardRef<HTMLWppFileUploadElement, Props>(function FormFileUpload(
  { id, name, onWppBlur, onWppChange, message, messageType, className, locales, ...rest },
  ref,
) {
  const { t } = useTranslation()
  const hasErrorRef = useRef<boolean>(false)

  const extensionsMap = useMemo(() => {
    return rest.acceptConfig ? acceptConfigToMap(rest.acceptConfig) : {}
  }, [rest.acceptConfig])

  const fileLocales = useMemo<FileUploadLocales>(
    () => ({
      label: t('common.file_upload.label'),
      text: t('common.file_upload.text'),
      info: (accept, size) => t('common.file_upload.info', { accept, size }),
      sizeError: t('common.file_upload.size_error'),
      formatError: t('common.file_upload.format_error'),

      ...locales,
    }),
    [t, locales],
  )

  const innerRef = useRef<HTMLWppFileUploadElement>(null)

  const {
    field: { ref: fieldRef, value, onChange, onBlur },
    fieldState: { isTouched, error },
  } = useField({
    name,
    rules: {
      validate: {
        uploadError: () => (hasErrorRef.current ? '' : void 0),
      },
    },
  })

  useProvideFieldFocus({
    fieldRef,
    setFocus: () => innerRef.current?.shadowRoot?.querySelector('input')?.focus(),
  })

  const identifier = id || name
  const errorText = error?.message
  const shouldShowError = isTouched && !!errorText

  return (
    <WppFileUpload
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      className={clsx(styles.root, className)}
      id={identifier}
      name={name}
      locales={fileLocales}
      value={value}
      message={shouldShowError ? errorText : message}
      messageType={shouldShowError ? 'error' : messageType}
      onWppChange={e => {
        // path missing types
        const patchedEvent = {
          ...e,
          detail: {
            ...e.detail,
            value: e.detail.value?.map(
              (file: FileItemType) =>
                new File([file as File], file.name, {
                  type: file.type || getFileType(file.name, extensionsMap),
                }),
            ),
          },
        }

        hasErrorRef.current = patchedEvent.detail.hasError
        onChange(patchedEvent.detail.value)
        onWppChange?.(patchedEvent)
      }}
      onWppBlur={e => {
        onBlur()
        onWppBlur?.(e)
      }}
    />
  )
})
