import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useGenerateFileDetailsDownloadApiUrl } from 'api/attachments/mutation/useGenerateFileDetailsDownloadUrlsApi'
import { useToast } from 'hooks/useToast'

export const useDownloadProjectFile = () => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { mutateAsync: downloadFile } = useGenerateFileDetailsDownloadApiUrl()

  const downloadFileAction = useCallback(
    async (key: string) => {
      try {
        const { data } = await downloadFile({ keys: [key] })

        const link = document.createElement('a')

        // Create a virtual anchor element
        link.href = data?.[0]?.signedUrl
        link.download = data?.[0]?.key

        // Dispatch a click event on the anchor element
        link.dispatchEvent(new MouseEvent('click'))
      } catch (e) {
        showToast({ type: 'error', message: t('project.files.toast_error_download_message')! })
      }
    },
    [downloadFile, showToast, t],
  )

  return { downloadFileAction }
}
