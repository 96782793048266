// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UK1SK{display:table;background:var(--wpp-white-color);border-top:var(--wpp-grey-color-400) 1px solid;border-right:var(--wpp-grey-color-400) 1px solid;border-bottom:var(--wpp-grey-color-400) 1px solid}.ohlbx{display:table-row;list-style:none}.l2DeI{display:table-cell;vertical-align:-webkit-baseline-middle;vertical-align:middle}.ydkbg{margin-left:16px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/timeline/components/taskListTable/TaskListHeader.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA,CACA,iCAAA,CACA,8CAAA,CACA,gDAAA,CACA,iDAAA,CAGF,OACE,iBAAA,CACA,eAAA,CAGF,OACE,kBAAA,CACA,sCAAA,CACA,qBAAA,CAGF,OACE,gBAAA","sourcesContent":[".ganttTable {\n  display: table;\n  background: var(--wpp-white-color);\n  border-top: var(--wpp-grey-color-400) 1px solid;\n  border-right: var(--wpp-grey-color-400) 1px solid;\n  border-bottom: var(--wpp-grey-color-400) 1px solid;\n}\n\n.ganttTable_Header {\n  display: table-row;\n  list-style: none;\n}\n\n.ganttTable_HeaderItem {\n  display: table-cell;\n  vertical-align: -webkit-baseline-middle;\n  vertical-align: middle;\n}\n\n.marginLeft {\n  margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ganttTable": `UK1SK`,
	"ganttTable_Header": `ohlbx`,
	"ganttTable_HeaderItem": `l2DeI`,
	"marginLeft": `ydkbg`
};
export default ___CSS_LOADER_EXPORT___;
