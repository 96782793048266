import { projectApi } from 'api'

interface Params {
  signedUrl: string
  file: File
}

export const uploadProjectFileApi = ({ signedUrl, file }: Params) =>
  projectApi.put<''>(signedUrl, file, {
    headers: { 'Content-Type': file.type || 'application/octet-stream' },
  })
