import { WppTypography, WppActionButton } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/404/NotFound.module.scss'

export const NotFound = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={24} justify="center" align="center" className={styles.container}>
      <WppTypography type="2xl-heading">{t('404.page_not_found')}</WppTypography>
      <WppActionButton onClick={() => navigate('projects')}>{t('404.back_to_dashboard')}</WppActionButton>
    </Flex>
  )
}
