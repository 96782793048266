import { PresetType, ProjectPartKey } from 'types/projects/projectViewSettings'

type PresetConfig = {
  [P in PresetType]: { [key in ProjectPartKey]: boolean }
}

export const LinearPreset: PresetConfig = {
  [PresetType.DEFAULT]: {
    [ProjectPartKey.AppIcon]: true,
    [ProjectPartKey.AppWarning]: true,
    [ProjectPartKey.StatusBar]: true,
    [ProjectPartKey.Descriptions]: true,
    [ProjectPartKey.ResponsiblePersons]: true,
    [ProjectPartKey.Dates]: true,
    [ProjectPartKey.ActivityFilesAndLinks]: true,
  },
  [PresetType.PITCH]: {
    [ProjectPartKey.AppIcon]: true,
    [ProjectPartKey.AppWarning]: false,
    [ProjectPartKey.StatusBar]: false,
    [ProjectPartKey.Descriptions]: true,
    [ProjectPartKey.ResponsiblePersons]: false,
    [ProjectPartKey.Dates]: false,
    [ProjectPartKey.ActivityFilesAndLinks]: false,
  },
  [PresetType.MINIMAL]: {
    [ProjectPartKey.AppIcon]: false,
    [ProjectPartKey.AppWarning]: false,
    [ProjectPartKey.StatusBar]: false,
    [ProjectPartKey.Descriptions]: false,
    [ProjectPartKey.ResponsiblePersons]: false,
    [ProjectPartKey.Dates]: false,
    [ProjectPartKey.ActivityFilesAndLinks]: false,
  },
  [PresetType.CUSTOM]: {
    [ProjectPartKey.AppIcon]: true,
    [ProjectPartKey.AppWarning]: true,
    [ProjectPartKey.StatusBar]: true,
    [ProjectPartKey.Descriptions]: true,
    [ProjectPartKey.ResponsiblePersons]: true,
    [ProjectPartKey.Dates]: true,
    [ProjectPartKey.ActivityFilesAndLinks]: true,
  },
}
