// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RLnpb::part(card){padding:12px}.tvRuL{height:183px}.xhpgm::after{position:absolute;inset:0;background:var(--wpp-grey-color-100);opacity:.6;content:"";pointer-events:none}.kQatJ{align-self:flex-start}.BfxYa{width:100%}.eU_jN{color:var(--wpp-grey-color-800)}.PI2LS{margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/fluidCanvas/components/fluidActivity/FluidEditableActivity.module.scss"],"names":[],"mappings":"AACE,mBACE,YAAA,CAIJ,OACE,YAAA,CAIA,cACE,iBAAA,CACA,OAAA,CACA,oCAAA,CACA,UAAA,CACA,UAAA,CACA,mBAAA,CAIJ,OACE,qBAAA,CAGF,OACE,UAAA,CAGF,OACE,+BAAA,CAGF,OACE,iBAAA","sourcesContent":[".fluidItemContainer {\n  &::part(card) {\n    padding: 12px;\n  }\n}\n\n.innerAppPlaceholder {\n  height: 183px; // 159px - de facto card height + 8px gap\n}\n\n.disabledItem {\n  &::after {\n    position: absolute;\n    inset: 0;\n    background: var(--wpp-grey-color-100);\n    opacity: 0.6;\n    content: '';\n    pointer-events: none;\n  }\n}\n\n.alignSelf {\n  align-self: flex-start;\n}\n\n.nameWrapper {\n  width: 100%;\n}\n\n.greyColor800 {\n  color: var(--wpp-grey-color-800);\n}\n\n.actionsGap {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fluidItemContainer": `RLnpb`,
	"innerAppPlaceholder": `tvRuL`,
	"disabledItem": `xhpgm`,
	"alignSelf": `kQatJ`,
	"nameWrapper": `BfxYa`,
	"greyColor800": `eU_jN`,
	"actionsGap": `PI2LS`
};
export default ___CSS_LOADER_EXPORT___;
