import styles from 'components/svg/common.module.scss'

export const NoTemplateSvg = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="80" fill="none" viewBox="0 0 100 80" {...props}>
    <g clipPath="url(#clip0_8289_49144)">
      <g filter="url(#filter0_f_8289_49144)">
        <ellipse cx="50.937" cy="65.881" className={styles.fillPrimary300} rx="35.917" ry="6.462" />
      </g>
      <path
        className={styles.fillPrimary200}
        d="M52.5 79.891c20.71 0 37.5-16.766 37.5-37.449C90 21.76 73.21 4.993 52.5 4.993S15 21.76 15 42.443C15 63.124 31.79 79.89 52.5 79.89z"
      />
      <g filter="url(#filter1_d_8289_49144)">
        <rect width="28.333" height="57.422" x="22.5" y="26.63" className={styles.fillWhite} rx="4" />
      </g>
      <g filter="url(#filter2_d_8289_49144)">
        <rect width="28.333" height="57.422" x="55" y="26.63" className={styles.fillWhite} rx="4" />
      </g>
      <path
        className={styles.fillPrimary300}
        fillRule="evenodd"
        d="M77 18.618a1.142 1.142 0 10.002-2.285A1.142 1.142 0 0077 18.618zm0 .856a1.999 1.999 0 100-3.995c-1.105 0-2 .894-2 1.997 0 1.103.895 1.998 2 1.998zM67 77.537a1.142 1.142 0 10.002-2.284A1.142 1.142 0 0067 77.537zm0 .856c1.105 0 2-.894 2-1.997a1.999 1.999 0 00-2-1.997c-1.105 0-2 .894-2 1.997 0 1.103.895 1.998 2 1.998z"
        clipRule="evenodd"
      />
      <path
        className={styles.fillPrimary300}
        d="M12.15 64.927c.88-.143 1.59.746 1.273 1.592l-.431 1.146a1.138 1.138 0 01-1.966.302l-.762-.953c-.562-.702-.178-1.751.693-1.893l1.192-.194zM86.033 48.35a1.146 1.146 0 011.414-1.374l1.697.453a1.145 1.145 0 01.51 1.927l-1.285 1.249a1.153 1.153 0 01-1.923-.553l-.413-1.701z"
      />
      <path
        className={styles.fillPrimary400}
        d="M50.003 3.236c.266-.986 1.728-.986 1.994 0l.14.517c.096.357.393.634.77.715l.29.062a.978.978 0 010 1.925l-.29.062a1.01 1.01 0 00-.77.715l-.14.517c-.266.986-1.728.986-1.994 0l-.14-.517a1.01 1.01 0 00-.77-.715l-.29-.062a.978.978 0 010-1.925l.29-.062a1.01 1.01 0 00.77-.715l.14-.517zM15.003 47.675c.266-.985 1.728-.985 1.994 0l.14.517c.096.358.393.634.77.715l.29.063a.978.978 0 010 1.924l-.29.063a1.01 1.01 0 00-.77.715l-.14.517c-.266.985-1.728.985-1.994 0l-.14-.517a1.01 1.01 0 00-.77-.715l-.29-.063a.978.978 0 010-1.924l.29-.063a1.01 1.01 0 00.77-.715l.14-.517z"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_8289_49144"
        width="115.379"
        height="56.47"
        x="-6.752"
        y="37.646"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_8289_49144" stdDeviation="10.886" />
      </filter>
      <filter
        id="filter1_d_8289_49144"
        width="40.334"
        height="69.422"
        x="16.5"
        y="17.63"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8289_49144" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_8289_49144" result="shape" />
      </filter>
      <filter
        id="filter2_d_8289_49144"
        width="40.334"
        height="69.422"
        x="49"
        y="17.63"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8289_49144" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_8289_49144" result="shape" />
      </filter>
      <clipPath id="clip0_8289_49144">
        <path className={styles.fillWhite} d="M0 0H100V79.891H0z" />
      </clipPath>
    </defs>
  </svg>
)
