import React, { PropsWithChildren } from 'react'

const PROJECT_MODAL_REGISTRY: {
  [id: string]: {
    comp: React.FC<any>
  }
} = {}

// Modals that should be rendered in project's modal placeholder - to have access to project's context
export const registerProjectModal = <T extends React.FC<any>>(id: string, comp: T): void => {
  if (!PROJECT_MODAL_REGISTRY[id]) {
    PROJECT_MODAL_REGISTRY[id] = { comp }
  }
}

export const ProjectModalProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <>
      {children}

      {Object.entries(PROJECT_MODAL_REGISTRY).map(([id, t]) => {
        return <t.comp key={id} id={id} />
      })}
    </>
  )
}
