// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NLZbK{flex:1 1 100%;min-width:350px}.NLZbK::part(card){width:100%;height:100%}.bJXiN{margin-bottom:4px}.P6bTu{margin-right:-16px;margin-left:-16px}`, "",{"version":3,"sources":["webpack://./src/pages/templates/components/templatePhase/TemplatePhase.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA,CACA,eAAA,CAEA,mBACE,UAAA,CACA,WAAA,CAIJ,OACE,iBAAA,CAGF,OACE,kBAAA,CACA,iBAAA","sourcesContent":[".phase {\n  flex: 1 1 100%;\n  min-width: 350px;\n\n  &::part(card) {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.name {\n  margin-bottom: 4px;\n}\n\n.divider {\n  margin-right: -16px;\n  margin-left: -16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phase": `NLZbK`,
	"name": `bJXiN`,
	"divider": `P6bTu`
};
export default ___CSS_LOADER_EXPORT___;
