// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YxJD3{--li-bg-color-hover: transparent;--li-bg-color-active: transparent;flex:1 1;width:100%}.YxJD3::part(item){width:100%;cursor:auto}.YxJD3::part(label){font-weight:500}.iSVC9{--wpp-typography-color: --wpp-grey-color-900;--wpp-icon-color: --wpp-grey-color-800;display:flex;gap:4px;align-items:center}.Axlvg{color:var(--wpp-primary-color-600);font-weight:400;font-size:12px;line-height:20px}.Axlvg:hover{text-decoration:underline}.dkE4f{width:100%}.AmHeC{width:100%}.eBYhP{color:var(--wpp-primary-color-500);text-decoration:underline;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/integrations/wrike/WrikeConnect.module.scss"],"names":[],"mappings":"AAAA,OACE,gCAAA,CACA,iCAAA,CAEA,QAAA,CACA,UAAA,CAEA,mBACE,UAAA,CACA,WAAA,CAGF,oBACE,eAAA,CAIJ,OACE,4CAAA,CACA,sCAAA,CAEA,YAAA,CACA,OAAA,CACA,kBAAA,CAGF,OACE,kCAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aACE,yBAAA,CAIJ,OACE,UAAA,CAGF,OACE,UAAA,CAGF,OACE,kCAAA,CACA,yBAAA,CACA,cAAA","sourcesContent":[".projectInfoItem {\n  --li-bg-color-hover: transparent;\n  --li-bg-color-active: transparent;\n\n  flex: 1;\n  width: 100%;\n\n  &::part(item) {\n    width: 100%;\n    cursor: auto;\n  }\n\n  &::part(label) {\n    font-weight: 500;\n  }\n}\n\n.externalLink {\n  --wpp-typography-color: --wpp-grey-color-900;\n  --wpp-icon-color: --wpp-grey-color-800;\n\n  display: flex;\n  gap: 4px;\n  align-items: center;\n}\n\n.helpLink {\n  color: var(--wpp-primary-color-600);\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.warningMessage {\n  width: 100%;\n}\n\n.width {\n  width: 100%;\n}\n\n.link {\n  color: var(--wpp-primary-color-500);\n  text-decoration: underline;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectInfoItem": `YxJD3`,
	"externalLink": `iSVC9`,
	"helpLink": `Axlvg`,
	"warningMessage": `dkE4f`,
	"width": `AmHeC`,
	"link": `eBYhP`
};
export default ___CSS_LOADER_EXPORT___;
