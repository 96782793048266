import {
  WppListItem,
  WppTypography,
  WppIconCircle,
  WppIconAvailableCheckmark,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { TaskPriority } from 'types/projects/tasks'

interface Props {
  onChange: (priority: TaskPriority) => void
  selectedPriority: TaskPriority
  hideLabel?: boolean
}

export const TaskPriorityChangeDropdown = ({ onChange, selectedPriority, hideLabel }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {!hideLabel && (
        <WppTypography type="2xs-strong" className={styles.statusLabel}>
          {t('project.tasks.priority_label')}
        </WppTypography>
      )}

      <Flex direction="column" gap={4}>
        {Object.values(TaskPriority).map(priority => (
          <WppListItem
            key={priority}
            onWppChangeListItem={() => onChange(priority)}
            data-testid={`context-app-${priority}`}
            className={clsx(styles.statusItem)}
            checked={selectedPriority === priority}
          >
            {selectedPriority === priority ? <WppIconAvailableCheckmark slot="left" /> : <WppIconCircle slot="left" />}
            <WppTypography
              slot="label"
              type="s-body"
              className={clsx({ [styles.selectedText]: selectedPriority === priority })}
            >
              {t(`project.tasks.priority.${priority}`)!}
            </WppTypography>
          </WppListItem>
        ))}
      </Flex>
    </>
  )
}
