import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'

export interface CreatePhase {
  projectId: string
  name: string
  startDate?: string
  endDate?: string
  assignUser?: string
  description?: MayBeNull<string>
}

export const createPhaseApi = (body: CreatePhase) => projectApi.post('/phases', body)
