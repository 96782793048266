import { WppDivider, WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'

export const FileModalSkeleton = () => (
  <>
    <Flex direction="column" slot="body" gap={12}>
      <WppSkeleton variant="rectangle" height="35px" width="320px" />

      <WppSkeleton variant="rectangle" height="24px" width="150px" />
      <WppSkeleton variant="rectangle" height="24px" width="150px" />
    </Flex>
    <WppDivider />
    <WppSkeleton variant="rectangle" height="45px" width="100%" />
  </>
)
