import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchWorkflowTemplatesListApi } from 'api/templates/fetchers/fetchWorkflowTemplatesListApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useFetchWorkflowTemplatesInfiniteApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE,
  fetcher: fetchWorkflowTemplatesListApi,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: lastPage => {
    return lastPage.data.paginator.totalPages === lastPage.data.paginator.page ||
      lastPage.data.paginator.totalPages === 0
      ? undefined
      : lastPage.data.paginator.page + 1
  },
})
