import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull } from '@wpp-open/core'

import { parseRichText, RichText } from 'components/richText/utils'
import { Presentation } from 'types/projects/projectDemo'

export enum DemoItemType {
  ProjectInfo = 'ProjectInfo',
  AppInfo = 'AppInfo',
}

export interface DemoState {
  type: DemoItemType
  appId?: string
  phaseId?: string
}

export interface PresentationFormValue {
  content: MayBeNull<RichText>
  files: (File | FileItemType)[]
}

export function presentationToForm(presentation: Presentation): PresentationFormValue {
  return {
    content: parseRichText(presentation.content ?? ''),
    files: presentation.image ? [{ ...presentation.image, url: presentation.image.name, type: 'png' }] : [],
  }
}
