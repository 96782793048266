import { Outlet } from 'react-router-dom'

import { ProjectModalProvider } from 'providers/project/ProjectModalProvider'
import { ProjectProvider } from 'providers/project/ProjectProvider'

export const ProjectPage = () => {
  return (
    <ProjectProvider>
      <ProjectModalProvider>
        <Outlet />
      </ProjectModalProvider>
    </ProjectProvider>
  )
}
