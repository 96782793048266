// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reiZ_{position:fixed;right:16px;bottom:16px;z-index:998}`, "",{"version":3,"sources":["webpack://./src/components/common/table/PageBackToTop.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".buttonWrapper {\n  position: fixed;\n  right: 16px;\n  bottom: 16px;\n  z-index: 998;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": `reiZ_`
};
export default ___CSS_LOADER_EXPORT___;
