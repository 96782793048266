// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qgp06:has(.ag-overlay-no-rows-wrapper,.ag-overlay-loading-wrapper) .ag-center-cols-viewport{overflow:hidden}.qgp06:has(.ag-overlay-no-rows-wrapper,.ag-overlay-loading-wrapper) .ag-root-wrapper.ag-layout-auto-height,.qgp06:has(.ag-overlay-no-rows-wrapper,.ag-overlay-loading-wrapper) .ag-root-wrapper-body.ag-layout-auto-height{height:100%}.WOvyy:has(.ag-overlay-no-rows-wrapper,.ag-overlay-loading-wrapper) .ag-center-cols-viewport{overflow-x:auto}.WOvyy .ag-header{position:sticky;top:var(--sticky-offset, var(--wpp-os-header-height));z-index:1}.WOvyy .ag-root,.WOvyy .ag-root-wrapper{overflow:visible}.WkzRW{pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/common/table/tableInfinite/TableInfinite.module.scss"],"names":[],"mappings":"AAII,6FACE,eAAA,CAGF,2NAEE,WAAA,CAOF,6FACE,eAAA,CAIJ,kBACE,eAAA,CACA,qDAAA,CACA,SAAA,CAGF,wCAEE,gBAAA,CAQJ,OACE,mBAAA","sourcesContent":["// Hide horizontal scrollbar for empty states.\n// NOTE: not working in FF\n.root {\n  &:has(:global(.ag-overlay-no-rows-wrapper), :global(.ag-overlay-loading-wrapper)) {\n    :global(.ag-center-cols-viewport) {\n      overflow: hidden;\n    }\n\n    :global(.ag-root-wrapper.ag-layout-auto-height),\n    :global(.ag-root-wrapper-body.ag-layout-auto-height) {\n      height: 100%;\n    }\n  }\n}\n\n@mixin agGridStickyHeader() {\n  &:has(:global(.ag-overlay-no-rows-wrapper), :global(.ag-overlay-loading-wrapper)) {\n    :global(.ag-center-cols-viewport) {\n      overflow-x: auto;\n    }\n  }\n\n  :global(.ag-header) {\n    position: sticky;\n    top: var(--sticky-offset, var(--wpp-os-header-height));\n    z-index: 1;\n  }\n\n  :global(.ag-root),\n  :global(.ag-root-wrapper) {\n    overflow: visible;\n  }\n}\n\n.pageSticky {\n  @include agGridStickyHeader;\n}\n\n.loadingMoreRow {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qgp06`,
	"pageSticky": `WOvyy`,
	"loadingMoreRow": `WkzRW`
};
export default ___CSS_LOADER_EXPORT___;
