import { useMutation, UseMutationOptions, MutationFunction } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { QueryFetcher, QueryFetcherResponse, QueryFetcherParams } from 'api/common/types'

export function createUseMutation<F extends QueryFetcher>({ fetcher }: { fetcher: F }) {
  return function useCustomMutation<Ctx = unknown>(
    options?: UseMutationOptions<QueryFetcherResponse<F>, AxiosError, QueryFetcherParams<F>, Ctx>,
  ) {
    return useMutation<QueryFetcherResponse<F>, AxiosError, QueryFetcherParams<F>, Ctx>(
      (params => fetcher(params)()) as MutationFunction<QueryFetcherResponse<F>, QueryFetcherParams<F>>,
      options,
    )
  }
}
