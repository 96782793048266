import { AttachmentMetadataResponse, MayBeNull } from '@wpp-open/core'

import { facadeApi } from 'api'

export interface Props {
  hubId: string
}

export const getHubsInfo = ({ hubId }: Props) => facadeApi.get<Hub>(`/hubs/${hubId}`)

export enum HubType {
  Custom = 'CUSTOM',
  System = 'SYSTEM',
}

export interface Hub {
  id: string
  name: string
  tenantId: string
  azId: string
  organizationUnitId: string
  isActive: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  type: HubType
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  azMeta: {
    groups: {
      viewerUsersId: string
      adminUsersId: string
    }
  }
  accessRequestable: boolean
}
