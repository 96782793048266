import { projectApi } from 'api'
import { ExternalLink } from 'types/projects/workflow'

interface Props {
  activityId: string
  link: ExternalLink
}

export const patchActivityLink = ({ activityId, link }: Props) => {
  const { id, ...data } = link
  return projectApi.patch(`/activities/${activityId}/external-links/${id}`, { ...data })
}
