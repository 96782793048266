import { WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/canvas/Canvas.module.scss'

export const LinearSkeleton = ({ isTemplate }: { isTemplate?: boolean }) => (
  <Flex direction="row" gap={24} className={isTemplate ? styles.canvasTemplateContainer : styles.canvasContainer}>
    <WppSkeleton variant="rectangle" height="auto" width="350px" />
    <WppSkeleton variant="rectangle" height="auto" width="350px" />
    <WppSkeleton variant="rectangle" height="auto" width="350px" />
    <WppSkeleton variant="rectangle" height="auto" width="350px" />
    <WppSkeleton variant="rectangle" height="auto" width="350px" />
  </Flex>
)
