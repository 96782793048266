import { WppTextareaInput } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { Editor } from 'draft-js'
import { forwardRef, ComponentProps, useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { mergeRefs } from 'react-merge-refs'

import { TextEditor, TextEditorError } from 'components/richText/textEditor/TextEditor'
import { RichText } from 'components/richText/utils'
import { useField } from 'hooks/form/useField'

interface Props
  extends Omit<ComponentProps<typeof TextEditor>, 'initialValue' | 'setValue'>,
    Pick<ComponentProps<typeof WppTextareaInput>, 'id' | 'message' | 'messageType' | 'labelConfig'> {
  name: string
}

export const FormTextEditor = forwardRef<Editor, Props>(({ key, name, message, messageType, ...rest }, ref) => {
  const {
    field: { ref: fieldRef, value },
  } = useField({
    name,
  })

  const { setValue, setError, clearErrors, resetField } = useFormContext()
  const [initialValue] = useState<string>(value?.plain || '')

  const handleValueChange = useCallback(
    (val: RichText) => {
      setValue(name, val, { shouldDirty: val.plain !== initialValue })
    },
    [setValue, name, initialValue],
  )
  const handleErrorChange = useCallback(
    (error: MayBeNull<TextEditorError>) => {
      if (!error) {
        clearErrors(name)
        resetField(name, { keepDirty: true, keepTouched: true })
        return
      }
      setError(name, { type: error.key })
    },
    [setError, name, clearErrors, resetField],
  )

  return (
    <>
      <TextEditor
        ref={mergeRefs([ref, fieldRef])}
        initialValue={value}
        setValue={handleValueChange}
        setError={handleErrorChange}
        name={name}
        optional={true}
        {...rest}
      />
    </>
  )
})
