import qs from 'qs'

import { projectApi } from 'api'
import { TaskDueDate } from 'types/projects/tasks'
import { Timeline } from 'types/projects/timeline'

export interface TimelineParams {
  projectId: string

  // filters
  search?: string
  statuses?: string[]
  assignee?: string[]
  dueDateRanges?: (keyof typeof TaskDueDate)[]
}

export const fetchProjectTimelineApi = ({ projectId, statuses, dueDateRanges, search, assignee }: TimelineParams) =>
  projectApi.get<Timeline>(`/projects/${projectId}/tasks/timeline`, {
    params: {
      ...(search?.length && { search }),
      ...(statuses?.length && { statuses }),
      ...(assignee?.length && { 'filter[assignee]': assignee }),
      ...(dueDateRanges?.length && { dueDateRanges }),
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: false, skipNulls: true }),
    },
  })
