import { projectApi } from 'api'

export interface Props {
  data: {
    id: string
    enabled: boolean
  }[]
}

export const updateProjectsViewSettingsApi = ({ data }: Props) =>
  projectApi.post('/project-attribute-view-settings/', { data })
