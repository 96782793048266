import { projectApi } from 'api'
import { LinearData } from 'pages/project/components/canvas/utils'

export interface MoveActivityProps {
  id: string
  phaseId: string
  orderNumber: number

  // optimistic update data
  optimisticData: Partial<Pick<LinearData, 'phases' | 'items'>>
}

export const moveActivityApi = ({ id, optimisticData, ...body }: MoveActivityProps) =>
  projectApi.patch(`/activities/${id}/move`, body)
