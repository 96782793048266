import { projectApi } from 'api'
import { ProjectFilter } from 'types/projects/projects'
import { ProjectsTimeline } from 'types/projects/projectsTimeline'

export const fetchProjectsTimelineApi = ({
  search,
  ownership,
  type,
  workspace,
  status,
  attributes,
  tenant,
}: Omit<ProjectFilter, 'client' | 'market' | 'brand' | 'viewMode'>) =>
  projectApi.post<ProjectsTimeline>('/projects/timeline', {
    inputText: search,
    'filter[ownership]': ownership,
    'filter[type]': type,
    'filter[workspace]': workspace,
    'filter[status]': status,
    'filter[tenant]': tenant,
    'filter[customAttributes]': attributes,
  })
