import { projectApi } from 'api'
import { WrikeWorkflow } from 'types/integrations/integration'

interface Params {
  id: string
}

interface WrikeWorkflowResponse {
  data: WrikeWorkflow[]
}

export const fetchWrikeIntegrationWorkflowsApi = ({ id }: Params) =>
  projectApi.get<WrikeWorkflowResponse>('wrike/workflows', {
    params: {
      account_id: id,
    },
  })
