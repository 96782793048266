import qs from 'qs'

import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { NativeAppDTO, NativeAppsCategory, NativeAppsCommercialModel } from 'types/products/nativeApp'

interface Props {
  search?: string
  commercialModel?: NativeAppsCommercialModel
  categories?: NativeAppsCategory[]
}

export const fetchNativeAppsApi = ({
  search,
  commercialModel,
  categories,
  page = 1,
  itemsPerPage = 30,
}: PaginationParams<Props>) =>
  projectApi.get<PaginatedResponse<NativeAppDTO>>('/native-apps', {
    params: {
      ...(!!commercialModel ? { commercialModel } : {}),
      ...(search?.length ? { search } : {}),
      ...(categories?.length ? { 'filter[categories]': categories } : {}),
      page,
      itemsPerPage,
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: false, skipNulls: true }),
    },
  })
