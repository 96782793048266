import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showActivityDetailsModal,
  useShowActivityDetailsModal,
} from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/detailsModal/ActivityDetailsModal'
import { DetailsModalType } from 'types/common/utils'

export const useOpenActivityPreviewModal = () => {
  const { visible, hide } = useShowActivityDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, activityId, phaseId, appId } = Object.fromEntries(params.entries()) as {
      view: string
      activityId: string
      appId?: string
      phaseId?: string
    }

    if (view && view === DetailsModalType.ACTIVITY_DETAILS_PREVIEW) {
      showActivityDetailsModal({ activityId, phaseId, appId, onClose: setParams })
    } else visible && hide()
  }, [hide, params, setParams, visible])
}
