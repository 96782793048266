import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { LockStatus } from 'api/canvas/fetchers/canvasLockApi'
import { useCanvasLockApi } from 'api/canvas/queries/useCanvasLockApi'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { useConditionalIdle } from 'pages/project/components/canvas/hooks/useConditionalIdle'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { queryClient } from 'providers/osQueryClient/utils'
import { routesManager } from 'utils/routesManager'
import { useRemoveAllModals } from 'utils/useRemoveAllModals'

const UPDATE_INTERVAL = 30e3
const INACTIVE_INTERVAL = 60e3

export const useCanvasLock = (projectId: string) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const navigate = useNavigate()
  const { removeAllModals } = useRemoveAllModals()
  const { project } = useProject()
  const { trackAction } = useTrackAction()
  const { me } = useHasProjectRole()

  const { data: lockData, isLoading: isLockLoading } = useCanvasLockApi({
    params: { id: projectId },
    refetchInterval: UPDATE_INTERVAL,
    keepPreviousData: true,
  })
  const isLocked = lockData?.status === LockStatus.Locked
  const isIdle = useConditionalIdle(!isLocked, INACTIVE_INTERVAL)

  useEffect(() => {
    return () => {
      // we need loading flag on component mount w/o stale data
      queryClient.removeQueries([ApiQueryKeys.PROJECT_CANVAS_LOCK])
    }
  }, [projectId])

  useEffect(() => {
    if (isIdle) {
      showToast({
        type: 'success',
        message: t('project.canvas.toast.inactivity_redirect'),
      })

      removeAllModals()
      navigate(routesManager.project.workflow.getURL({ id: projectId }))

      const analyticsData = getCommonProjectAnalyticsData(project, me)

      trackAction(ACTION_ANALYTICS.ACTION_CANVAS_REDIRECT, analyticsData)
    }
  }, [
    isIdle,
    isLocked,
    me,
    navigate,
    project,
    project.contextHierarchy,
    project.contextWorkspace,
    project.processType,
    project.type,
    projectId,
    removeAllModals,
    showToast,
    t,
    trackAction,
  ])

  return { isLockLoading, isLocked, lockedBy: lockData?.data.email }
}
