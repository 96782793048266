import { WppAvatarGroup, WppTypography } from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, NavigationTreeNode } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, getAvatarColor } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { NoData } from 'components/common/noData/NoData'
import { useHierarchy } from 'hooks/useHierarchy'
import { useProject } from 'hooks/useProject'
import styles from 'pages/projectDemo/components/projectInfo/ProjectInfo.module.scss'
import { DefaultHierarchyLevelType } from 'types/core'
import { Members } from 'types/members/members'
import { ProjectRole } from 'types/permissions/permissions'
import { Project } from 'types/projects/projects'
import { fullName } from 'utils/common'
import { formatDate } from 'utils/dateFormat'

interface Props {
  project: Project
}

const findType = (nodeType: HierarchyLevelType, nodes: NavigationTreeNode[]) =>
  nodes.find(({ type }) => nodeType === type)

export const ProjectInfo: FC<Props> = ({ project }) => {
  const { t } = useTranslation()

  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()
  const { getWorkspaceTree } = useHierarchy()

  const { members } = useProject()
  const { owners, otherMembers } = useMemo(() => {
    return members.reduce<{ owners: Members[]; otherMembers: Members[] }>(
      (acc, member) => {
        if (member.role === ProjectRole.OWNER) {
          acc.owners.push(member)
        } else {
          acc.otherMembers.push(member)
        }

        return acc
      },
      { owners: [], otherMembers: [] },
    )
  }, [members])

  const workspaceData = getWorkspaceTree(project.contextWorkspace)

  return (
    <div className={styles.projectContainer} data-testid="demo-project-info">
      <WppTypography type="4xl-display" data-testid="demo-project-name">
        {project.name}
      </WppTypography>
      <Flex className={styles.rowOne}>
        <div>
          <WppTypography type="s-midi" className={styles.label}>
            {t('project.demo.project_type')}&nbsp;
          </WppTypography>
          <WppTypography type="s-strong" data-testid="demo-project-type">
            {project.type}
          </WppTypography>
        </div>
        <div className={styles.creationDate}>
          <WppTypography type="s-midi" className={styles.label}>
            {t('project.demo.creation_date')}&nbsp;
          </WppTypography>
          <WppTypography type="s-strong">
            {formatDate({ dateString: project.createdAt, dateLocale, relativeFormatting: true })}
          </WppTypography>
        </div>
      </Flex>

      <Flex className={styles.rowTwo}>
        <Flex direction="column">
          <WppTypography type="s-midi" className={styles.label}>
            {t('project.demo.client')}
          </WppTypography>
          {findType(DefaultHierarchyLevelType.Client, workspaceData) ? (
            <WppTypography type="m-strong" data-testid="demo-project-client">
              {findType(DefaultHierarchyLevelType.Client, workspaceData)?.name}
            </WppTypography>
          ) : (
            <NoData />
          )}
        </Flex>
        <Flex direction="column">
          <WppTypography type="s-midi" className={styles.label}>
            {t('project.demo.market')}
          </WppTypography>
          {findType(DefaultHierarchyLevelType.Market, workspaceData) ? (
            <WppTypography type="m-strong" data-testid="demo-project-market">
              {findType(DefaultHierarchyLevelType.Market, workspaceData)?.name}
            </WppTypography>
          ) : (
            <NoData />
          )}
        </Flex>
        <Flex direction="column">
          <WppTypography type="s-midi" className={styles.label}>
            {t('project.demo.brand')}
          </WppTypography>
          {findType(DefaultHierarchyLevelType.Brand, workspaceData) ? (
            <WppTypography type="m-strong" data-testid="demo-project-brand">
              {findType(DefaultHierarchyLevelType.Brand, workspaceData)?.name}
            </WppTypography>
          ) : (
            <NoData />
          )}
        </Flex>
      </Flex>

      {!!project.description && (
        <div className={styles.rowThree}>
          <div>
            <WppTypography type="s-midi" className={styles.label}>
              {t('project.demo.description')}
            </WppTypography>
          </div>
          <WppTypography type="s-body" data-testid="demo-project-description">
            {project.description}
          </WppTypography>
        </div>
      )}

      <div className={styles.rowThree}>
        <Flex direction="column" gap={8}>
          <WppTypography type="2xs-strong" className={styles.subTitle}>
            {t('project.demo.project_owners', { count: owners.length })}
          </WppTypography>

          <div data-testid="demo-project-owners" className={styles.grid}>
            {owners.map(owner => (
              <Flex gap={8} align="center" key={owner.id}>
                <Avatar size="s" name={fullName(owner.firstname, owner.lastname)} src={owner.avatarUrl ?? ''} />

                <Flex direction="column">
                  <WppTypography type="s-strong">{fullName(owner.firstname, owner.lastname)}</WppTypography>
                  <WppTypography type="s-body">{owner.email}</WppTypography>
                </Flex>
              </Flex>
            ))}
          </div>
        </Flex>
      </div>

      <div className={styles.rowThree}>
        <Flex direction="column" gap={8}>
          {!!otherMembers.length && (
            <>
              <WppTypography type="2xs-strong" className={styles.subTitle}>
                {t('project.demo.project_members')}
              </WppTypography>

              <WppAvatarGroup
                maxAvatarsToDisplay={4}
                size="s"
                withTooltip
                users={otherMembers.map(member => ({
                  name: fullName(member.firstname, member.lastname),
                  src: member.avatarUrl ?? '',
                  color: getAvatarColor({ name: member.firstname }),
                }))}
              />
            </>
          )}
        </Flex>
      </div>
    </div>
  )
}
