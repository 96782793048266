import { MayBeNull, AttachmentMetadata } from '@wpp-open/core'

export interface Presentation {
  id: string
  image: MayBeNull<AttachmentMetadata>
  content: MayBeNull<string>
}

export enum PresentationItemType {
  Application = 'APPLICATION',
}
