import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAddProjectFiles } from 'api/projects/mutation/useAddProjectFiles'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useUploadProjectFilesApi } from 'hooks/attachments/useUploadProjectFilesApi'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { mimeToExtensionMapping } from 'pages/project/components/files/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { UploadInfo } from 'types/attaschments/attachments'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { bytes, formatBytes } from 'utils/files'

interface Props extends NiceModalWrappedProps {
  projectId: string
}

const UploadFilesModal = ({ isOpen, projectId, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { mutateAsync: updateFiles } = useAddProjectFiles()
  const form = useForm({ defaultValues: { files: [] } })
  const [hasError, setError] = useState(false)

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = form

  const handleUploadTenantsFiles = useUploadProjectFilesApi()

  const onSubmit = handleSubmit(async values => {
    try {
      const resp = await handleUploadTenantsFiles(values.files, projectId)
      await updateFiles({ projectId, body: resp as unknown as UploadInfo[] })

      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES_FETCHER])
      tableActions.reload([TableKey.PROJECT_FILE_LIST])
      onClose()
    } catch (e) {
      console.log(e)
      showToast({ type: 'error', message: t('project.files.toast_error_add_message')! })
    }
  })

  const fixLocalMsg = useCallback(
    (_: string, size: number) => `Maximum file size for upload is ${formatBytes(bytes(size, 'mb'))}.`,
    [],
  )

  const { files } = watch()

  const handleClose = () => {
    if (!isSubmitting) onClose()
  }

  return (
    <FormProvider {...form}>
      <SideModal
        size="m"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={handleClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid="upload-files-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('project.files.file_modal.title')}
        </WppTypography>

        <div slot="body">
          <FormFileUpload
            name="files"
            size={1024}
            maxLabelLength={45}
            locales={{ info: fixLocalMsg }}
            acceptConfig={mimeToExtensionMapping}
            onWppChange={e => setError(e.detail.hasError)}
            multiple={true}
          />
        </div>
        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" size="m" onClick={handleClose} disabled={isSubmitting}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton
            type="submit"
            size="m"
            loading={isSubmitting}
            disabled={!files.length || hasError}
            variant="primary"
          >
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showUploadFilesModal } = createNiceModal<Props>(
  UploadFilesModal,
  'project-upload-files-modal',
)
