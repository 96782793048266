/**
 * @see https://gitlab.com/2sixty/chaos/osmium/public/core/wpp-open-core-fe/-/blob/develop/src/utils/links.ts#L3
 */
export const simulateLinkClick = ({
  href,
  target,
}: {
  href: string
  target?: '_self' | '_blank' | '_parent' | '_top'
}) => {
  const link = document.createElement('a')
  link.href = href

  if (target) {
    link.target = target
  }

  if (target === '_blank') {
    link.setAttribute('rel', 'noreferrer')
  }

  link.click()
  link.remove()
}

export const isUrlWithProtocol = (url: string): boolean => {
  return !!url.match(/^(http|https):\/\//)
}

export const urlToAbsolute = (url: string): string => {
  if (!isUrlWithProtocol(url)) {
    return `${window.location.protocol}//${url}`
  }

  return url
}

export const isValidUrl = (url: string): boolean => {
  const urlRegex = new RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    'i',
  )
  return url.length < 2083 && urlRegex.test(url)
}
