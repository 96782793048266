import {
  WppCard,
  WppIconExternalLink,
  WppListItem,
  WppTypography,
  WppInlineMessage,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/projectModal/components/integrations/wrike/WrikeConnect.module.scss'
import { ProjectWrikeDTO } from 'types/projects/projects'
import { fullName } from 'utils/common'
import { DATE_FORMAT } from 'utils/dateFormat'

export const WrikeProjectCard = ({
  wrike,
  showMessage,
}: {
  wrike?: MayBeNull<ProjectWrikeDTO>
  showMessage: boolean
}) => {
  const { t } = useTranslation()

  return (
    <WppCard variant="secondary" size="s" className="wpp-spacing-4-top" data-testid="wrike-card">
      {showMessage && (
        <Flex className="wpp-spacing-4-bottom">
          <WppInlineMessage
            size="m"
            message={t('project.wrike.project_info.disconnect_wrike_info')!}
            type="warning"
            className={styles.warningMessage}
          />
        </Flex>
      )}
      {wrike && (
        <Flex direction="column" gap={4}>
          <Flex justify="between">
            <WppListItem className={styles.projectInfoItem}>
              <span slot="label">{t('project.wrike.project_info.name')}</span>
              <span slot="caption">{wrike.name}</span>
            </WppListItem>

            <Link
              to={wrike.permalink}
              className={styles.externalLink}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="wrike-project-link"
            >
              <WppTypography type="s-strong">{t('project.wrike.project_info.open')}</WppTypography>
              <WppIconExternalLink />
            </Link>
          </Flex>

          <WppListItem className={styles.projectInfoItem}>
            <span slot="label">{t('project.wrike.project_info.project_id')}</span>
            <span slot="caption">{wrike.wrikeProjectId}</span>
          </WppListItem>

          <WppListItem className={styles.projectInfoItem}>
            <span slot="label">{t('project.wrike.project_info.status_title')}</span>
            <span slot="caption">{t('project.wrike.project_info.status_value')}</span>
          </WppListItem>

          <WppListItem className={styles.projectInfoItem}>
            <span slot="label">{t('project.wrike.project_info.connected_by')}</span>

            <span slot="caption" className={styles.width}>
              <Link to={`mailto: ${wrike?.connectedBy?.email}`} className={styles.link}>
                {fullName(wrike.connectedBy?.firstname, wrike.connectedBy?.lastname)}
              </Link>
              {t('project.wrike.project_info.connected_on')}
              {wrike.connectedAt
                ? t('project.wrike.project_info.linked_at_value', {
                    date: format(parseISO(wrike.connectedAt), DATE_FORMAT.DD_LLL_YYYY),
                    time: format(parseISO(wrike.connectedAt), 'HH:mm'),
                  })
                : '-'}
            </span>
          </WppListItem>
          <WppListItem className={styles.projectInfoItem}>
            <span slot="label">{t('project.wrike.project_info.workspace')}</span>
            <span slot="caption">{wrike.accountName}</span>
          </WppListItem>
        </Flex>
      )}
    </WppCard>
  )
}
