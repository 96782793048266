import {
  WppListItem,
  WppTypography,
  WppIconAvailableCheckmark,
  WppIconCircle,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import styles from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu.module.scss'

export const StatusMenuItem = ({
  isChecked,
  children,
  onWppChangeListItem,
  'data-testid': dataTestId,
}: {
  isChecked: boolean
  children: ReactNode
  onWppChangeListItem?: () => void
  'data-testid'?: string
}) => {
  const Icon = isChecked ? WppIconAvailableCheckmark : WppIconCircle

  return (
    <WppListItem
      onWppChangeListItem={isChecked ? undefined : onWppChangeListItem}
      checked={isChecked}
      data-testid={dataTestId}
    >
      <Icon slot="left" />
      <WppTypography slot="label" type="s-body" className={clsx({ [styles.textColor]: isChecked })}>
        {children}
      </WppTypography>
    </WppListItem>
  )
}
