import { useMemo } from 'react'

import { MayBeNull } from 'types/common/utils'
import { WrikeWorkflows } from 'types/projects/WrikeWorkflows'

export const useCustomExternalStatuses = (wrikeWorkflows: MayBeNull<WrikeWorkflows>) => {
  const externalStatusesEntries = useMemo(() => {
    if (!wrikeWorkflows?.customStatuses) return {}
    return wrikeWorkflows.customStatuses.reduce((acc: Record<string, string>, customStatus) => {
      acc[customStatus.wrikeId] = customStatus.name
      return acc
    }, {})
  }, [wrikeWorkflows?.customStatuses])

  const externalStatuses = useMemo(() => Object.keys(externalStatusesEntries), [externalStatusesEntries])

  const externalStatusesMapped = useMemo(() => {
    if (!wrikeWorkflows?.statusMappings) return []
    return wrikeWorkflows.statusMappings
      .filter(status => externalStatuses.includes(status.wrikeStatusId) && status.direction === 'to_os')
      .map(status => status.wrikeStatusId)
  }, [externalStatuses, wrikeWorkflows?.statusMappings])

  return { externalStatusesEntries, externalStatusesMapped }
}
