import { Tenant } from '@wpp-open/core'
import { TenantType } from '@wpp-open/core/types/tenant'

import { FACADE_MAX_ITEMS, facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'

interface FetchTenantsParams {
  tenantType?: TenantType
}

export const fetchTenantsApi = ({
  page = 1,
  itemsPerPage = FACADE_MAX_ITEMS,
  tenantType,
}: PaginationParams<FetchTenantsParams>) =>
  facadeApi.get<PaginatedResponse<Tenant>>('/tenants', {
    params: {
      page,
      itemsPerPage,
      ...(tenantType && { 'filter[tenantType]': tenantType }),
    },
  })
