// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UthEv{margin:-9px -16px}.UthEv::part(anchor){padding:9px 16px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/selectProcessType/SelectProcessType.module.scss"],"names":[],"mappings":"AAAA,OAEE,iBAAA,CAEA,qBACE,gBAAA","sourcesContent":[".segmentTooltip {\n  // show tooltip on all segment area, values are based on var(--sc-item-text-padding-m)\n  margin: -9px -16px;\n\n  &::part(anchor) {\n    padding: 9px 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segmentTooltip": `UthEv`
};
export default ___CSS_LOADER_EXPORT___;
