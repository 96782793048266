import { Connection, Edge, Node } from 'reactflow'

import {
  ActivityItem,
  ApplicationItem,
  Container,
  ContainerType,
  IConnection,
  PhaseItemType,
} from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

export enum CustomMarkerType {
  MARKER_PRIMARY = 'marker-primary',
  MARKER_SECONDARY = 'marker-secondary',
}

export enum NodeType {
  INPUT = 'input',
  APP_NODE = 'app_node',
  DEFAULT = 'default',
  GROUP = 'group',
  OUTPUT = 'output',
  ACTIVITY_NODE = 'activity_node',
}

export enum EdgeType {
  DEFAULT = 'default',
  INPUT = 'input',
  OUTPUT = 'output',
  CUSTOM_EDGE = 'customEdge',
}

export enum ConnectionType {
  FLOW = 'FLOW',
  DATA = 'DATA',
}

// temporary filter data connections, @see https://jira.uhub.biz/browse/WPPLONOP-15513
export const HIDE_DATA_CONNECTION = true

export enum EdgePosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const START_POSITION = { x: -100, y: -350 } as const
export const ITEM_OFFSET = { x: 75, y: 75 } as const

// !IMPORTANT! must be aligned with an **actual** activity markup
export const INNER_PREVIEW_APP_RECT = {
  startX: 12,
  startY: 0,
  height: 50,
  gap: 8,
} as const

export const INNER_APP_RECT = {
  startX: 12,
  startY: 0,
  height: 175,
  gap: 8,
} as const

export const LINKS_RECT = {
  height: 32,
  margins: 32,
} as const

export const FILES_RECT = {
  height: 42,
  margins: 12,
} as const

export const edgeConnectValidation = ({ connection }: { connection: Connection }) => {
  let errorCode = ''

  const connectedToSameNode = connection.source === connection.target

  if (connectedToSameNode) {
    errorCode = 'rejectConnection'
  }

  if (connection.sourceHandle === ConnectionType.DATA && connection.targetHandle === ConnectionType.FLOW) {
    errorCode = 'rejectConnection'
  }
  if (connection.sourceHandle === ConnectionType.FLOW && connection.targetHandle === ConnectionType.DATA) {
    errorCode = 'rejectConnection'
  }

  return errorCode
}

export const calcInnerAppPosition = (orderNumber: number, isPreview?: boolean): { x: number; y: number } => {
  const { startX, startY, height, gap } = isPreview ? INNER_PREVIEW_APP_RECT : INNER_APP_RECT

  return {
    x: startX,
    y: startY + orderNumber * (height + gap),
  }
}

// sort inner apps and place them into a column
export const alignChildren = (nodes: Node[], activityContainerId: string): Node[] => {
  const children = nodes.filter(({ parentNode }) => activityContainerId === parentNode)

  const sortedChildren = [...children]
    .sort(({ position: positionA }, { position: positionB }) => {
      return positionA.y === positionB.y ? 0 : positionA.y < positionB.y ? -1 : 1
    })
    .reduce(
      (acc, node, index) => ({ ...acc, [node.id]: { node, orderNumber: index } }),
      {} as Record<string, { node: Node; orderNumber: number }>,
    )

  return nodes.map(node => {
    if (sortedChildren[node.id]) {
      const { orderNumber } = sortedChildren[node.id]
      return {
        ...node,
        style: {
          marginTop: `var(--inner-start-${activityContainerId}, 0)`,
        },
        position: calcInnerAppPosition(orderNumber),
      }
    }

    return node
  })
}

export const sortNodesByType = (dataNodes: Node[]): Node[] => {
  const nodesMap = Object.fromEntries(dataNodes.map(node => [node.id, node]))

  return [...dataNodes].sort((nodeA: Node, nodeB: Node) => {
    const parentAPosition = nodeA.parentNode ? nodesMap[nodeA.parentNode].position.y : null
    const parentBPosition = nodeB.parentNode ? nodesMap[nodeB.parentNode].position.y : null

    // sort nodes by their Y position, with inner apps exception:
    // inner apps should always be on position next to their parent activity
    const positionA = parentAPosition ? parentAPosition + 0.001 : nodeA.position.y
    const positionB = parentBPosition ? parentBPosition + 0.001 : nodeB.position.y

    return positionA === positionB ? 0 : positionA < positionB ? -1 : 1
  })
}

export const mapFluidDataNodes = ({
  containers,
  itemsMap,
  preview,
  userEmail,
  isOwnerOrGlobal,
  templateView,
  isInactive,
}: {
  containers: Container[]
  itemsMap: Record<string, ApplicationItem | ActivityItem>
  preview?: boolean
  userEmail?: string
  isOwnerOrGlobal?: boolean
  templateView?: boolean
  isInactive?: boolean
}): Node[] => {
  let startX = START_POSITION.x
  let startY = START_POSITION.y

  const containersMap = Object.fromEntries(containers.map(container => [container.id, container]))

  const dataNodes = containers.map<Node>(container => {
    const nodeApp = itemsMap[container.itemId]

    const x = container.coordinateX ?? (startX += ITEM_OFFSET.x)
    const y = container.coordinateY ?? (startY += ITEM_OFFSET.y)

    const isIAssignee = isEqualEmails(nodeApp.assignUser, userEmail)
    const isOwnerOrAssignee = isIAssignee || !!isOwnerOrGlobal

    if (container.itemType === PhaseItemType.Application) {
      const nodeApp = itemsMap[container.itemId] as ApplicationItem

      const node = {
        id: container.id,
        type: NodeType.APP_NODE,
        data: {
          label: nodeApp.name,
          item: nodeApp,
          editMode: isOwnerOrAssignee,
          preview,
          templateView,
          isIAssignee,
          isInactive,
          oldPosition: {
            x,
            y,
          },
        },
        draggable: !preview && !isInactive && isOwnerOrAssignee,
        selectable: !templateView && (preview || isOwnerOrAssignee),
        connectable: !preview && !isInactive && isOwnerOrAssignee,
        deletable: !preview && isOwnerOrAssignee,
        position: { x, y },
      }

      if (container.containerType === ContainerType.ROOT) {
        return node
      }

      // override inner apps positions
      const rootContainerId = container.rootContainerId!
      const parentContainer = containersMap[rootContainerId]
      const parentActivity = itemsMap[parentContainer.itemId] as ActivityItem

      const { x: innerX, y: innerY } = calcInnerAppPosition(container.orderNumberInRoot!, preview)

      const isIAssignToThisActivity = isEqualEmails(parentActivity.assignUser, userEmail)
      // update editing condition for inner apps
      const editMode = isOwnerOrAssignee || isIAssignToThisActivity
      const dndMode = isOwnerOrGlobal || isIAssignToThisActivity

      return {
        ...node,
        style: {
          marginTop: `var(--inner-start-${rootContainerId}, 0)`,
        },
        parentNode: rootContainerId,
        position: { x: innerX, y: innerY },
        draggable: !isInactive && !preview && dndMode,
        selectable: !templateView && (preview || isOwnerOrAssignee),
        connectable: !preview && editMode && !isInactive,
        deletable: !preview && editMode,
        data: {
          ...node.data,
          editMode,
          parentActivityId: parentActivity.id,
          oldPosition: {
            x: innerX,
            y: innerY,
          },
        },
      }
    }

    const activityItem = itemsMap[container.itemId] as ActivityItem
    return {
      id: container.id,
      type: NodeType.ACTIVITY_NODE,
      data: {
        label: activityItem.name,
        item: activityItem,
        editMode: isOwnerOrAssignee,
        preview,
        templateView,
        isInactive,
        oldPosition: {
          x,
          y,
        },
      },
      draggable: !isInactive && !preview && isOwnerOrAssignee,
      selectable: preview || isOwnerOrAssignee,
      connectable: !preview && isOwnerOrAssignee && !isInactive,
      deletable: !preview && isOwnerOrAssignee,
      position: { x, y },
    }
  })

  // activities must be always first, for proper z-index
  return sortNodesByType(dataNodes)
}

interface ReverseMap {
  [revertTargetId: string]: IConnection
}
// list of "reverse' connections - same nodes, but in opposite directions.
export const getReverseDataConnectionsMap = (connections: IConnection[]) => {
  return connections
    .filter(({ type }) => type === ConnectionType.DATA)
    .reduce((acc, connection) => {
      if (!acc[connection.sourceId]) {
        acc[connection.targetId] = connection
      }
      return acc
    }, {} as ReverseMap)
}

export const mapFluidEdges = (
  connections: IConnection[],
  nodes: Node[],
  containers: Container[],
  itemsMap: Record<string, ApplicationItem | ActivityItem>,
  projectId: string,
  userEmail?: string,
  preview?: boolean,
  isOwner?: boolean,
  isInactive?: boolean,
): Edge[] => {
  const nodesMap = Object.fromEntries(nodes.map(node => [node.id, node]))
  const containersMap = Object.fromEntries(containers.map(container => [container.id, container]))
  const reverseDataConnectionsMap = getReverseDataConnectionsMap(connections)

  return connections.map(connection => {
    const isDataEdge = connection.type === ConnectionType.DATA

    const sourceNode = nodesMap[connection.sourceId]
    const targetNode = nodesMap[connection.targetId]
    const isInnerEdge =
      !!sourceNode.parentNode && !!targetNode.parentNode && sourceNode.parentNode === targetNode.parentNode

    const sourceContainer = containersMap[connection.sourceId]
    const targetContainer = containersMap[connection.targetId]
    const sourceNodeApp = itemsMap[sourceContainer?.itemId ?? '-1']
    const targetNodeApp = itemsMap[targetContainer?.itemId ?? '-1']

    const isOwnerOrAssignee =
      (isEqualEmails(sourceNodeApp.assignUser, userEmail) && isEqualEmails(targetNodeApp.assignUser, userEmail)) ||
      isOwner

    const isSourceInnerDataEdge = isDataEdge && sourceContainer?.containerType === ContainerType.NESTED
    const isTargetInnerDataEdge = isDataEdge && targetContainer?.containerType === ContainerType.NESTED
    const sourceAnchorSide =
      isSourceInnerDataEdge && !['LEFT', 'RIGHT'].includes(connection.sourceAnchorSide)
        ? 'LEFT'
        : connection.sourceAnchorSide
    const targetAnchorSide =
      isTargetInnerDataEdge && !['LEFT', 'RIGHT'].includes(connection.targetAnchorSide)
        ? 'LEFT'
        : connection.targetAnchorSide

    const reverseConnection = reverseDataConnectionsMap[connection.sourceId]
    const hasReverse =
      reverseConnection &&
      connection.targetAnchorSide === reverseConnection.sourceAnchorSide &&
      connection.sourceAnchorSide === reverseConnection.targetAnchorSide

    const animated = isDataEdge && !hasReverse

    return {
      id: connection.id,
      source: connection.sourceId,
      target: connection.targetId,
      sourceHandle: `${sourceAnchorSide}_${connection.type}`,
      targetHandle: `${targetAnchorSide}_${connection.type}`,
      animated,
      className: isDataEdge ? 'dataEdge' : 'flowEdge',
      data: {
        preview,
        isInactive,
        canEdit: isOwnerOrAssignee,
        isInnerEdge,
        projectId,
      },
      markerEnd: isDataEdge ? CustomMarkerType.MARKER_SECONDARY : CustomMarkerType.MARKER_PRIMARY,
      zIndex: 1,
      type: 'customEdge',
    }
  })
}

export const splitByUnderscore = (str: string) => {
  const [first, ...rest] = str.split('_')
  return [first, rest.join('_')]
}

// only self-assigned apps can be moved out of activity - to keep possibility operate with this app after the drop
export const canDropOutOfActivity = (node: Node): boolean => {
  return node.data.isIAssignee
}

export const canDropInActivity = (node: Node, targetActivity: Node, edges: Edge[]): boolean => {
  if (!targetActivity.data.editMode) {
    return false
  }

  // find all connections
  const nodeEdges = edges.filter((edge: Edge) => {
    return edge.target === node.id || edge.source === node.id
  })
  const hasFlowConnections = nodeEdges.some(edge => edge.targetHandle?.includes(ConnectionType.FLOW))
  return !hasFlowConnections
}

export const updateNodeEdges = (node: Node, nodes: Node[], edges: Edge[]): Edge[] => {
  const nodesMap = Object.fromEntries(nodes.map(node => [node.id, node]))

  return edges.map(edge => {
    if (edge.source === node.id || edge.target === node.id) {
      const sourceNode = nodesMap[edge.source]
      const targetNode = nodesMap[edge.target]
      const isInnerEdge =
        !!sourceNode.parentNode && !!targetNode.parentNode && sourceNode.parentNode === targetNode.parentNode

      return {
        ...edge,
        data: {
          ...edge.data,
          isInnerEdge,
        },
      }
    }

    return edge
  })
}

export const revertNodeOnDrop = (droppedNode: Node, nodes: Node[]): Node[] => {
  return nodes.map(node => {
    if (node.id === droppedNode.id && droppedNode.data.oldPosition) {
      return {
        ...node,
        position: {
          // revert position
          x: droppedNode.data.oldPosition.x,
          y: droppedNode.data.oldPosition.y,
        },
      }
    }
    return node
  })
}
