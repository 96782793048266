import {
  WppActionButton,
  WppButton,
  WppEmpty404,
  WppIconGear,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { SideModalContentSkeleton } from 'components/common/sideModalSkeleton/SideModalContentSkeleton'
import { useProject } from 'hooks/useProject'
import { useProjectRole } from 'hooks/useProjectRole'
import { ErrorState } from 'pages/components/templatePreviewModal/utils'
import { showAddEditActivityModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AddEditActivityModal'
import styles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { ActivityDetailsModalHeader } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/detailsModal/ActivityDetailsModalHeader'
import { ActivityDetailsModalTabs } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/detailsModal/ActivityDetailsModalTabs'
import { DetailsModalType } from 'types/common/utils'
import { ProcessType } from 'types/projects/projects'
import { ActivityItem } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

export enum ActivityTabsTypes {
  apps = 'apps',
  resources = 'resources',
}

export const activityTabs = [
  { id: ActivityTabsTypes.apps, value: 'modals.activity_details.tabs.apps' },
  { id: ActivityTabsTypes.resources, value: 'modals.activity_details.tabs.resources' },
]

interface Props {
  activity?: ActivityItem | null
  phaseId?: string
  isLoading?: boolean
  isError?: boolean
  error?: AxiosError<unknown, any> | null
  onClose: () => void
}

export const ActivityDetailsModalContent = ({ activity, phaseId, isLoading, isError, error, onClose }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isOwnerOrGlobalManage } = useProjectRole()
  const { project, linearData: canvas, isInactive } = useProject()
  const {
    osContext: { userDetails },
  } = useOs()

  const getPhase = useMemo(() => {
    return phaseId ? canvas?.phases[phaseId] : null
  }, [canvas, phaseId])

  const [, setSearchParams] = useSearchParams()

  const isMeAssignToThisActivity = useMemo(
    () => isEqualEmails(userDetails.email, activity?.assignUser),
    [activity?.assignUser, userDetails.email],
  )

  const isMeAssignToThisPhase = useMemo(
    () => isEqualEmails(userDetails.email, getPhase?.assignUser),
    [getPhase?.assignUser, userDetails.email],
  )

  const canEdit = !isInactive && (isOwnerOrGlobalManage || isMeAssignToThisActivity || isMeAssignToThisPhase)

  const setSelectedAppIdCallback = useCallback(
    (appId: string | null) => {
      const view =
        project.processType === ProcessType.LINEAR
          ? DetailsModalType.PHASE_DETAILS_PREVIEW
          : DetailsModalType.ACTIVITY_DETAILS_PREVIEW

      setSearchParams({
        view,
        ...(phaseId && { phaseId }),
        ...(activity?.id && { activityId: activity.id }),
        ...(appId && { appId }),
      })
    },
    [activity?.id, phaseId, project.processType, setSearchParams],
  )

  const filteredTabs = useMemo(() => {
    if (!activity) return []

    const tabConditions: Record<ActivityTabsTypes, boolean> = {
      apps: !!activity.items.length,
      resources: !!activity.files.length || !!activity.externalLinks?.length,
    }

    return activityTabs.filter(({ id }) => {
      return id in tabConditions ? tabConditions[id] : true
    })
  }, [activity])

  return (
    <>
      {isLoading ? (
        <SideModalContentSkeleton />
      ) : (
        <>
          <WppTypography slot="header" type="2xl-heading" data-testid="activity-name">
            {activity?.name ?? t('modals.activity_details_modal.title')}
          </WppTypography>

          <Flex slot="body" direction="column" gap={16} className={styles.detailsContainer}>
            {isError ? (
              <ErrorState error={error} />
            ) : (
              <>
                <>
                  {activity ? (
                    <>
                      <ActivityDetailsModalHeader activity={activity} />
                      {!!filteredTabs.length && (
                        <ActivityDetailsModalTabs
                          activity={activity}
                          setSelectedAppId={setSelectedAppIdCallback}
                          tabs={filteredTabs}
                        />
                      )}
                    </>
                  ) : (
                    <Flex
                      justify="center"
                      align="center"
                      className={styles.noResultsContainer}
                      direction="column"
                      gap={24}
                    >
                      <WppEmpty404 width={120} />
                      <Flex justify="center" align="center" direction="column" gap={8}>
                        <WppTypography type="l-strong" className={styles.greyColor1000}>
                          {t('modals.errors.404.title')}
                        </WppTypography>
                        <WppTypography type="xs-body" className={styles.greyColor800}>
                          {t('modals.errors.404.description')}
                        </WppTypography>
                      </Flex>
                    </Flex>
                  )}
                </>
              </>
            )}
          </Flex>
          <Flex slot="actions" justify="end" gap={12}>
            <Flex justify="between" className={styles.actionsWrapper}>
              {canEdit && activity ? (
                <WppActionButton
                  onClick={() => {
                    onClose()
                    showAddEditActivityModal({
                      projectId: project.id,
                      phaseId: 'fluid',
                      activity,
                      onClose: async () => {
                        navigate(-1)
                      },
                      isWrikeConnected: !!project.wrike?.isConnected,
                    })
                  }}
                  data-testid="settings-action"
                  variant="primary"
                >
                  <WppIconGear slot="icon-start" />
                  {t('project.item.settings')}
                </WppActionButton>
              ) : (
                <div />
              )}

              <WppButton variant="primary" size="m" onClick={onClose}>
                {t('common.btn_done')}
              </WppButton>
            </Flex>
          </Flex>
        </>
      )}
    </>
  )
}
