import React, { useMemo } from 'react'

import { LinearPreviewActivity } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/LinearPreviewActivity'
import { LinearPreviewApplication } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/LinearPreviewApplication'
import { TemplatePhaseItem as TemplatePhaseItemProps } from 'types/projects/template'
import { ActivityItem, ApplicationItem, PhaseItemType } from 'types/projects/workflow'

interface Props {
  phaseItem: TemplatePhaseItemProps
  index: number
  variant?: 'primary' | 'secondary'
}

export const TemplatePhaseItem = ({ phaseItem }: Props) => {
  if (phaseItem.itemType === PhaseItemType.Application) {
    const item = phaseItem.item as ApplicationItem
    return <LinearPreviewApplication key={phaseItem.id} application={item} isDisabled templateView />
  }

  const item = phaseItem.item as ActivityItem
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sortedExternalLinksByNewest = useMemo(() => {
    return [...(item.externalLinks || [])]?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  }, [item?.externalLinks])

  return (
    <LinearPreviewActivity
      key={phaseItem.id}
      activityItem={item}
      isDisabled
      fileAction
      sortedExternalLinks={sortedExternalLinksByNewest}
      templateView
    />
  )
}
