import {
  WppActionButton,
  WppIconBold,
  WppIconItalic,
  WppIconOrderedList,
  WppIconUnorderedList,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { EditorState, RichUtils } from 'draft-js'
import { ComponentProps, FC, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/richText/editorToolbar/EditorToolbar.module.scss'

interface Props {
  editorState: EditorState
  setEditorState: (val: EditorState) => void
}

enum Option {
  STYLE,
  BLOCK,
}

interface ToolbarOption {
  value: string
  Icon: FC<ComponentProps<typeof WppIconBold>>
  type: Option
}

const options: ToolbarOption[] = [
  {
    value: 'BOLD',
    Icon: WppIconBold,
    type: Option.STYLE,
  },
  {
    value: 'ITALIC',
    Icon: WppIconItalic,
    type: Option.STYLE,
  },
  {
    value: 'UNDERLINE',
    Icon: () => <p className={styles.underline}>U</p>,
    type: Option.STYLE,
  },
  {
    value: 'ordered-list-item',
    Icon: WppIconOrderedList,
    type: Option.BLOCK,
  },
  {
    value: 'unordered-list-item',
    Icon: WppIconUnorderedList,
    type: Option.BLOCK,
  },
]

export const EditorToolbar = ({ editorState, setEditorState }: Props) => {
  const handleToggleClick = useCallback(
    (e: React.MouseEvent, inlineStyle: string) => {
      e.preventDefault()
      setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
    },
    [editorState, setEditorState],
  )

  const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
    e.preventDefault()
    setEditorState(RichUtils.toggleBlockType(editorState, blockType))
  }

  const startKey = editorState.getSelection().getStartKey()
  const selectedBlockType = editorState.getCurrentContent().getBlockForKey(startKey).getType()

  return (
    <Flex gap={8} className={styles.textEditorFooter}>
      {options.map(({ value, Icon, type }) => {
        const handler = type === Option.STYLE ? handleToggleClick : handleBlockClick
        const isSelected =
          type === Option.STYLE ? editorState.getCurrentInlineStyle().has(value) : selectedBlockType === value
        return (
          <WppActionButton
            data-testid={`toolbar-btn-${value}`}
            className={clsx(styles.actionButton, { [styles.isSelected]: isSelected })}
            onMouseDown={e => handler(e, value)}
            variant="secondary"
            key={value}
          >
            <Icon />
          </WppActionButton>
        )
      })}
    </Flex>
  )
}
