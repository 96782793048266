// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GgEzS{margin-top:28px}.eXSe2{--card-primary-bg-color: var(--wpp-grey-color-100)}.Lhfxn{position:relative;height:100%}._5trPT{--im-error-background-color: var(--wpp-danger-color-200);position:sticky;top:4px;display:block;margin-bottom:16px}.W90eZ{height:100%}.oPKpq{margin-right:6.5px;color:var(--wpp-primary-color-500)}.a2Bn9::part(step-index),.a2Bn9::part(step-label){cursor:auto}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/CreateProjectModal.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAGF,OACE,kDAAA,CAGF,OACE,iBAAA,CACA,WAAA,CAGF,QACE,wDAAA,CAEA,eAAA,CACA,OAAA,CACA,aAAA,CACA,kBAAA,CAGF,OACE,WAAA,CAGF,OACE,kBAAA,CACA,kCAAA,CAIA,kDAEE,WAAA","sourcesContent":[".presetContainer {\n  margin-top: 28px;\n}\n\n.templatePreset {\n  --card-primary-bg-color: var(--wpp-grey-color-100);\n}\n\n.modalBody {\n  position: relative;\n  height: 100%;\n}\n\n.templateError {\n  --im-error-background-color: var(--wpp-danger-color-200);\n\n  position: sticky;\n  top: 4px;\n  display: block;\n  margin-bottom: 16px;\n}\n\n.skeletonContainer {\n  height: 100%;\n}\n\n.backIcon {\n  margin-right: 6.5px;\n  color: var(--wpp-primary-color-500);\n}\n\n.step {\n  &::part(step-index),\n  &::part(step-label) {\n    cursor: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"presetContainer": `GgEzS`,
	"templatePreset": `eXSe2`,
	"modalBody": `Lhfxn`,
	"templateError": `_5trPT`,
	"skeletonContainer": `W90eZ`,
	"backIcon": `oPKpq`,
	"step": `a2Bn9`
};
export default ___CSS_LOADER_EXPORT___;
