import { NavigationState } from '@platform-ui-kit/components-library'
import { WppTypography, WppTopbar } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import { useIsPermitted } from 'hooks/useIsPermitted'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { AppPermissions } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

type ExtendedNavigationState = NavigationState & {
  enabled: boolean
}

export const DashboardNavigation = () => {
  const { osContext } = useOs()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { isPermitted } = useIsPermitted()

  const rootPath = location.pathname.split('/')[1]
  const [value, setValue] = useState(rootPath)
  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const canViewTemplate = isPermitted(AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_VIEW) || isGlobalManage

  const canViewSettings =
    isPermitted(AppPermissions.ORCHESTRATION_WORKSPACE_ATTRIBUTE_MANAGE) ||
    isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const navigationData: ExtendedNavigationState[] = useMemo(
    () => [
      {
        label: t('dashboard.navigation.home'),
        value: routesManager.projects.root.shortPattern,
        path: `/${osContext.baseUrl}${routesManager.projects.root.getURL()}`,
        enabled: true,
      },
      {
        label: t('dashboard.navigation.tasks'),
        value: routesManager.tasks.root.shortPattern,
        path: `/${osContext.baseUrl}${routesManager.tasks.root.getURL()}`,
        enabled: true,
      },
      {
        label: t('dashboard.navigation.templates'),
        value: routesManager.templates.root.shortPattern,
        path: `/${osContext.baseUrl}${routesManager.templates.root.getURL()}`,
        enabled: canViewTemplate,
      },
      {
        label: t('dashboard.navigation.settings'),
        value: routesManager.settings.root.shortPattern,
        path: `/${osContext.baseUrl}${routesManager.settings.root.getURL()}`,
        enabled: canViewSettings,
      },
    ],
    [canViewSettings, canViewTemplate, osContext.baseUrl, t],
  ).filter(item => item.enabled)

  const handleTopbarItemChange = ({ detail }: CustomEvent) => {
    setValue(detail.value)
    navigate(`/${detail.value}`)
  }

  return (
    <WppTopbar
      value={value}
      navigation={navigationData}
      onWppChange={handleTopbarItemChange}
      data-testid="dashboard-topbar"
      className={styles.topBar}
    >
      <div slot="app">
        <WppTypography type="m-strong" className={styles.topBarItem}>
          {t('dashboard.navigation.title')}
        </WppTypography>
      </div>
    </WppTopbar>
  )
}
