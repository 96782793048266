import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { forwardRef, ComponentProps } from 'react'

import styles from 'components/common/truncate/Truncate.module.scss'

type Props = ComponentProps<typeof WppTypography> & {
  lines?: number
}

export const Truncate = forwardRef<HTMLWppTypographyElement, Props>(({ lines = 0, style, className, ...rest }, ref) => {
  if (lines) {
    return (
      <WppTypography
        className={clsx(
          {
            [styles.multiple]: true,
            [styles.breakAll]: lines === 1, // for single line, break-all allows to show more information w/o worsening the UX
          },
          className,
        )}
        {...rest}
        ref={ref}
        {...((!!style || !!lines) && {
          style: { ...style, '--lines': lines },
        })}
      />
    )
  }

  return <WppTypography className={clsx(styles.single, className)} {...rest} ref={ref} style={style} />
})
