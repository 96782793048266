import { useProjectAppApi } from 'api/projects/queries/useProjectAppApi'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { AppDetailsModalContent } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/detailsModal/AppDetailsModalContent'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  appId: string
  activityId?: string
  phaseId?: string
}

const AppDetailsModal = ({ appId, onClose, activityId, onCloseComplete, isOpen, phaseId }: Props) => {
  const { data: application, isLoading, isError, error } = useProjectAppApi({ params: { id: appId } })

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="m"
      data-testid="app-details-modal"
    >
      <AppDetailsModalContent
        application={application}
        isError={isError}
        error={error}
        phaseId={phaseId}
        activityId={activityId}
        onClose={onClose}
        isLoading={isLoading}
      />
    </SideModal>
  )
}

export const { showModal: showAppDetailsModal, useModal: useShowAppDetailsModal } = createProjectModal<Props>(
  AppDetailsModal,
  'app-details-modal',
)
