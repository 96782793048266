import { WppButton, WppTypography, WppDivider } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { usePatchApplicationApi } from 'api/canvas/mutation/usePatchApplicationApi'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ACTION_ANALYTICS, CanvasItemType } from 'constants/analytics'
import { useForm } from 'hooks/form/useForm'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import useEventSource from 'hooks/useEventSource'
import { useProject } from 'hooks/useProject'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/components/editPhaseModal/EditPhaseModal.module.scss'
import { SelectOsGroupForm } from 'pages/project/components/canvas/components/selectOsGroup/SelectOsGroupForm'
import { SelectPerson } from 'pages/project/components/canvas/components/selectPerson/SelectPerson'
import {
  ResponsibleUser,
  getFormattedDates,
  useGetBaseFormValues,
} from 'pages/project/components/canvas/components/selectPerson/utils'
import {
  applicationValidationScheme,
  invalidateCanvas,
} from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { useExternalStatusList } from 'pages/project/hooks/useExternalStatusList'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { ProjectRole } from 'types/permissions/permissions'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem } from 'types/projects/workflow'
import { makeStringShorter } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  application: ApplicationItem
  projectId: string
  isDisabled?: boolean
  isWrikeConnected?: boolean
  isTemplate?: boolean
}

const EditAppModal = ({
  onClose,
  onCloseComplete,
  isOpen,
  application,
  isDisabled,
  projectId,
  isWrikeConnected,
  isTemplate = false,
}: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { calculateTimeDifferenceInSeconds } = useTimeTracker()
  const { wrikeWorkflows, useExternalStatuses, project } = useProject() ?? {}
  const { trackAction } = useTrackAction()
  const { me } = useHasProjectRole()

  const eventSource = useEventSource()

  const initialStatus = useExternalStatuses ? application?.task?.wrike?.externalStatusId! : application?.task?.status!
  const defaultValues = {
    ...useGetBaseFormValues(application, projectId, initialStatus),
    ...(isTemplate && { groupId: application?.group?.id || null }),
  }

  const form = useForm({ defaultValues, validationSchema: applicationValidationScheme })

  const { mutateAsync: handlePatchApp } = usePatchApplicationApi()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async ({ dueDate, name, description, status, groupId, ...values }) => {
    try {
      const assignUser = values.assignUser[0] as ResponsibleUser

      const body = {
        id: application.id,
        name: name?.trim() || null,
        description: description?.trim() || null,
        ...getFormattedDates(dueDate),
        assignUser: assignUser?.email || null,
        [useExternalStatuses && wrikeWorkflows ? 'taskExternalStatusId' : 'taskStatus']: status,

        ...(isTemplate && { groupId: groupId || null }),
      }
      await handlePatchApp(body)
      await invalidateCanvas(!assignUser?.isMember)

      showToast({ type: 'success', message: t('project.canvas.toast.update_app', { query: makeStringShorter(name) }) })

      if (project) {
        const analyticsData = {
          ...getCommonProjectAnalyticsData(project, me),
          type: CanvasItemType.APPLICATION,
          responsible_person_role: !assignUser?.isMember ? ProjectRole.VIEWER : assignUser.role,
          duration_in_seconds: calculateTimeDifferenceInSeconds(),
          event_source: eventSource,
        }
        trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_EDIT, analyticsData)
      }
      onClose()
    } catch (e) {
      showToast({ type: 'error', message: t('project.canvas.toast.failed_operation_edit', { query: 'application' }) })
      console.log(e)
    }
  })

  const selectedVersion = useMemo(
    () => application.externalAppVersions?.find(version => version.id === application.externalAppVersionId),
    [application],
  )

  const externalStatuses = useExternalStatusList()

  const statusOptions = useMemo(() => {
    return Object.entries(TaskStatus).map(([key, value]) => ({
      value: key,
      label: t(`project.tasks.status.${value}`),
    }))
  }, [t])

  const handleCancelEdit = () => {
    onClose()

    if (project) {
      const analyticsData = {
        ...getCommonProjectAnalyticsData(project, me),
        type: CanvasItemType.APPLICATION,
        duration_in_seconds: calculateTimeDifferenceInSeconds(),
        event_source: eventSource,
      }

      trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_EDIT_CANCELLED, analyticsData)
    }
  }

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={handleCancelEdit}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
        data-testid="edit-app-modal"
      >
        <WppTypography slot="header" type="2xl-heading" data-testid="app-settings-header-text">
          {t('modals.settings.app_settings')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <FormInput
            name="name"
            labelConfig={{ text: t('product.field_app_name') }}
            placeholder={t('product.field_app_name_placeholder')}
            required
            disabled={isDisabled}
            data-testid="app-modal-name"
          />
          <FormTextareaInput
            name="description"
            labelConfig={{ text: t('product.field_app_description') }}
            placeholder={t('product.field_app_description_placeholder')}
            warningThreshold={980}
            charactersLimit={1000}
            disabled={isDisabled}
            data-testid="modal-app-description"
          />

          {!isTemplate && (
            <>
              <SelectPerson disabled={isDisabled} projectId={projectId} isWrikeConnected={isWrikeConnected} />
              <Flex justify="between" gap={20} align="end">
                <FormDatepicker
                  className={styles.datePicker}
                  name="dueDate"
                  placeholder="Start date | End date"
                  labelConfig={{ text: t('product.field_due_date') }}
                  range
                  disabled={isDisabled}
                />
              </Flex>

              <FormSelect
                name="status"
                type="single"
                data-testid="status-select"
                options={useExternalStatuses ? externalStatuses! : statusOptions}
                labelConfig={{ text: t('product.status_label') }}
                required
                withSearch
              />
            </>
          )}

          {isTemplate && <SelectOsGroupForm data-testid="modal-app-group" initialValue={application?.group} />}
          <WppDivider />
          {selectedVersion && (
            <Flex direction="column" className={styles.versionWrapper}>
              <>
                <WppTypography type="s-midi" className={styles.greyColor800}>
                  {t('modals.app_details_modal.app_version_title')}
                </WppTypography>
                <WppTypography type="s-strong" className={styles.greyColor1000}>
                  {selectedVersion.name} {`(${selectedVersion.versionType.toLowerCase()})`}
                </WppTypography>
              </>
            </Flex>
          )}
        </Flex>

        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} disabled={isDisabled}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditAppModal } = createProjectModal<Props>(EditAppModal, 'edit-app-modal')
