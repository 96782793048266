// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qfPEP{display:flex;flex-direction:column;row-gap:2px}.x9dmR{margin-left:28px;color:var(--wpp-grey-color-800)}`, "",{"version":3,"sources":["webpack://./src/components/form/formCheckbox/FormCheckbox.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,WAAA,CAGF,OACE,gBAAA,CACA,+BAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  row-gap: 2px;\n}\n\n.hint {\n  margin-left: 28px;\n  color: var(--wpp-grey-color-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qfPEP`,
	"hint": `x9dmR`
};
export default ___CSS_LOADER_EXPORT___;
