import { WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/canvas/Canvas.module.scss'

export const CanvasActionsSkeleton = () => (
  <Flex direction="row" gap={24} className={styles.actionsContainer}>
    <WppSkeleton variant="rectangle" height="32px" width="300px" />
  </Flex>
)
