import { useState, useDeferredValue } from 'react'
import { useDebounce } from 'react-use'

export const useDebouncedValue = <T>(value: T, ms: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useDebounce(() => setDebouncedValue(value), ms, [value])

  return useDeferredValue(debouncedValue)
}
