// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tFoYM{margin-top:8px}.oOtvQ::part(pill-wrapper){padding:var(--pill-display-padding-icon-m)}`, "",{"version":3,"sources":["webpack://./src/components/common/autocomplete/Autocomplete.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CAIA,2BACE,0CAAA","sourcesContent":[".value {\n  margin-top: 8px;\n}\n\n.pill {\n  &::part(pill-wrapper) {\n    padding: var(--pill-display-padding-icon-m);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"value": `tFoYM`,
	"pill": `oOtvQ`
};
export default ___CSS_LOADER_EXPORT___;
