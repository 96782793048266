import { ProjectAttributeType } from 'types/projects/attributes'

export const typeLabels: Record<ProjectAttributeType, string> = {
  TEXT_AREA: 'project.settings.attributes_fields.text_area',
  TEXT_INPUT: 'project.settings.attributes_fields.text_input',
  SINGLE_SELECT: 'project.settings.attributes_fields.single_select',
  MULTI_SELECT: 'project.settings.attributes_fields.multi_select',
  DATE_RANGE: 'project.settings.attributes_fields.date_range',
  HIERARCHY: 'project.settings.attributes_fields.hierarchy',
}
