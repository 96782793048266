import { projectApi } from 'api'
import { ProjectAttribute } from 'types/projects/attributes'

export type CreateProjectAttributes = Omit<ProjectAttribute, 'id'>

interface Props {
  data: CreateProjectAttributes[]
}

export const addAttributesApi = (data: Props) => projectApi.post('/project/attributes', data)
