// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D7HlI{width:40px;height:40px;background:var(--wpp-grey-color-200);border-radius:100%}.tc8tf{color:var(--wpp-grey-color-700);text-transform:uppercase}.baVCT{width:100%;max-width:190px}._bph1{color:var(--wpp-success-color-500)}.h2E8J{color:var(--wpp-warning-color-500)}.mBZer{color:var(--wpp-grey-color-800)}.TsUeM{white-space:pre-line}.aGy_3{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/members/components/memberModal/MemberModal.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,oCAAA,CACA,kBAAA,CAGF,OACE,+BAAA,CACA,wBAAA,CAGF,OACE,UAAA,CACA,eAAA,CAGF,OACE,kCAAA,CAGF,OACE,kCAAA,CAGF,OACE,+BAAA,CAGF,OACE,oBAAA,CAGF,OACE,cAAA","sourcesContent":[".icon {\n  width: 40px;\n  height: 40px;\n  background: var(--wpp-grey-color-200);\n  border-radius: 100%;\n}\n\n.subTitle {\n  color: var(--wpp-grey-color-700);\n  text-transform: uppercase;\n}\n\n.userName {\n  width: 100%;\n  max-width: 190px;\n}\n\n.CONNECTED {\n  color: var(--wpp-success-color-500);\n}\n\n.NOT_CONNECTED {\n  color: var(--wpp-warning-color-500);\n}\n\n.INVITATION_PENDING {\n  color: var(--wpp-grey-color-800);\n}\n\n.tipPyContent {\n  white-space: pre-line;\n}\n\n.infoIcon {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `D7HlI`,
	"subTitle": `tc8tf`,
	"userName": `baVCT`,
	"CONNECTED": `_bph1`,
	"NOT_CONNECTED": `h2E8J`,
	"INVITATION_PENDING": `mBZer`,
	"tipPyContent": `TsUeM`,
	"infoIcon": `aGy_3`
};
export default ___CSS_LOADER_EXPORT___;
