import { HierarchyLevelType, HierarchyNode, NavigationTreeNode } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { getChildHierarchyLevels } from '@wpp-open/core/utils/hierarchy'
import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { FormSelectOption } from 'components/form/formSelect/FormSelect'
import { ProjectFilter } from 'types/projects/projects'
import { WITHOUT_HIERARCHY } from 'utils/common'

type PartialProjectFilter = Partial<Omit<ProjectFilter, 'includeEmptyWorkspace'>>

export const mapToOptions = (nodes: NavigationTreeNode[] = []): FormSelectOption[] => {
  const mapValues = nodes.reduce((previousValue: Record<string, string[]>, currentValue: NavigationTreeNode) => {
    const { name } = currentValue

    if (!name) return previousValue
    return { ...previousValue, [name]: [...(previousValue[name] || []), currentValue.azId] as string[] }
  }, {})

  return Object.keys(mapValues)
    .map(key => ({ value: mapValues[key].join(','), label: key }))
    .sort((a, b) => a.label?.localeCompare(b.label))
}

export const useHierarchyFilters = () => {
  const { osContext } = useOs()
  const { mapping } = osContext.navigationTree
  const { hierarchyLevels } = osContext.tenant

  const mappedTree = useMemo(
    () =>
      Object.keys(mapping).reduce((previousValue, currentValue) => {
        const element = mapping[currentValue]

        const type = (element.type === HierarchyCustomNodeType ? element.customTypeName : element.type) as string

        return { ...previousValue, [type]: [...(previousValue[type] || []), element] }
      }, {} as Record<HierarchyLevelType, NavigationTreeNode[]>),
    [mapping],
  )

  const options = useMemo(() => {
    return Object.entries(mappedTree).reduce((prev, [key, nodes]) => {
      return { ...prev, [key.toLowerCase()]: mapToOptions(nodes) }
    }, {} as Record<HierarchyLevelType, FormSelectOption[]>)
  }, [mappedTree])

  const getType = (node: HierarchyNode) => (node.type === HierarchyCustomNodeType ? node.customTypeName : node.type)!

  const allowNavigationTypes = useMemo(() => osContext.tenant.hierarchyLevels.map(lvl => lvl.type), [osContext])

  const getAllChildren = useCallback(
    (value: PartialProjectFilter, nodes: string[], isParentSelected = false) => {
      const isEmpty =
        getChildHierarchyLevels(osContext.tenant)
          .map(hierarchy => {
            const key = hierarchy.type.toLowerCase() as keyof PartialProjectFilter
            return value[key] as string[]
          }, [])
          .flat(Infinity).length === 0

      if (isEmpty) return []

      const selectedWithoutHierarchy = getChildHierarchyLevels(osContext.tenant).map(hierarchy => {
        const key = hierarchy.type.toLowerCase() as keyof PartialProjectFilter
        return (value[key] as string[])?.filter(el => el === WITHOUT_HIERARCHY)
      }, [])

      const selectedOtherHierarchy = getChildHierarchyLevels(osContext.tenant).map(hierarchy => {
        const key = hierarchy.type.toLowerCase() as keyof PartialProjectFilter
        return (value[key] as string[])?.filter(el => el !== WITHOUT_HIERARCHY)
      }, [])

      console.log({ selectedWithoutHierarchy, selectedOtherHierarchy })

      const isSelectOnlyOnFirstLevel =
        selectedWithoutHierarchy[0]?.length === 1 && selectedWithoutHierarchy.flat(Infinity).length === 1

      const isSelectedAnyHierarchyOptions = selectedOtherHierarchy.flat(Infinity).length > 0

      if (isSelectOnlyOnFirstLevel && !selectedOtherHierarchy) return []
      if (!isSelectedAnyHierarchyOptions) {
        const selectHierarchy = getChildHierarchyLevels(osContext.tenant).slice(
          0,
          getChildHierarchyLevels(osContext.tenant).findIndex(h =>
            value[h.type.toLowerCase() as keyof PartialProjectFilter]!.includes(WITHOUT_HIERARCHY),
          ),
        )

        return selectHierarchy.reduce<string[]>((acc, curr) => {
          const hierarchy = mappedTree[curr.type as any]
          const ids = hierarchy.map(el => el.azId!)!
          return acc.concat(ids)
        }, [])
      }

      return nodes.reduce((acc: string[], curNodeId: string): string[] => {
        const currentNode = mapping[curNodeId] as HierarchyNode
        const currentNodeChildren = currentNode?.children || []
        const currentNodeType = getType(currentNode)

        // We need only HierarchyLevelType projects
        if (!allowNavigationTypes.includes(currentNodeType)) return acc

        // Get selected nodes from form value
        const selectedNodes = ((value[currentNodeType.toLowerCase() as keyof PartialProjectFilter] as string[]) || [])
          .map(el => el.split(','))
          .flat(Infinity)
          .filter(el => el !== WITHOUT_HIERARCHY) as string[]

        const checkIfNodeHasSelectedWithoutChildrenOnNextLvl = (currentHierarchyIndex: number) => {
          const nextIdx = currentHierarchyIndex + 1
          if (nextIdx >= hierarchyLevels.length) return false

          const lvl = hierarchyLevels[nextIdx].type.toLowerCase()
          return (value as any)[lvl].filter((el: string) => el === WITHOUT_HIERARCHY).length > 0
        }

        const checkIfNodeHasSelectedChildren = (currentHierarchyIndex: number) => {
          const selectedOnNextLvl = []
          for (let index = currentHierarchyIndex + 1; index < hierarchyLevels.length; index++) {
            const lvl = hierarchyLevels[index].type
            const nextLvlValue = ((value[lvl.toLowerCase() as keyof PartialProjectFilter] as string[]) || []).filter(
              el => el !== WITHOUT_HIERARCHY,
            )

            selectedOnNextLvl.push(!!nextLvlValue?.length)
          }

          return selectedOnNextLvl.some(Boolean)
        }

        const currentHierarchyIndex = hierarchyLevels.findIndex(h => h.type === currentNodeType)
        const nodeHasSelectedChildren = checkIfNodeHasSelectedChildren(currentHierarchyIndex)
        const nodeHasSelectedWithoutChildren = checkIfNodeHasSelectedWithoutChildrenOnNextLvl(currentHierarchyIndex)

        if (!!selectedNodes.length) {
          const isThisNodeSelected = selectedNodes.includes(currentNode.azId)

          if (isThisNodeSelected) {
            if (!!currentNodeChildren.length) {
              return acc.concat(
                nodeHasSelectedChildren && !nodeHasSelectedWithoutChildren ? [] : currentNode.azId,
                getAllChildren(value, currentNodeChildren, true),
              )
            } else {
              if (currentHierarchyIndex === hierarchyLevels.length - 1) return acc.concat(currentNode.azId)
              return nodeHasSelectedChildren && !nodeHasSelectedWithoutChildren ? acc : acc.concat(currentNode.azId)
            }
          } else return acc
        } else {
          if (!!currentNodeChildren.length) {
            if (isParentSelected) {
              return acc.concat(
                nodeHasSelectedChildren ? [] : currentNode.azId,
                getAllChildren(value, currentNodeChildren, isParentSelected),
              )
            } else {
              return [
                acc,
                nodeHasSelectedWithoutChildren ? [currentNode.azId] : [],
                getAllChildren(value, currentNodeChildren, isParentSelected),
              ].flat(Infinity) as string[]
            }
          } else {
            if (currentHierarchyIndex >= hierarchyLevels.length - 1)
              return isParentSelected ? acc.concat(currentNode.azId) : acc
            return nodeHasSelectedChildren && !nodeHasSelectedWithoutChildren ? acc : acc.concat(currentNode.azId)
          }
        }
      }, [])
    },
    [allowNavigationTypes, hierarchyLevels, mappedTree, mapping, osContext.tenant],
  )

  return { options, getAllChildren }
}
