import { useOpenActivityPreviewModal } from 'pages/project/hooks/useOpenActivityPreviewModal'
import { useOpenAppPreviewModal } from 'pages/project/hooks/useOpenAppPreviewModal'
import { useOpenFilePreviewModal } from 'pages/project/hooks/useOpenFilesModal'
import { useOpenPhasePreviewModal } from 'pages/project/hooks/useOpenPhasePreviewModal'
import { useOpenTaskPreviewModal } from 'pages/project/hooks/useOpenTaskPreviewModal'
import { Project } from 'types/projects/projects'

export const useOpenPreviewModals = (project: Project) => {
  useOpenFilePreviewModal(project)
  useOpenTaskPreviewModal()
  useOpenAppPreviewModal()
  useOpenActivityPreviewModal()
  useOpenPhasePreviewModal(project)
}
