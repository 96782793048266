import { HierarchyLevelType, MayBeNull, NavigationTree, Tenant } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'

import { DefaultHierarchyLevelType } from 'types/core'
import { NativeAppVersionDTO } from 'types/products/nativeApp'
import { Project } from 'types/projects/projects'

// this is the same logic, as currently implemented on the Core side for hierarchy validation
// on projects with "dynamic" hierarchy can be run only apps w/o context requirements
export const isValidHierarchyLevel = (
  version?: MayBeNull<NativeAppVersionDTO>,
  currentWorkspaceLevel?: MayBeNull<HierarchyLevelType>,
): boolean => {
  const requiredHierarchy = (version?.dataContext || []).filter(
    (value: string) =>
      value === HierarchyCustomNodeType ||
      Object.values(DefaultHierarchyLevelType).includes(value as DefaultHierarchyLevelType),
  )

  return !requiredHierarchy.length || requiredHierarchy.includes(currentWorkspaceLevel!)
}

interface ContextParams {
  project: MayBeNull<Project>
  tenant: Tenant
  navigationTree: NavigationTree
}

/*
dataContext: ['BRAND']  Client -> Market -> Brand -- valid
dataContext: ['Market'] Client -> Market -> Brand -- invalid, Client -> Market -- valid
dataContext: ['TENANT'] without workspace -- valid, with any workspace -- invalid
dataContext: [] - any workspace lvl is valid
*/
export function validateAppContext(contextParams: ContextParams, actualVersion?: MayBeNull<NativeAppVersionDTO>) {
  const { project, navigationTree } = contextParams

  const currentWorkspaceLevel = !project?.contextWorkspace
    ? DefaultHierarchyLevelType.Tenant
    : (navigationTree.mapping[project?.contextWorkspace]?.type as HierarchyLevelType)

  const isValid = isValidHierarchyLevel(actualVersion, currentWorkspaceLevel)
  const missingLevels = isValid
    ? []
    : (actualVersion?.dataContext ?? []).filter(level => level !== currentWorkspaceLevel)

  return { isValid, missingLevels }
}
