import { FC, HTMLAttributes } from 'react'

import { ApplicationIconSvg } from 'components/svg/ApplicationIconSvg'

interface Props extends HTMLAttributes<HTMLElement> {
  logo?: string
  size?: number
}

export const ApplicationLogo: FC<Props> = ({ logo, size = 30, ...rest }) => {
  if (!logo?.length) {
    return (
      <div data-testid="app-logo" style={{ width: `${size}px`, height: `${size}px` }} {...rest}>
        <ApplicationIconSvg width={`${size}px`} height={`${size}px`} />
      </div>
    )
  }

  return <img src={logo} alt="Product logo" width={`${size}px`} height={`${size}px`} {...rest} />
}
