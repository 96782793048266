import { HierarchyContainerNode, HierarchyNode, MayBeNull } from '@wpp-open/core'

import { facadeApi } from 'api'

interface Params {
  tenantId: string
}

export const getTenantHierarchy = ({ tenantId }: Params) =>
  facadeApi.get<HierarchyTree>(`/v2/tenants/${tenantId}/navigation-tree`)

type HierarchyTreeNodeFlags = MayBeNull<{
  visible: boolean
  accessRequestable: boolean
}>

export interface HierarchyContainerNodeDetailed extends HierarchyContainerNode {
  mdId: null
  flags: HierarchyTreeNodeFlags
}

export interface HierarchyNodeDetailed extends HierarchyNode {
  mdId: string
  flags: HierarchyTreeNodeFlags
}

export type HierarchyTreeNode = HierarchyContainerNodeDetailed | HierarchyNodeDetailed

export type HierarchyTreeMapping = Record<string, HierarchyTreeNode>

export interface HierarchyTree {
  rootId: string
  mapping: HierarchyTreeMapping
}
