import { Task as GanttTask } from 'gantt-task-react'
import { useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/timeline/components/taskListTable/TaskListTable.module.scss'
import { TimelineTaskRow } from 'pages/project/components/timeline/components/taskListTable/TimelineTaskRow'
import { TimelineAssignees, TimelineContext } from 'pages/project/components/timeline/TimelineProvider'
import { DetailsModalType } from 'types/common/utils'
import { TimelinePhase, TimelineTask } from 'types/projects/timeline'

interface Props {
  rowHeight: number
  rowWidth: string
  fontFamily: string
  fontSize: string
  locale: string
  tasks: GanttTask[]
  selectedTaskId: string
  setSelectedTask: (taskId: string) => void
  onExpanderClick: (task: GanttTask) => void
}

export const TaskListTable = ({
  rowHeight,
  rowWidth,
  tasks: ganttTasks,
  fontFamily,
  fontSize,
  onExpanderClick,
}: Props) => {
  const { project, members } = useProject()
  const { allPhases, allTasks, allAssignees } = useContext(TimelineContext)
  const [, setSearchParams] = useSearchParams()

  const timelineTasks = useMemo(() => {
    const allTasksMap = Object.fromEntries(allTasks.map(task => [task.id, task]))
    const allPhasesMap = Object.fromEntries(allPhases.map(task => [task.id, task]))
    const allAssigneesMap = Object.fromEntries(allAssignees.map(task => [task.id, task]))

    return ganttTasks.reduce<Record<string, TimelineTask | TimelinePhase | TimelineAssignees>>((acc, task) => {
      const timelineTask = allTasksMap[task.id] || allPhasesMap[task.id] || allAssigneesMap[task.id]
      if (timelineTask) {
        acc[timelineTask.id] = timelineTask
      }
      return acc
    }, {})
  }, [allAssignees, allPhases, allTasks, ganttTasks])

  const handleTaskOpen = (task: GanttTask) => {
    if (task.type === 'project') {
      setSearchParams({ view: DetailsModalType.PHASE_DETAILS_PREVIEW, phaseId: task.id })
      return
    }

    setSearchParams({ view: DetailsModalType.TASK_DETAILS_PREVIEW, id: task.id })
  }

  return (
    <div
      className={styles.taskListWrapper}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {ganttTasks.map((task, index) => {
        const timelineTask = timelineTasks[task.id]
        if (!timelineTask) {
          return null
        }

        return (
          <TimelineTaskRow
            key={task.id}
            task={task}
            timelineTask={timelineTask}
            rowHeight={rowHeight}
            rowWidth={rowWidth}
            members={members}
            project={project}
            onExpanderClick={onExpanderClick}
            handleTaskOpen={handleTaskOpen}
            index={index}
          />
        )
      })}
    </div>
  )
}
