import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { RefCallback, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteNativeAppsApi } from 'api/applications/queries/useInfiniteNativeAppsApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { AppPickerSkeleton } from 'pages/project/components/canvas/components/appPickerSkeleton/AppPickerSkeleton'
import { NativeAppCard } from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeAppCard'
import styles from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeApps.module.scss'
import { SelectedApp } from 'pages/project/components/canvas/components/appPikerModal/types'
import { useIsMyMiroEnabled } from 'pages/project/hooks/useIsMyMiroEnabled'
import {
  NativeAppDTO,
  NativeAppsCategory,
  NativeAppsCommercialModel,
  NativeAppVersionDTO,
} from 'types/products/nativeApp'

interface Props {
  search: string
  filters: {
    categories: NativeAppsCategory[]
    commercialModel: NativeAppsCommercialModel
  }
  selectedApps: SelectedApp[]
  onSelectApp: (args: SelectedApp[]) => void
}

export const NativeApps = ({ search, filters, selectedApps, onSelectApp }: Props) => {
  const { t } = useTranslation()
  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  const selectedAppsMap = useMemo(() => {
    return Object.fromEntries(selectedApps.map(selectedApps => [selectedApps.id, selectedApps]))
  }, [selectedApps])

  const isMiroEnabled = useIsMyMiroEnabled()
  const {
    data: nativeApps,
    response,
    isLoading: isNativeAppsLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteNativeAppsApi({
    params: { search, ...filters },
  })

  const isFetching = isRefetching || isFetchingNextPage
  const resultsNumber = response?.pages?.[response.pages.length - 1].data?.paginator?.totalItems || 0

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const handleSelectVersions = useCallback(
    (app: NativeAppDTO, versions: NativeAppVersionDTO[]) => {
      const newApps = selectedApps.filter(selectedApp => selectedApp.id !== app.id)
      if (versions.length) {
        newApps.push({
          id: app.id,
          type: app.type,
          name: app.name,
          logo: app.logoUrl,
          versions,
        })
      }

      onSelectApp(newApps)
    },
    [onSelectApp, selectedApps],
  )

  return isNativeAppsLoading ? (
    <AppPickerSkeleton />
  ) : !resultsNumber ? (
    <EmptyState
      title={!!search ? t('common.no_search_results') : t('product.no_products.title')}
      filtersApplied={!!search}
      description={!!search ? t('common.no_results_description') : t('product.no_products.subtitle')}
      testToken="results"
    />
  ) : (
    <Flex className={styles.grid}>
      {nativeApps.map(app => (
        <NativeAppCard
          app={app}
          key={app.id}
          onSelectVersions={versions => handleSelectVersions(app, versions)}
          selectedVersions={selectedAppsMap[app.id]?.versions || []}
          isMiroEnabled={isMiroEnabled}
        />
      ))}
      <Flex justify="center" ref={setRef} className={styles.nextPageSpinner}>
        {isFetching && <WppSpinner size="m" />}
      </Flex>
    </Flex>
  )
}
