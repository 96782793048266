// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rm37s{--wpp-file-upload-item-max-width: 100%}`, "",{"version":3,"sources":["webpack://./src/components/form/formFileUpload/FormFileUpload.module.scss"],"names":[],"mappings":"AAAA,OACE,sCAAA","sourcesContent":[".root {\n  --wpp-file-upload-item-max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Rm37s`
};
export default ___CSS_LOADER_EXPORT___;
