// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AUpaD{margin:-5px -8px -5px auto;visibility:hidden}.sE3er{padding:5px 8px;border-radius:6px;cursor:pointer}.sE3er:hover,.sE3er:active{background:var(--wpp-grey-color-200)}.sE3er:hover .AUpaD{visibility:visible}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/projectDashboard/coponents/pinnedFilesCard/PinnedFilesCard.module.scss"],"names":[],"mappings":"AAAA,OACE,0BAAA,CAEA,iBAAA,CAGF,OACE,eAAA,CACA,iBAAA,CACA,cAAA,CAEA,2BAEE,oCAAA,CAIA,oBACE,kBAAA","sourcesContent":[".menuButton {\n  margin: -5px -8px -5px auto;\n  // see the .fileRow hover state\n  visibility: hidden;\n}\n\n.fileRow {\n  padding: 5px 8px;\n  border-radius: 6px;\n  cursor: pointer;\n\n  &:hover,\n  &:active {\n    background: var(--wpp-grey-color-200);\n  }\n\n  &:hover {\n    .menuButton {\n      visibility: visible;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": `AUpaD`,
	"fileRow": `sE3er`
};
export default ___CSS_LOADER_EXPORT___;
