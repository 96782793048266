export const isHTML = (str: string = '') => {
  const doc = new DOMParser().parseFromString(str, 'text/html')
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
}

export const parseHTMLString = (htmlString: string = '') => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html')
  const parserError = doc.querySelector('parsererror')

  //adding blank to all links to open in new tab
  Array.from(doc.body.querySelectorAll('a')).forEach(link => link.setAttribute('target', '_blank'))

  return { html: doc.body.innerHTML, error: !!parserError }
}
