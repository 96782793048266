// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kaB4o{display:flex;padding:6px;border-radius:6px;cursor:pointer}.kaB4o:focus-within,.kaB4o:hover{background:var(--wpp-grey-color-300)}.JUMSx{display:flex;padding:5px 8px 5px 6px}.bI9bC{display:inline-block;width:0;height:0}.bI9bC::part(icon-calendar),.bI9bC::part(icon-cross){display:none}.bI9bC::part(datepicker){margin-top:-24px}.bI9bC::part(datepicker-container),.bI9bC::part(datepicker-input){width:0;height:0;padding:0;border:none}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/selectDateInline/SelectDateInline.module.scss","webpack://./src/pages/project/components/canvas/utils.scss"],"names":[],"mappings":"AAEA,OACE,YAAA,CCFA,WAAA,CACA,iBAAA,CACA,cAAA,CAEA,iCAEE,oCAAA,CDCJ,OACE,YAAA,CACA,uBAAA,CAGF,OACE,oBAAA,CACA,OAAA,CACA,QAAA,CAEA,qDAEE,YAAA,CAIF,yBACE,gBAAA,CAGF,kEAEE,OAAA,CACA,QAAA,CACA,SAAA,CACA,WAAA","sourcesContent":["@import '~pages/project/components/canvas/utils';\n\n.dateEmptyLabel {\n  display: flex;\n\n  @include activeInlineButton();\n}\n\n.dateLabel {\n  display: flex;\n  padding: 5px 8px 5px 6px;\n}\n\n.datePickerInline {\n  display: inline-block;\n  width: 0;\n  height: 0;\n\n  &::part(icon-calendar),\n  &::part(icon-cross) {\n    display: none;\n  }\n\n  // CL hardcoded  `top` position of the calendar for some reason, this is compensation\n  &::part(datepicker) {\n    margin-top: -24px;\n  }\n\n  &::part(datepicker-container),\n  &::part(datepicker-input) {\n    width: 0;\n    height: 0;\n    padding: 0;\n    border: none;\n  }\n}\n","@mixin activeInlineButton() {\n  padding: 6px;\n  border-radius: 6px;\n  cursor: pointer;\n\n  &:focus-within,\n  &:hover {\n    background: var(--wpp-grey-color-300);\n  }\n}\n\n@mixin multilineEllipsis($lines: 2) {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: $lines;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateEmptyLabel": `kaB4o`,
	"dateLabel": `JUMSx`,
	"datePickerInline": `bI9bC`
};
export default ___CSS_LOADER_EXPORT___;
