// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KFF5g{width:100%;min-height:54px}.j5GyP{width:100%;height:100%;margin-bottom:16px}.MJvei{--ag-background-color: var(--wpp-grey-color-100);--ag-header-background-color: var(--wpp-grey-color-100);--ag-odd-row-background-color: var(--wpp-grey-color-100)}.m1VJb{--tag-color: var(--wpp-success-color-500)}.QpXSZ{--tag-color: var(--wpp-grey-color-600)}.cVjMh{overflow:hidden;text-overflow:ellipsis}.nSpMp{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/settings/attributes/components/attributesTable/AttributesTable.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,eAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,kBAAA,CAGF,OACE,gDAAA,CACA,uDAAA,CACA,wDAAA,CAGF,OACE,yCAAA,CAGF,OACE,sCAAA,CAGF,OACE,eAAA,CACA,sBAAA,CAGF,OACE,UAAA","sourcesContent":[".noData {\n  width: 100%;\n  min-height: 54px;\n}\n\n.tableWrapper {\n  width: 100%;\n  height: 100%;\n  margin-bottom: 16px;\n}\n\n.agGrid {\n  --ag-background-color: var(--wpp-grey-color-100);\n  --ag-header-background-color: var(--wpp-grey-color-100);\n  --ag-odd-row-background-color: var(--wpp-grey-color-100);\n}\n\n.enabledIcon {\n  --tag-color: var(--wpp-success-color-500);\n}\n\n.disabledIcon {\n  --tag-color: var(--wpp-grey-color-600);\n}\n\n.ellipsis {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.nameWrapper {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noData": `KFF5g`,
	"tableWrapper": `j5GyP`,
	"agGrid": `MJvei`,
	"enabledIcon": `m1VJb`,
	"disabledIcon": `QpXSZ`,
	"ellipsis": `cVjMh`,
	"nameWrapper": `nSpMp`
};
export default ___CSS_LOADER_EXPORT___;
