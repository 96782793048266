import { projectApi } from 'api'
import { ContextHierarchy, Project, ProjectStatus } from 'types/projects/projects'

export interface CreateProjectAttribute {
  id: string
  value: string
}

export interface CreateProjectDTO {
  projectAttributes: CreateProjectAttribute[]
  contextHierarchy?: ContextHierarchy[]
  status?: ProjectStatus
  templateId?: string
  wrikeProjectId?: string
}

export const createProjectApi = (body: CreateProjectDTO) => projectApi.post<Project>('/projects', body)
