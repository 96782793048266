import styles from 'components/svg/common.module.scss'

export const SvgWrikeLogoRound = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="40" height="40" rx="20" className={styles.fillGrey200} />
    <path
      d="M15.9874 19.911C16.8104 19.911 17.1984 20.062 17.7943 20.6573L20.992 23.8502C21.0867 23.9447 21.1057 23.9825 21.1244 24.0391C21.134 24.0581 21.134 24.0863 21.134 24.1053C21.134 24.1242 21.134 24.1525 21.1244 24.1715C21.1057 24.228 21.0867 24.2659 20.992 24.3604L18.8068 26.5519C18.712 26.6463 18.6743 26.6653 18.6173 26.6842C18.5986 26.6935 18.5702 26.6935 18.5512 26.6935C18.5322 26.6935 18.5039 26.6935 18.4849 26.6842C18.4281 26.6653 18.3904 26.6463 18.2957 26.5519L12.0989 20.3643C11.9193 20.1847 11.9854 19.911 12.2883 19.911H15.9874ZM25.0127 16.0093C24.1896 16.0093 23.8017 16.1604 23.2058 16.7557L20.0081 19.9486C19.9134 20.043 19.8944 20.0809 19.8757 20.1375C19.866 20.1564 19.866 20.1847 19.866 20.2037C19.866 20.2226 19.866 20.2509 19.8757 20.2698C19.8944 20.3264 19.9134 20.3643 20.0081 20.4588L22.1933 22.6409C22.288 22.7354 22.3258 22.7543 22.3828 22.7733C22.4015 22.7826 22.4298 22.7826 22.4488 22.7826C22.4679 22.7826 22.4962 22.7826 22.5152 22.7733C22.5719 22.7543 22.6097 22.7354 22.7044 22.6409L28.9009 16.4533C29.0807 16.2738 29.0147 16 28.7118 16H25.0127V16.0093Z"
      fill="#8B919A"
    />
  </svg>
)
