import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import {
  showTemplatePreview,
  useTemplatePreviewModal,
} from 'pages/components/templatePreviewModal/TemplatePreviewModal'

export const useOpenTemplateModal = () => {
  const { visible, hide } = useTemplatePreviewModal()

  const [params, setParams] = useSearchParams()
  useEffect(() => {
    const { view, id } = Object.fromEntries(params.entries()) as { view: string; id?: string }

    if (view && view === 'template') {
      showTemplatePreview({
        templateId: id,
        handleSubmit: () => {
          showCreateProjectModal({ templatePresetId: id })
        },
        onClose: setParams,
      })
    } else visible && hide()
  }, [hide, params, setParams, visible])
}
