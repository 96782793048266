import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteProjectFileApi } from 'api/projects/mutation/useDeleteProjectFileApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ACTION_ANALYTICS, CanvasItemType } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  fileId: string
  isInFluid?: boolean

  onDelete?: () => void
}

const FileDeleteModal = ({ fileId, isInFluid, isOpen, onClose, onCloseComplete, onDelete }: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const [isDeleting, setDeleting] = useState(false)
  const { me } = useHasProjectRole()
  const { trackAction } = useTrackAction()
  const projectContext = useProject()

  const { mutateAsync: deleteFile } = useDeleteProjectFileApi()

  const deleteFileAction = useCallback(async () => {
    setDeleting(true)
    try {
      await deleteFile({ fileId })
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES_FETCHER])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES])
      invalidateCanvas()
      onClose()
      onDelete?.()

      showToast({ type: 'success', message: t('project.files.toast_success_message')! })

      if (projectContext) {
        const { project } = projectContext
        const analyticsData = {
          ...getCommonProjectAnalyticsData(project, me),
          type: CanvasItemType.FILE,
        }

        trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DELETE, analyticsData)
      }
    } catch (e) {
      showToast({ type: 'error', message: t('project.files.toast_error_delete_message')! })
    } finally {
      setDeleting(false)
    }
  }, [deleteFile, fileId, me, onClose, onDelete, projectContext, showToast, t, trackAction])

  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete}>
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center" data-testid="delete-group-modal-title">
          {t('project.files.remove_container.delete_file')}
        </Flex>
      </WppTypography>
      <WppTypography slot="body" type="s-body" data-testid="delete-group-modal-text">
        <WppTypography>
          {t(
            isInFluid
              ? 'project.files.remove_container.delete_fluid_confirm'
              : 'project.files.remove_container.delete_confirm',
          )}
        </WppTypography>
      </WppTypography>
      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} size="s" variant="secondary">
          {t('common.btn_cancel')}
        </WppButton>
        <WppButton loading={isDeleting} onClick={deleteFileAction} size="s" variant="destructive">
          {t('common.btn_delete')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showFileDeleteModal } = createProjectModal<Props>(FileDeleteModal, 'delete-file-modal')
