import { WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/common/sideModalSkeleton/SideModalSkeleton.module.scss'

export const SideModalBodySkeleton = () => (
  <Flex slot="body" direction="column" gap={12} className={styles.bodyContainer}>
    <Flex className={styles.sideModalLoaderWrapper}>
      <WppSkeleton variant="rectangle" height="100%" width="100%" />
    </Flex>
  </Flex>
)
