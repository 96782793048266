import { useOs } from '@wpp-open/react'
import { isEqual } from 'lodash'
import { useCallback, useMemo } from 'react'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { ContextHierarchy, ProcessType, Project } from 'types/projects/projects'

interface UseProjectAnalyticsProps {
  project: Project
  eventSource: string | undefined
  calculateTimeDifferenceInSeconds: () => number
}

export const useProjectAnalytics = ({
  project,
  eventSource,
  calculateTimeDifferenceInSeconds,
}: UseProjectAnalyticsProps) => {
  const { trackAction } = useTrackAction()
  const {
    osContext: { userDetails },
  } = useOs()

  const getMeFromProjectMembers = useMemo(
    () => project.members?.find(({ email }) => email === userDetails?.email),
    [project.members, userDetails?.email],
  )

  const buildAnalyticsData = useCallback(
    (overrides?: Partial<Project>) => ({
      ...getCommonProjectAnalyticsData(project),
      user_agency: getMeFromProjectMembers?.agency,
      user_role: getMeFromProjectMembers?.role,
      duration_in_seconds: calculateTimeDifferenceInSeconds(),
      event_source: eventSource,
      process_type: overrides?.processType || project.processType, // Include new processType if provided
    }),
    [project, getMeFromProjectMembers, calculateTimeDifferenceInSeconds, eventSource],
  )

  const trackIfChanged = useCallback(
    (action: ACTION_ANALYTICS, condition: boolean, overrides?: Partial<Project>) => {
      if (condition) {
        trackAction(action, buildAnalyticsData(overrides))
      }
    },
    [trackAction, buildAnalyticsData],
  )

  const trackHierarchyChange = useCallback(
    (newContextHierarchy: ContextHierarchy[]) => {
      const hierarchyChanged = !isEqual(project.contextHierarchy, newContextHierarchy)
      trackIfChanged(ACTION_ANALYTICS.ACTION_PROJECT_HIERARCHY_CHANGED, hierarchyChanged)
    },
    [project.contextHierarchy, trackIfChanged],
  )

  const trackProcessTypeChange = useCallback(
    (newProcessType: ProcessType) => {
      const processTypeChanged = project.processType !== newProcessType

      trackIfChanged(
        ACTION_ANALYTICS.ACTION_PROJECT_PROCESS_TYPE_CHANGED,
        processTypeChanged,
        { processType: newProcessType }, // Pass the new process type as an override
      )
    },
    [project.processType, trackIfChanged],
  )

  const trackWrikeAction = useCallback(
    (disconnectWrike: boolean) => {
      const action = disconnectWrike
        ? ACTION_ANALYTICS.ACTION_PROJECT_WRIKE_DISCONNECTED
        : ACTION_ANALYTICS.ACTION_PROJECT_WRIKE_CONNECTED
      trackAction(action, buildAnalyticsData())
    },
    [trackAction, buildAnalyticsData],
  )

  const trackProjectEdit = useCallback(
    (overrides?: Partial<Project>) => {
      trackAction(ACTION_ANALYTICS.ACTION_PROJECT_EDIT, buildAnalyticsData(overrides))
    },
    [trackAction, buildAnalyticsData],
  )

  const trackProjectEditCancel = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_PROJECT_EDIT_CANCELLED, buildAnalyticsData())
  }, [trackAction, buildAnalyticsData])

  return {
    trackHierarchyChange,
    trackProcessTypeChange,
    trackWrikeAction,
    trackProjectEdit,
    trackProjectEditCancel,
  }
}
