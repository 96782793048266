import { SideModalHeaderSkeleton } from 'components/common/sideModalSkeleton/SideModaHeaderSkeleton'
import { SideModalBodySkeleton } from 'components/common/sideModalSkeleton/SideModalBodySkeleton'
import { SideModalFooterSkeleton } from 'components/common/sideModalSkeleton/SideModalFooterSkeleton'

export const SideModalContentSkeleton = () => {
  return (
    <>
      <SideModalHeaderSkeleton />
      <SideModalBodySkeleton />
      <SideModalFooterSkeleton />
    </>
  )
}
