import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { TemplateDetail } from 'api/templates/fetchers/fetchWorkflowTemplateByIdApi'
import {
  showFileDetailsModal,
  useShowFileDetailsModal,
} from 'pages/project/components/files/components/fileDetailsModal/FileDetailsModal'
import { DetailsModalType } from 'types/common/utils'
import { ProcessType } from 'types/projects/projects'

export const useOpenTemplateFilePreviewModal = (template: TemplateDetail) => {
  const { visible, hide } = useShowFileDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, id } = Object.fromEntries(params.entries()) as { view: string; id: string }

    if (view && view === DetailsModalType.FILE_DETAILS_PREVIEW) {
      showFileDetailsModal({
        fileId: id,
        isInFluid: template.processType === ProcessType.FLUID,
        onClose: setParams,
      })
    } else visible && hide()
  }, [hide, params, template.processType, setParams, visible])
}
