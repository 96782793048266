import { useEffect, useState } from 'react'

export const useReadWriteStorageFlag = (flagKey: string, persist: boolean = true) => {
  const [isToggled, setIsToggled] = useState(false)

  useEffect(() => {
    if (!persist) return

    const storedValue = JSON.parse(localStorage.getItem(`${flagKey}`)!)
    if (storedValue !== null) setIsToggled(storedValue)
  }, [flagKey, persist])

  const toggleState = () => {
    const newValue = !isToggled
    setIsToggled(newValue)

    persist && localStorage.setItem(`${flagKey}`, JSON.stringify(newValue))
  }

  return { state: isToggled, toggleState }
}
