import { createUseQuery } from 'api/common/createUseQuery'
import { fetchProjectTimelineApi } from 'api/projects/fetchers/fetchProjectTimelineApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { isFluidTimeline, LinearTimeline, TimelineTask } from 'types/projects/timeline'

export const useProjectTimelineApi = createUseQuery({
  queryKey: ApiQueryKeys.PROJECT_TIMELINE,
  fetcher: fetchProjectTimelineApi,
  selector: res => {
    const timeline = res?.data
    if (timeline) {
      // as task's subtask at the moment is not a REAL subtask, but the app-in-activity representation - we will return
      // them as a flat tasks list, with updated order
      if (isFluidTimeline(timeline)) {
        return { ...timeline, tasks: flattenSubtasks(timeline.tasks) }
      }

      return {
        ...timeline,
        phases: timeline.phases.map(phase => ({
          ...phase,
          tasks: flattenSubtasks(phase.tasks ?? []),
        })),
      } as LinearTimeline
    }

    return null
  },
})

function flattenSubtasks(tasks: TimelineTask[]) {
  return tasks
    .reduce<TimelineTask[]>((acc, task) => [...acc, task, ...(task.subTasks || [])], [])
    .sort((a, b) => {
      // First compare by startDate presence
      if (a.startDate && !b.startDate) return -1
      if (!a.startDate && b.startDate) return 1

      // If both have startDate, sort by startDate
      if (a.startDate && b.startDate) {
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      }

      // If both do not have startDate, sort by created_at
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })
}
