import {
  WppActionButton,
  WppCard,
  WppDivider,
  WppIconArrow,
  WppIconMore,
  WppIconRemoveCircle,
  WppListItem,
  WppMenuContext,
  WppSkeleton,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useProjectFilesApi } from 'api/projects/queries/useProjectFilesApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import styles from 'pages/project/components/projectDashboard/coponents/pinnedFilesCard/PinnedFilesCard.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { useReorderPinFiles } from 'pages/project/hooks/useReorderPinFiles'
import { useTogglePinFile } from 'pages/project/hooks/useTogglePinFile'
import { DetailsModalType } from 'types/common/utils'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { getFileIcon } from 'utils/getFileIcon'

const Skeleton = () => <WppSkeleton variant="rectangle" height="100%" width="100%" />

export const PinnedFilesCard = () => {
  const { t } = useTranslation()
  const { project } = useProject()
  const [, setParams] = useSearchParams()
  const { isPermitted } = useIsPermitted()
  const { hasRole } = useHasProjectRole()
  const { togglePinFile } = useTogglePinFile()
  const { move } = useReorderPinFiles({ projectId: project.id })

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const isOwner = hasRole([ProjectRole.OWNER]) || isGlobalManage

  const { data, isLoading } = useProjectFilesApi({ params: { projectId: project.id, pinned: true } })

  const openPreview = (fileId: string) => setParams({ view: DetailsModalType.FILE_DETAILS_PREVIEW, id: fileId })

  if (isLoading) return <Skeleton />
  if (!data.length) return null

  return (
    <WppCard variant="primary" size="l" data-testid="pinned-files-card">
      <WppTypography slot="header" type="l-strong">
        {t('overview.pin.card_title')}
      </WppTypography>
      <Flex direction="column" gap={4}>
        <ShowMoreItems maxToShow={7}>
          {data.map((file, idx) => {
            const Icon = getFileIcon(file.name)

            return (
              <Flex
                key={file.id}
                className={styles.fileRow}
                gap={8}
                onClick={() => openPreview(file.id)}
                data-testid="pinned-file-row"
              >
                <Flex gap={8} align="center">
                  {Icon}
                  <Truncate lines={1} type="s-body">
                    {file.name}
                  </Truncate>
                </Flex>

                {isOwner && (
                  <Flex align="center" className={styles.menuButton}>
                    <WppMenuContext
                      dropdownConfig={{ appendTo: () => document.body }}
                      onClick={e => e.stopPropagation()}
                    >
                      <WppActionButton slot="trigger-element" variant="secondary">
                        <WppIconMore slot="icon-start" direction="horizontal" />
                      </WppActionButton>

                      <Flex direction="column" gap={4}>
                        <WppListItem
                          onWppChangeListItem={() => move(file.id, idx, 'up')}
                          disabled={!idx}
                          data-testid="context-move-pinned-file-up"
                        >
                          <WppIconArrow slot="left" direction="top" />
                          <span slot="label">{t('project.pinned_files_card.move_up')}</span>
                        </WppListItem>
                        <WppListItem
                          onWppChangeListItem={() => move(file.id, idx, 'down')}
                          disabled={idx === data.length - 1}
                          data-testid="context-move-pinned-file-down"
                        >
                          <WppIconArrow slot="left" direction="down" />
                          <span slot="label">{t('project.pinned_files_card.move_down')}</span>
                        </WppListItem>
                        <WppDivider />
                        <WppListItem
                          data-testid="context-unpin-file"
                          onWppChangeListItem={() =>
                            showDeleteModal({
                              title: t('project.pinned_files_card.remove_modal_title'),
                              subTitle: (
                                <Trans
                                  i18nKey="project.pinned_files_card.remove_modal_text"
                                  values={{ name: file.name }}
                                  components={{ bold: <WppTypography type="m-strong" /> }}
                                />
                              ),
                              deleteText: t('project.pinned_files_card.unpin'),
                              onDelete: () => togglePinFile(file),
                            })
                          }
                        >
                          <WppIconRemoveCircle slot="left" />
                          <span slot="label">{t('project.pinned_files_card.unpin')}</span>
                        </WppListItem>
                      </Flex>
                    </WppMenuContext>
                  </Flex>
                )}
              </Flex>
            )
          })}
        </ShowMoreItems>
      </Flex>
    </WppCard>
  )
}
