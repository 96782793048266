// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JhrjC{position:relative;display:flex;flex-direction:column;justify-content:center;height:100%;margin-left:72px}.HpFPP{position:absolute;top:32px;right:32px}.zOh0e{margin-top:24px}.oH9uT{gap:68px;margin-top:46px}.Gkh1V{max-width:600px;margin-top:32px}.ptync{color:var(--wpp-grey-color-800)}.bQS46{margin-left:49px}.EzMxu{--wpp-typography-color: var(--wpp-grey-color-700)}.uFlqH{display:grid;grid-template-columns:1fr 1fr 1fr;gap:12px 24px;row-gap:12px;margin-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/pages/projectDemo/components/projectInfo/ProjectInfo.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,WAAA,CACA,gBAAA,CAGF,OACE,iBAAA,CACA,QAAA,CACA,UAAA,CAGF,OACE,eAAA,CAGF,OACE,QAAA,CACA,eAAA,CAGF,OACE,eAAA,CACA,eAAA,CAGF,OACE,+BAAA,CAGF,OACE,gBAAA,CAGF,OACE,iDAAA,CAGF,OACE,YAAA,CACA,iCAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".projectContainer {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n  margin-left: 72px;\n}\n\n.closeIcon {\n  position: absolute;\n  top: 32px;\n  right: 32px;\n}\n\n.rowOne {\n  margin-top: 24px;\n}\n\n.rowTwo {\n  gap: 68px;\n  margin-top: 46px;\n}\n\n.rowThree {\n  max-width: 600px;\n  margin-top: 32px;\n}\n\n.label {\n  color: var(--wpp-grey-color-800);\n}\n\n.creationDate {\n  margin-left: 49px;\n}\n\n.subTitle {\n  --wpp-typography-color: var(--wpp-grey-color-700);\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 12px 24px;\n  row-gap: 12px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectContainer": `JhrjC`,
	"closeIcon": `HpFPP`,
	"rowOne": `zOh0e`,
	"rowTwo": `oH9uT`,
	"rowThree": `Gkh1V`,
	"label": `ptync`,
	"creationDate": `bQS46`,
	"subTitle": `EzMxu`,
	"grid": `uFlqH`
};
export default ___CSS_LOADER_EXPORT___;
