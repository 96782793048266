// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--modal-padding-top-botton: 120px;--modal-header: 68px;--modal-footer: 80px;--modal-top-content: 100px;--calc-value: var(--modal-padding-top-botton) - var(--modal-header) - var(--modal-footer) - var(--modal-top-content)}.xZaFa{flex-shrink:0}.vjmnV::part(content){--modal-vertical-position-minus-number: 0px}.i52N4{max-height:calc(100vh - var(--calc-value));overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/appCtxWarningModal/components/AppCtxWarningModal.module.scss"],"names":[],"mappings":"AAAA,MACE,iCAAA,CACA,oBAAA,CACA,oBAAA,CACA,0BAAA,CACA,oHAAA,CAGF,OACE,aAAA,CAIA,sBACE,2CAAA,CAIJ,OACE,0CAAA,CACA,eAAA","sourcesContent":[":root {\n  --modal-padding-top-botton: 120px;\n  --modal-header: 68px;\n  --modal-footer: 80px;\n  --modal-top-content: 100px;\n  --calc-value: var(--modal-padding-top-botton) - var(--modal-header) - var(--modal-footer) - var(--modal-top-content);\n}\n\n.appIcon {\n  flex-shrink: 0;\n}\n\n.modalWrapper {\n  &::part(content) {\n    --modal-vertical-position-minus-number: 0px;\n  }\n}\n\n.appScrollContainer {\n  max-height: calc(100vh - var(--calc-value));\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appIcon": `xZaFa`,
	"modalWrapper": `vjmnV`,
	"appScrollContainer": `i52N4`
};
export default ___CSS_LOADER_EXPORT___;
