import {
  WppActionButton,
  WppButton,
  WppIconRemoveCircle,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import styles from 'pages/project/components/members/components/confirmIExternalModal/ConfirmExternalModal.module.scss'
import { ProjectMember } from 'types/projects/projectMembers'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  members: (ProjectMember & { label?: string })[]
  inviteMembers: (ids: string[]) => void

  title?: string
  description?: string
  withDelete?: boolean
  isMember?: boolean
  assign?: boolean
}

const ConfirmExternalModal = ({
  isOpen,
  onClose,
  onCloseComplete,
  members = [],
  inviteMembers,
  title,
  description,
  withDelete = true,
  isMember,
  assign = true,
}: Props) => {
  const { t } = useTranslation()
  const [list, setList] = useState(members)

  return (
    <Modal
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      data-testid="assign-member-modal"
    >
      <Flex gap={8} slot="header" align="start" data-testid="assign-member-modal-title">
        <WppTypography slot="header" type="xl-heading">
          {title || t('modals.invite_member.invite_external_title')}
        </WppTypography>
      </Flex>
      <Flex slot="body" direction="column" gap={12} className={styles.container}>
        <WppTypography data-testid="assign-member-modal-message">
          <Trans
            i18nKey={
              list?.length
                ? description || t('modals.invite_member.invite_external_description')
                : t('modals.invite_member.invite_external_description_empty')
            }
            components={{ bold: <WppTypography type="m-strong" /> }}
          />
        </WppTypography>

        <Flex gap={4} direction="column">
          <ShowMoreItems maxToShow={8}>
            {list!.map(user => (
              <Flex justify="between" align="center" key={user.id} style={{ width: '100%' }}>
                <Flex gap={8}>
                  <Avatar size="xs" name={user.label || user.email} src="" style={{ marginRight: '6px' }} />
                  <WppTypography type="s-body">{user.label}</WppTypography>
                </Flex>
                {withDelete && (
                  <WppActionButton
                    variant="secondary"
                    onClick={() => setList(items => items.filter(item => item.id !== user.id))}
                  >
                    <WppIconRemoveCircle slot="icon-end" />
                  </WppActionButton>
                )}
              </Flex>
            ))}
          </ShowMoreItems>
        </Flex>
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} size="s" variant="secondary">
          {t('common.btn_cancel')}
        </WppButton>

        {!isMember ? (
          <WppButton
            size="s"
            variant="primary"
            onClick={() => {
              inviteMembers(list.map(user => user.id))
              onClose()
            }}
          >
            {list?.length
              ? t(assign ? 'modals.invite_member.btn_invite_and_assign' : 'modals.invite_member.btn_invite_one')
              : t('modals.invite_member.btn_continue')}
          </WppButton>
        ) : (
          <WppButton
            size="s"
            variant="primary"
            onClick={() => {
              inviteMembers(list.map(user => user.id))
              onClose()
            }}
          >
            {t('modals.invite_member.btn_assign')}
          </WppButton>
        )}
      </Flex>
    </Modal>
  )
}

export const { showModal: showConfirmExternalModal } = createNiceModal<Props>(
  ConfirmExternalModal,
  'confirm-external-members-modal',
)
