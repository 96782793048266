export interface ProjectMember {
  id: string
  email: string
  firstname: string
  lastname: string
  jobTitle: string
  agency: string
  isExternal?: boolean
}

export enum ProjectMemberType {
  CONTRIBUTOR = 'CONTRIBUTOR',
}

export interface ExternalMember {
  email: string
  firstName: string
  lastName: string
  agency: string
}
