import { WppCheckbox, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import styles from 'components/form/formCheckbox/FormCheckbox.module.scss'
import { useCommonLabelProps } from 'components/form/utils'
import { useField } from 'hooks/form/useField'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppCheckbox>, 'checked' | 'onChange' | 'onWppChange'> {
  name: string
  hint?: string
}

export const FormCheckbox = forwardRef<HTMLWppCheckboxElement, Props>(
  ({ name, hint, onWppBlur, labelConfig, labelTooltipConfig, ...rest }, ref) => {
    const {
      field: { ref: fieldRef, value, onChange, onBlur },
    } = useField({ name })

    const innerRef = useRef<HTMLWppCheckboxElement>(null)

    useImperativeHandle(
      fieldRef,
      () => ({
        focus: () => innerRef.current?.setFocus(),
      }),
      [],
    )

    const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

    return (
      <div className={styles.root}>
        <WppCheckbox
          {...rest}
          {...labelProps}
          ref={mergeRefs([innerRef, ref])}
          name={name}
          checked={value}
          onWppChange={event => onChange(event.detail.checked)}
          onWppBlur={e => {
            onBlur()
            onWppBlur?.(e)
          }}
        />
        {!!hint && (
          <WppTypography className={styles.hint} type="xs-body">
            {hint}
          </WppTypography>
        )}
      </div>
    )
  },
)
