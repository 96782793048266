import { arc, pie, scaleOrdinal, select } from 'd3'
import { useLayoutEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ArcValue, PieData } from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/utils'

export interface ChartEntry {
  value: number
  order: number
  color?: string
}

interface Props<T extends string> {
  height: number
  width: number
  chartData: Record<T, ChartEntry>
  progressBy: T
}

const getColorScaleProgress = (chartData: Record<string, ChartEntry>) => {
  const statuses = Object.keys(chartData)
  const colors = statuses.map(status => chartData[status].color || 'var(--wpp-grey-color-400)')

  const colorScaleProgress = scaleOrdinal().domain(statuses).range(colors)

  return colorScaleProgress
}

export const PieChartProgress = <T extends string>({ height, width, chartData, progressBy }: Props<T>) => {
  const { t } = useTranslation()
  const ref = useRef<SVGSVGElement | null>(null)

  const data: PieData = useMemo(() => {
    const result = (Object.entries(chartData) as [string, ChartEntry][]).reduce(
      (acc, [key, entry]) => {
        const entryData: ArcValue = {
          type: key,
          value: entry.value,
          order: entry.order,
        }

        acc.values.push(entryData)

        acc.totalCount += entry.value

        return acc
      },
      { totalCount: 0, values: [] as ArcValue[] },
    )

    return {
      name: t(`common.progress_chart_statuses.${String(progressBy)}`),
      totalCount: result.totalCount,
      values: result.values,
    }
  }, [chartData, progressBy, t])

  const colorScale = getColorScaleProgress(chartData)

  useLayoutEffect(() => {
    if (!ref.current) return
    const svg = select(ref.current)

    const radius = height / 2
    const padding = 18 // animation padding, calcTranslate(v, 6) where v = Pi, so 3.14*6= ~18

    const g = svg
      .append('g')
      .datum(data)
      .style('font-family', 'sans-serif')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)

    const pieChart = pie<ArcValue>()
      .startAngle(0)
      .endAngle(3 * Math.PI)
      .value(d => d.value)
      // @ts-ignore
      .sort((a, b) => a.order - b.order)

    const text = g.append('g').attr('text-anchor', 'middle').style('-webkit-perspective', 1000)

    text
      .append('text')
      .attr('class', 'pie-name')
      .attr('data-testid', 'pie-name')
      .text(d => d.name)
      .attr('dy', '-12px')
      .attr('font-size', '14px')
      .attr('font-family', 'Inter')

    text
      .append('text')
      .attr('class', 'pie-count')
      .attr('data-testid', 'pie-count')
      .attr('dy', '20px')
      .text(d => `${d.values.find(type => type.type === progressBy)!.value} / ${d.totalCount}`)
      .attr('font-size', '24px')
      .attr('font-family', 'Inter')
      .attr('font-weight', '600')

    const arcs = pieChart(data.values)

    const arcG = g
      .selectAll('.arc')
      .data(arcs)
      .enter()
      .append('g')
      .attr('class', d => `arc arc_${d.data.type.toLowerCase()}`)
      .attr('data-testid', d => `arc_${d.data.type.toLowerCase()}`)
      .style('cursor', 'pointer')
      .style('opacity', 0.8)

    const arcPath = arc<ArcValue>()
      .outerRadius(radius - padding)
      .innerRadius((radius - padding) / 1.5)

    arcG
      .append('path')
      // arcPath build svg path based on datum, due to TS types mismatch better to cast it as string
      .attr('d', arcPath as unknown as string)
      .attr('stroke', 'white')
      .attr('fill', d => colorScale(d.data.type) as string)

    return () => {
      svg.selectAll('*').remove()
    }
  }, [height, width, data, chartData, t, progressBy, colorScale])

  return (
    <Flex justify="center" direction="column" align="center">
      <svg id="chart" ref={ref} viewBox={`0 0 ${width} ${height}`} width={width} height={height} />
    </Flex>
  )
}
