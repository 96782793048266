import { projectApi } from 'api'
import { ProjectRole } from 'types/permissions/permissions'
import { ProjectMember } from 'types/projects/projectMembers'

interface UpdateProjectMember {
  memberId: string
  role: ProjectRole
}

export const updateProjectMemberApi = ({ memberId, role }: UpdateProjectMember) =>
  projectApi.patch<ProjectMember[]>(`/members/${memberId}`, { role })
