import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchProjectsListApi } from 'api/projects/fetchers/fetchProjectsListApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useProjectInfiniteApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.PROJECTS_INFINITE,
  fetcher: fetchProjectsListApi,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: lastPage => {
    return lastPage.data.paginator.totalPages === lastPage.data.paginator.page
      ? undefined
      : lastPage.data.paginator.page + 1
  },
})
