import { WppInlineMessage } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'

export const WrikeIntegrationsMessage = () => {
  const { t } = useTranslation()

  return (
    <Flex>
      <WppInlineMessage size="s" message={t('modals.create_project.integrations_message')!} type="information" />
    </Flex>
  )
}
