import {
  WppActionButton,
  WppButton,
  WppIconReset,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { HierarchyContainerNodeId } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { useHierarchy } from 'hooks/useHierarchy'
import { MultiSelectAttribute } from 'pages/components/projectModal/components/attributes/MultiSelectAttribute'
import { SingleSelectAttribute } from 'pages/components/projectModal/components/attributes/SingleSelectAttribute'
import { HierarchyFilterControl } from 'pages/dashboard/components/hierarchyFilterControl/HierarchyFilterControl'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import { initialProjectFilters } from 'pages/dashboard/Dashboard'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { useHierarchyFilters } from 'pages/dashboard/utils/hierarchyFilters'
import { ProjectAttribute, ProjectAttributeType } from 'types/projects/attributes'
import { ProjectFilter, ProjectOwnership, ProjectStatus, ProjectType } from 'types/projects/projects'
import { WITHOUT_HIERARCHY } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  filter: ProjectFilter
  attributes?: ProjectAttribute[]

  handleCloseModal(filter?: ProjectFilter): void
}

const projectTypes = [
  { value: ProjectType.CAMPAIGN, label: 'Campaign' },
  { value: ProjectType.WORKSHOP, label: 'Workshop' },
  { value: ProjectType.PITCH, label: 'Pitch' },
  { value: ProjectType.FINANCE, label: 'Finance' },
  { value: ProjectType.MISCELLANEOUS, label: 'Miscellaneous' },
]

const ProjectFilterModal: FC<Props> = ({
  isOpen,
  onClose,
  onCloseComplete,
  handleCloseModal,
  filter,
  attributes = [],
}) => {
  const { t } = useTranslation()
  const { osContext } = useOs()
  const isAgencyWorkspace = osContext.tenant.tenantType === TenantType.Agency

  const [, setParam] = useSearchParams()

  const { mapping } = osContext.navigationTree
  const { hierarchyOrder } = useHierarchy()

  const { getAllChildren } = useHierarchyFilters()

  const form = useForm({ defaultValues: filter })

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    getValues,
  } = form

  const statusOptions = [
    { value: ProjectStatus.TO_DO, label: t('project.status.to_do') },
    { value: ProjectStatus.IN_PROGRESS, label: t('project.status.in_progress') },
    { value: ProjectStatus.ARCHIVED, label: t('project.status.archived') },
    { value: ProjectStatus.COMPLETED, label: t('project.status.completed') },
  ]

  const onSubmit = handleSubmit(value => {
    const { children } = mapping[HierarchyContainerNodeId]

    const isWorkspaceEmpty = Object.entries(value)
      .filter(([key]) => hierarchyOrder.includes(key))
      .some(([, value]) => !!value?.length)

    setParam()
    if (!isWorkspaceEmpty) {
      handleCloseModal({ ...value, workspace: undefined, includeEmptyWorkspace: false })
      onClose()
      return
    }

    const { includeEmptyWorkspace: skip, ...rest } = value
    const includeEmptyWorkspace = Object.values(value).some(val =>
      Array.isArray(val) ? val.includes(WITHOUT_HIERARCHY) : false,
    )

    handleCloseModal({ ...value, workspace: getAllChildren(rest, children), includeEmptyWorkspace })
    onClose()
  })

  const watchAllFields = watch()

  const isStatusDirty =
    watchAllFields.status?.length !== initialProjectFilters.status.length ||
    !watchAllFields.status?.every(value => initialProjectFilters.status.includes(value))

  const isOwnershipDirty = getValues('ownership') !== ProjectOwnership.ALL

  const onReset = () => {
    reset({
      ...initialProjectFilters,
      ...hierarchyOrder.reduce(
        (pre, curr) => ({
          ...pre,
          [curr]: [],
        }),
        {},
      ),
      attributes: attributes?.reduce((acc, curr) => ({ ...acc, [curr.contractName]: [] }), {}),

      // do not reset dashboards filters
      search: filter.search,
      tenant: filter.tenant,
    })
  }

  const appliedFilters = (filter: ProjectFilter) => {
    const { status, ownership, tenant, attributes, ...rest } = filter

    return getAppliedFilters({ ...rest, ...attributes })
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          // formConfig={{ onSubmit }}
          onWppSideModalClose={() => {
            handleCloseModal()
            onClose()
          }}
          onWppSideModalCloseComplete={onCloseComplete}
          size="s"
          data-testid="create-project-modal"
        >
          <WppTypography slot="header" type="2xl-heading" data-testid="filters-header">
            {t('modals.dashboard_filters.title')!}
          </WppTypography>
          <Flex slot="body" direction="column" gap={24}>
            <WppSegmentedControl
              size="s"
              onWppChange={event => setValue('ownership', event.detail.value as ProjectOwnership)}
              value={getValues('ownership') ?? ProjectOwnership.ALL}
              data-testid="dashboard-project-ownership-filter"
            >
              <WppSegmentedControlItem value={ProjectOwnership.ALL}>
                {t('dashboard.field_my_project_label_all')!}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={ProjectOwnership.MY}>
                {t('dashboard.field_my_project_label_my')!}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={ProjectOwnership.SHARED_WITH_ME}>
                {t('dashboard.field_my_project_label_shared')!}
              </WppSegmentedControlItem>
            </WppSegmentedControl>
            <FormSelect
              name="status"
              type="multiple"
              data-testid="status-select"
              options={statusOptions}
              labelConfig={{ text: t('modals.dashboard_filters.field_status_label') }}
              placeholder={t('modals.dashboard_filters.field_status_placeholder')!}
              withFolder
              required
            />
            <FormSelect
              name="type"
              type="multiple"
              data-testid="type-select"
              options={projectTypes}
              labelConfig={{ text: t('modals.dashboard_filters.field_type_label') }}
              placeholder={t('modals.dashboard_filters.field_type_placeholder')!}
              withFolder
              required
            />
            {!isAgencyWorkspace && <HierarchyFilterControl />}
            {attributes.map(attribute => {
              switch (attribute.type) {
                case ProjectAttributeType.SINGLE_SELECT:
                  return <SingleSelectAttribute formGroup="attributes" key={attribute.id} attribute={attribute} />
                case ProjectAttributeType.MULTI_SELECT:
                  return <MultiSelectAttribute formGroup="attributes" key={attribute.id} attribute={attribute} />
                default:
                  return null
              }
            })}
          </Flex>
          <Flex justify="between" slot="actions">
            <Flex>
              {(appliedFilters(watchAllFields) > 0 || isStatusDirty || isOwnershipDirty) && (
                <WppActionButton variant="primary" onClick={onReset}>
                  <WppIconReset className={styles.resetIcon} />
                  {t('common.btn_reset')}
                </WppActionButton>
              )}
            </Flex>
            <Flex gap={12}>
              <WppButton variant="secondary" size="m" onClick={onClose}>
                {t('modals.dashboard_filters.btn_cancel')}
              </WppButton>
              <WppButton variant="primary" size="m" onClick={onSubmit} loading={isSubmitting}>
                {t('common.btn_apply')}
              </WppButton>
            </Flex>
          </Flex>
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showProjectFilterModal } = createNiceModal<Props>(ProjectFilterModal, 'project-filter-modal')
