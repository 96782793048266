// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HFCLm{display:flex;align-items:center;justify-content:center;height:32px}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/components/tenantFilterSelect/TenantFilterSelect.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":[".selectLoader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectLoader": `HFCLm`
};
export default ___CSS_LOADER_EXPORT___;
