import { projectApi } from 'api'
import { Template } from 'types/projects/template'

export interface PatchWorkflowTemplate {
  id: string
  name?: Template['name']
  tags?: Template['tags']
  description?: Template['description']
  types?: Template['types']
  projectId?: Template['projectId']
  processType?: Template['processType']
  isPublished?: boolean
}

export const patchWorkflowTemplateApi = ({ id, ...body }: PatchWorkflowTemplate) =>
  projectApi.patch(`/templates/${id}`, body)
