// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OAQr5::part(card){padding:4px 6px 4px 8px}.OAQr5.s8t1b::part(card){padding:5px 8px;box-shadow:none}.i3QR9{overflow:hidden}.i3QR9:hover{text-decoration:underline}.W1YZa{margin-left:auto}.sCrjF{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/externalLink/ExternalLinkView.module.scss"],"names":[],"mappings":"AACE,mBACE,uBAAA,CAIA,yBACE,eAAA,CACA,eAAA,CAKN,OACE,eAAA,CAEA,aACE,yBAAA,CAIJ,OACE,gBAAA,CAGF,OACE,eAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".externalLinkContainer {\n  &::part(card) {\n    padding: 4px 6px 4px 8px;\n  }\n\n  &.preview {\n    &::part(card) {\n      padding: 5px 8px;\n      box-shadow: none;\n    }\n  }\n}\n\n.externalLink {\n  overflow: hidden;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.externalLinkMenu {\n  margin-left: auto;\n}\n\n.textBreak {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"externalLinkContainer": `OAQr5`,
	"preview": `s8t1b`,
	"externalLink": `i3QR9`,
	"externalLinkMenu": `W1YZa`,
	"textBreak": `sCrjF`
};
export default ___CSS_LOADER_EXPORT___;
