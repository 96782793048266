import {
  WppIconDatabase,
  WppIconDocument,
  WppIconFileZip,
  WppIconImage,
  WppIconMusic,
  WppIconPitch,
  WppIconStatisticDocument,
  WppIconVideoClip,
} from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'

import { FileFormatMapByCategory, FileTypes } from 'pages/project/components/files/utils'

const FilesIconFormat: Record<FileTypes, ReactNode> = {
  music: <WppIconMusic />,
  compressed: <WppIconFileZip />,
  data: <WppIconDatabase />,
  images: <WppIconImage />,
  presentations: <WppIconPitch />,
  spreadsheet: <WppIconStatisticDocument />,
  videos: <WppIconVideoClip />,
  documents: <WppIconDocument />,
}

export const getFileIcon = (fileName: string) => {
  const extension = fileName.match(/\.[^.]+$/)![0]
  return FilesIconFormat[FileFormatMapByCategory[extension]!]
}
