import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import type { Identifier } from 'dnd-core'
import { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import placeholderStyles from 'pages/project/components/canvas/components/placeholder/Placeholder.module.scss'
import { useDragScrolling } from 'pages/project/components/canvas/hooks/useDragScrolling'
import { Item } from 'pages/project/components/canvas/linearCanvas/components/item/Item'
import { showAddEditActivityModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AddEditActivityModal'
import { showEditAppModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/EditAppModal'
import { isApplicationItem } from 'pages/project/components/canvas/phaseUtils'
import { DnDItem, DragContainerType, DropPosition, getDropPosition } from 'pages/project/components/canvas/utils'
import { LinearDispatchContext } from 'providers/common/LinearGenericProvider'
import { ActivityItem, ApplicationItem, PhaseItem } from 'types/projects/workflow'

interface Props {
  projectId: string
  task: PhaseItem
  index: number
  isOwnerOrGlobalManage: boolean
  isWrikeConnected?: boolean
  isIAssignToThisPhase?: boolean
  isDraggingDisabled?: boolean
  isInactive?: boolean
  isTemplate?: boolean
}

export const DragItem = ({
  projectId,
  task,
  index,
  isIAssignToThisPhase,
  isDraggingDisabled,
  isInactive,
  isWrikeConnected,
  isTemplate,
  isOwnerOrGlobalManage,
}: Props) => {
  const { dropOnPhaseItem } = useContext(LinearDispatchContext)

  const { listenWindow, removeListenerWindow } = useDragScrolling()

  useEffect(() => {
    return () => removeListenerWindow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dndRestricted = !isOwnerOrGlobalManage && !isIAssignToThisPhase

  const dragDisabled = dndRestricted || isDraggingDisabled

  const editApplicationModal = () => {
    showEditAppModal({
      application: task.item as ApplicationItem,
      isDisabled: isInactive,
      projectId,
      isWrikeConnected,
      isTemplate,
    })
  }

  const editActivityModal = () => {
    showAddEditActivityModal({
      projectId,
      phaseId: task.id,
      activity: task.item as ActivityItem,
      isDisabled: isInactive,
      isWrikeConnected,
      isTemplate,
    })
  }
  const ref = useRef<HTMLDivElement>(null)
  const [hoverPosition, setHoverPosition] = useState<DropPosition | null>(null)
  const [placeholderHeight, setPlaceholderHeight] = useState<MayBeNull<number>>(null)

  const [{ handlerId, isOverCurrent }, drop] = useDrop<
    DnDItem,
    void,
    {
      handlerId: MayBeNull<Identifier>
      isOverCurrent: boolean
    }
  >(
    {
      accept: DragContainerType.Item,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
          isOverCurrent: monitor.isOver({ shallow: true }),
        }
      },
      canDrop() {
        return !dndRestricted
      },
      hover(dndItem: DnDItem, monitor) {
        if (dndItem.id === task.id || !monitor.canDrop()) {
          setHoverPosition(null)
          return
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        const offset = monitor.getClientOffset()

        setHoverPosition(hoverBoundingRect && offset ? getDropPosition(hoverBoundingRect, offset) : null)
        setPlaceholderHeight(dndItem.height ?? null)
      },
      drop(dndItem: DnDItem, monitor) {
        const didDrop = monitor.didDrop()

        removeListenerWindow()
        if (didDrop) return

        if (!ref.current) return

        //  drop on self
        if (task.id === dndItem.id) {
          return
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        dropOnPhaseItem(task.phaseId, task, dndItem, getDropPosition(hoverBoundingRect, monitor.getClientOffset()!))
      },
    },
    [dropOnPhaseItem, dndRestricted],
  )

  const [{ isDragging }, drag] = useDrag(
    {
      type: DragContainerType.Item,
      item: (): DnDItem => {
        listenWindow()
        return {
          index,
          id: task.id,
          type: task.itemType,
          phaseId: task.phaseId,
          height: ref.current?.getBoundingClientRect().height,
        }
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => !isInactive && !dragDisabled,
    },
    [dragDisabled],
  )
  drag(drop(ref))

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={
        {
          '--placeholder-height': placeholderHeight ? `${placeholderHeight}px` : null,
          display: isDragging ? 'none' : null,
        } as CSSProperties
      }
      className={clsx({
        [placeholderStyles.dropPlaceholderTop]: isOverCurrent && hoverPosition === DropPosition.Above,
        [placeholderStyles.dropPlaceholderBottom]: isOverCurrent && hoverPosition === DropPosition.Below,
      })}
      data-testid="drag-phase-item-container"
    >
      <Item
        variant="secondary"
        phaseItem={task}
        isIAssignToThisPhase={isIAssignToThisPhase}
        toggleEditModal={isApplicationItem(task) ? editApplicationModal : editActivityModal}
        isEditable
        index={index}
        isDraggingDisabled={isDraggingDisabled}
        projectId={projectId}
        isWrikeConnected={isWrikeConnected}
        isTemplate={isTemplate}
        isOwnerOrGlobalManage={isOwnerOrGlobalManage}
      />
    </div>
  )
}
