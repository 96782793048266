import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import styles from 'pages/project/components/tasks/components/statusText/StatusText.module.scss'
import { TaskStatus } from 'types/projects/tasks'

interface Props {
  statusKey: TaskStatus | string
  small?: boolean
  isExternal?: boolean
  className?: string
  'data-testid'?: string
}

export const StatusText = ({ statusKey, small, className, 'data-testid': dataTestId, isExternal }: Props) => {
  const { t } = useTranslation()

  return (
    <WppTypography
      type={small ? 'xs-body' : 's-body'}
      className={clsx({ [styles[statusKey]]: !isExternal }, className)}
      data-testid={dataTestId ?? 'task-status'}
    >
      {isExternal ? statusKey : t(`project.tasks.status.${statusKey}`)}
    </WppTypography>
  )
}
