import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { get, set, del } from 'idb-keyval'
import { PropsWithChildren, useState } from 'react'

import { queryClient } from 'providers/osQueryClient/utils'

const REACT_QUERY_DEV_OFFLINE_CACHE_KEY = 'WPP_ORCHESTRATION_QUERY_API_CACHE_KEY'

const persister = createAsyncStoragePersister({
  storage: {
    getItem: async key => (await get(key)) || null,
    setItem: (key, value) => set(key, value),
    removeItem: key => del(key),
  },
  key: REACT_QUERY_DEV_OFFLINE_CACHE_KEY,
  throttleTime: 10000,
})

export const DevQueryClientProvider = ({ children }: PropsWithChildren<{}>) => {
  const [buster] = useState(() => {
    let uuid = window.sessionStorage.getItem('tab-orchestration-session-id')

    if (!uuid) {
      uuid = crypto.randomUUID()
      window.sessionStorage.setItem('tab-orchestration-session-id', uuid)
    }

    return uuid
  })

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister, buster }}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </PersistQueryClientProvider>
  )
}
