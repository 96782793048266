export enum IntegrationType {
  WRIKE = 'wrike',
}

export interface IntegrationDTOResponse {
  data: IntegrationDTO[]
}

export interface IntegrationDTO {
  type: IntegrationType
  connectionId: string
  name: string
  permalink: string
}
