import { WppBackToTopButton } from '@platform-ui-kit/components-library-react'
import { useState, useEffect } from 'react'

import styles from 'components/common/table/PageBackToTop.module.scss'
import { useThrottleFn } from 'hooks/useThrottleFn'

const DEFAULT_TOP_OFFSET = 250

interface Props {
  scrollTopOffset?: number
  onChangeState?: (state: boolean) => void
}

export function PageBackToTop({ scrollTopOffset = DEFAULT_TOP_OFFSET, onChangeState }: Props) {
  const [thresholdMet, setThresholdMet] = useState(false)

  const handleScroll = () => {
    const newState = window.scrollY > scrollTopOffset
    setThresholdMet(newState)
    onChangeState?.(newState)
  }

  const handleScrollThrottled = useThrottleFn(handleScroll, 200)

  useEffect(() => {
    window.addEventListener('scroll', handleScrollThrottled)

    return () => {
      window.removeEventListener('scroll', handleScrollThrottled)
    }
  }, [handleScrollThrottled, onChangeState, scrollTopOffset])

  const handleBackToTopClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {thresholdMet && (
        <div className={styles.buttonWrapper}>
          <WppBackToTopButton onClick={handleBackToTopClick} />
        </div>
      )}
    </>
  )
}
