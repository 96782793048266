import { projectApi } from 'api'
import { UploadInfo } from 'types/attaschments/attachments'

interface Params {
  projectId: string
  names: string[]
  contentType: string
}

export const generateProjectUploadUrlsApi = (data: Params) =>
  projectApi.post<UploadInfo[]>('/files/generate-temp-upload-urls', data)
