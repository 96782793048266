import { TabsChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppTab, WppTabs, WppDivider } from '@platform-ui-kit/components-library-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PhaseDetailsActivities } from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsActivities'
import {
  PhaseTabsTypes,
  phaseTabs,
} from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModal'
import { PhaseDetailsModalApps } from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModalApps'
import { ActivityItem, ApplicationItem, PhaseItem, PhaseItemType } from 'types/projects/workflow'

interface Props {
  tabs: typeof phaseTabs
  activities: PhaseItem<ActivityItem>[]
  apps: PhaseItem<ApplicationItem>[]
  setSelectedItem: (id: string | null, type: PhaseItemType | null) => void
}

type ActivityTabId = (typeof phaseTabs)[number]['id']

export const PhaseDetailsModalTabs = ({ tabs, activities, apps, setSelectedItem }: Props) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState<ActivityTabId>(PhaseTabsTypes.activities)

  useEffect(() => {
    setCurrentTab(tabs[0].id)
  }, [tabs])

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value as ActivityTabId)
  }

  return (
    <>
      <WppTabs value={currentTab} size="s" onWppChange={handleTabChange} hidden={tabs.length === 1}>
        {tabs.map(({ id, value }) => (
          <WppTab key={id} value={id}>
            {t(value)}
          </WppTab>
        ))}
      </WppTabs>

      {tabs.length === 1 && <WppDivider />}
      {
        {
          activities: <PhaseDetailsActivities activities={activities} setSelectedItem={setSelectedItem} />,
          apps: <PhaseDetailsModalApps apps={apps} setSelectedItem={setSelectedItem} />,
        }[currentTab]
      }
    </>
  )
}
