// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tdm32{flex:1 1;height:100%}.srR9Y{color:var(--wpp-grey-color-800)}.LMgtc{width:400px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/common/emptyState/EmptyState.module.scss"],"names":[],"mappings":"AAAA,OACE,QAAA,CACA,WAAA,CAGF,OACE,+BAAA,CAGF,OACE,WAAA,CACA,iBAAA","sourcesContent":[".noData {\n  flex: 1;\n  height: 100%;\n}\n\n.greyColor800 {\n  color: var(--wpp-grey-color-800);\n}\n\n.emptyStateTextWrapper {\n  width: 400px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noData": `tdm32`,
	"greyColor800": `srR9Y`,
	"emptyStateTextWrapper": `LMgtc`
};
export default ___CSS_LOADER_EXPORT___;
