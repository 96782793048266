import { projectApi } from 'api'
import { ProjectViewSettings } from 'types/projects/projectViewSettings'

interface UpdateProjectViewSettings {
  projectId: string
  preferenceId?: string
  settings: ProjectViewSettings
}

export const updateProjectViewSettingsApi = ({ projectId, preferenceId, settings }: UpdateProjectViewSettings) =>
  projectApi.patch<ProjectViewSettings>(`/projects/${projectId}/canvas_preferences/${preferenceId}`, settings)
