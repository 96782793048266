// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q9OML{display:flex}`, "",{"version":3,"sources":["webpack://./src/components/form/formSegmentControl/FormSegmentControl.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA","sourcesContent":[".root {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Q9OML`
};
export default ___CSS_LOADER_EXPORT___;
