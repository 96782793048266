import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { AppLaunchError } from 'hooks/application/useAppErrors'
import { AppIssueCard } from 'pages/components/appIssueCard/AppIssueCard'
import styles from 'pages/project/components/canvas/components/appPikerModal/appsCreateIssuesModal/AppsCreateIssuesModal.module.scss'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  appsCount: number
  appsErrors: AppLaunchError[]
  onAddApps: () => Promise<void>
}

const AppsCreateIssuesModal = ({ appsCount, appsErrors, onAddApps, isOpen, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const [isCreating, setIsCreating] = useState(false)

  const handleAddApps = async () => {
    try {
      setIsCreating(true)
      await onAddApps()
      onClose()
    } catch (error) {
      setIsCreating(false)
    }
  }

  return (
    <SideModal open={isOpen} onWppSideModalClose={onClose} onWppSideModalCloseComplete={onCloseComplete} size="m">
      <WppTypography slot="header" type="2xl-heading">
        {t('modals.app_create_issues.title')}
      </WppTypography>

      <div slot="body" className={styles.modalBody}>
        <WppTypography type="s-body">{t('modals.app_create_issues.info', { count: appsCount })}</WppTypography>

        <Flex direction="column" gap={12} className="wpp-spacing-24-top">
          {appsErrors.map((appError, idx) => (
            <AppIssueCard
              key={idx}
              issueType={appError.issue}
              missingCtx={appError.missedCtx}
              appName={appError.appName}
            />
          ))}
        </Flex>
      </div>
      <Flex justify="end" slot="actions">
        <Flex gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('modals.create_project.btn_cancel')}
          </WppButton>

          <WppButton variant="primary" size="m" onClick={handleAddApps} loading={isCreating}>
            {t('modals.app_create_issues.btn_primary', { count: appsCount })}
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showAppsCreateIssuesModal } = createProjectModal<Props>(
  AppsCreateIssuesModal,
  'apps-create-issues-modal',
)
