import { safeParseJSON } from 'utils/common'

const getTimelineRow = (index: number) => {
  return document.querySelector(`.ganttWrapper g.rows > rect:nth-child(${index + 1})`)
}

export const highlightRow = (index: number) => {
  getTimelineRow(index)?.classList.add('rowHover')
}

export const unHighlightRow = (index: number) => {
  getTimelineRow(index)?.classList.remove('rowHover')
}

export const getTimelineExpandedState = (taskId: string): boolean => {
  const savedState = sessionStorage.getItem(`timeline-task-${taskId}-expanded`)
  const parsedState = safeParseJSON(savedState)
  return parsedState !== null ? parsedState : false
}

export const setTimelineExpandedState = (taskId: string, isExpanded: boolean): void => {
  sessionStorage.setItem(`timeline-task-${taskId}-expanded`, JSON.stringify(isExpanded))
}
