import { useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { AttachedFile } from 'pages/project/components/canvas/components/attachedFile/AttachedFile'
import { ExternalLinkView } from 'pages/project/components/canvas/components/externalLink/ExternalLinkView'
import { ActivityFiles, ExternalLink } from 'types/projects/workflow'

interface Props {
  activityId: string
  externalLinks?: ExternalLink[]
  files: ActivityFiles[]
}

export const ActivityDetailsModalResources = ({ activityId, externalLinks, files }: Props) => {
  const sortedExternalLinksByNewest = useMemo(() => {
    return externalLinks?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  }, [externalLinks])

  return (
    <Flex direction="column" gap={8} data-testid="activity-files">
      {!!sortedExternalLinksByNewest?.length && (
        <>
          {sortedExternalLinksByNewest.map(link => (
            <ExternalLinkView
              activityId={activityId}
              link={link}
              key={link.id}
              variant="secondary"
              isDisabled={false}
              isPreview
            />
          ))}
        </>
      )}

      {!!files?.length &&
        files.map(file => (
          <AttachedFile file={file} key={file.id} activityId={activityId} variant="secondary" preview fileAction />
        ))}
    </Flex>
  )
}
