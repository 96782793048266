// Product Category
import { NativeAppsCategory, NativeAppsCommercialModel } from 'types/products/nativeApp'

export const NativeAppsCategoryLabel: Record<NativeAppsCategory, string> = {
  [NativeAppsCategory.MEDIA]: 'Media Studio',
  [NativeAppsCategory.PRODUCTION]: 'Production Studio',
  [NativeAppsCategory.BRAND]: 'Brand Studio',
  [NativeAppsCategory.COMMERCE]: 'Commerce Studio',
  [NativeAppsCategory.CREATIVE]: 'Creative Studio',
  [NativeAppsCategory.EXPERIENCE]: 'Experience Studio',
  [NativeAppsCategory.PR]: 'PR Studio',
  [NativeAppsCategory.DATA]: 'Data',
  [NativeAppsCategory.OTHER_APPS]: 'Other Apps',
}

export const nativeAppsCategoryOptions = [
  ...Object.entries(NativeAppsCategoryLabel).map(([key, value]) => ({ label: value, value: key })),
]

// Product Commercial model
export const NativeAppsCommercialModelLabel = {
  [NativeAppsCommercialModel.CONTRACT]: 'Contract',
  [NativeAppsCommercialModel.FREE]: 'Free',
  [NativeAppsCommercialModel.PAY_AS_YOU_GO]: 'Pay-as-you-go',
}

export const nativeAppsCommercialModelOptions = [
  ...Object.entries(NativeAppsCommercialModelLabel).map(([key, value]) => ({ label: value, value: key })),
]
