import { Task as GanttTask } from 'gantt-task-react'
import { useContext } from 'react'

import { ProjectsTimelineContext } from 'pages/dashboard/components/projectsTimelineView/ProjectsTimelineProvider'

interface Props {
  task: GanttTask
}

export const ProjectTooltipContent = ({ task: ganttTask }: Props) => {
  const { timeline } = useContext(ProjectsTimelineContext)

  const entity = timeline.find(task => task.id === ganttTask.id)

  if (!entity) {
    return null
  }

  return null
}
