import { useTemplate } from 'hooks/useTemplate'
import { FluidTemplate } from 'pages/template/fluid/FluidTemplate'
import { LinearTemplate } from 'pages/template/linear/LinearTemplate'
import { LinearTemplateProvider } from 'pages/template/LinearTemplateProvider'
import { ProcessType } from 'types/projects/projects'

export const CanvasTemplate = () => {
  const { template } = useTemplate()

  return template.processType === ProcessType.LINEAR ? (
    <LinearTemplateProvider>
      <LinearTemplate />
    </LinearTemplateProvider>
  ) : (
    <FluidTemplate />
  )
}
