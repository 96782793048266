import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppFilterButton,
  WppInput,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'

import { TasksParams } from 'api/projects/fetchers/fetchProjectTasksApi'
import { useProjectTasksApi } from 'api/projects/queries/useProjectTasksApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useProject } from 'hooks/useProject'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import { StickySubheader } from 'pages/project/components/canvas/linearCanvas/components/stickySubheader/StickySubheader'
import { TasksStatusColumns } from 'pages/project/components/tasks//TasksStatusColumns'
import { TaskStatusFilter } from 'pages/project/components/tasks/components/statusFilter/TaskStatusFilter'
import { showTasksFiltersModal } from 'pages/project/components/tasks/components/tasksFilters/TasksFiltersModal'
import { useCustomExternalStatuses } from 'pages/project/components/tasks/hooks/useCustomExternalStatuses'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { allStatuses, alwaysShowStatuses, groupTasks } from 'pages/project/components/tasks/utils'
import { LocalStorageKey } from 'types/common/localStorage'
import { DashboardOwnership } from 'types/projects/DashboardData'
import { TasksFilter, TaskStatus } from 'types/projects/tasks'

const TasksSkeleton = () => (
  <Flex direction="row" gap={28} className={styles.tasksLoadingContainer}>
    {[...Array(3)].map((_, index) => (
      <WppSkeleton key={index} variant="rectangle" height="auto" width="100%" />
    ))}
  </Flex>
)
export const initialTasksFilters: TasksFilter = {
  search: '',
  dueDateRanges: [],
  tenant: [],
  selectedStatuses: [],
}

type Filters = Omit<TasksFilter, 'search'>

interface LsFilters extends Filters {
  taskType: DashboardOwnership
}

export const Tasks = () => {
  const { t } = useTranslation()

  const {
    osContext: { userDetails },
  } = useOs()
  const { project, useExternalStatuses, wrikeWorkflows } = useProject()
  const { externalStatusesEntries, externalStatusesMapped } = useCustomExternalStatuses(wrikeWorkflows)
  const [hideSubHeader, setHideSubHeader] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const [taskFilters, setTaskFilters] = useLocalStorage<LsFilters>(
    `${LocalStorageKey.PROJECT_TASKS_FILTERS}:${project.id}:${userDetails.id}`,
    {} as LsFilters,
  )

  const [filters, setFilters] = useState<TasksFilter>({ ...initialTasksFilters, ...taskFilters })
  const [subset, setSubset] = useState<DashboardOwnership>(taskFilters?.taskType || DashboardOwnership.ALL)

  const externalStatuses = useMemo(() => {
    return Object.keys(externalStatusesEntries)
  }, [externalStatusesEntries])

  const mapExternalFilterStatusesToOs = useMemo(() => {
    if (!wrikeWorkflows?.statusMappings) return []
    if (!filters.selectedStatuses?.length) return alwaysShowStatuses
    return wrikeWorkflows.statusMappings
      .filter(status => filters.selectedStatuses?.includes(status.wrikeStatusId) && status.direction === 'to_os')
      .map(status => status.osStatus)
  }, [filters.selectedStatuses, wrikeWorkflows?.statusMappings])

  const statusesOptions = useMemo(() => {
    return useExternalStatuses ? externalStatusesMapped : allStatuses
  }, [externalStatusesMapped, useExternalStatuses])

  useEffect(() => {
    const filteredStatuses = !useExternalStatuses
      ? statusesOptions.filter(status => ![TaskStatus.ARCHIVED].includes(status as TaskStatus))
      : statusesOptions

    setFilters(prev => ({ ...prev, selectedStatuses: filteredStatuses }))
  }, [statusesOptions, useExternalStatuses])

  useEffect(() => {
    const { search, ...rest } = filters
    setTaskFilters(prev => ({ ...prev, ...rest }))
  }, [setTaskFilters, filters])

  const mappedFilters: TasksParams = useMemo(() => {
    const statuses = useExternalStatuses
      ? mapExternalFilterStatusesToOs
      : filters.selectedStatuses?.length
      ? filters.selectedStatuses
      : allStatuses

    return {
      projectId: project.id,
      text: filters.search,
      subset,
      statuses,
      dueDateRanges: filters.dueDateRanges,
      includeEmptyWorkspace: filters.includeEmptyWorkspace,
    }
  }, [
    filters.dueDateRanges,
    filters.search,
    filters.selectedStatuses,
    filters.includeEmptyWorkspace,
    mapExternalFilterStatusesToOs,
    project.id,
    subset,
    useExternalStatuses,
  ])

  const isDefaultStatusFilter = !filters.selectedStatuses?.length

  const statusColumns = useMemo(() => {
    return isDefaultStatusFilter
      ? alwaysShowStatuses
      : allStatuses.filter(status => mappedFilters.statuses!.includes(status))
  }, [isDefaultStatusFilter, mappedFilters.statuses])

  const { data: tasks, isLoading: isTasksLoading } = useProjectTasksApi({
    params: {
      ...mappedFilters,
    },
  })

  const mappedTasks = useMemo(() => {
    return groupTasks({
      tasks,
      statuses: useExternalStatuses ? externalStatuses : allStatuses,
      useExternalStatuses: !!useExternalStatuses,
    })
  }, [externalStatuses, tasks, useExternalStatuses])

  const mappedGroupedTasks = useMemo(() => Object.entries(mappedTasks), [mappedTasks])

  const setSearchDebounced = useDebounceFn((e: WppInputCustomEvent<InputChangeEventDetail>) => {
    const search = e.detail.value || ''
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const handleCloseFilterModal = (newFilter: TasksFilter) => {
    setFilters(filters => ({ ...filters, ...newFilter }))
  }

  const filtersCounts = useMemo(() => {
    const { selectedStatuses, attributes, includeEmptyWorkspace, ...rest } = filters
    return getAppliedFilters({ ...rest, ...attributes })
  }, [filters])

  const isFiltersApplied = !!filtersCounts || !!filters.search

  const handleSubsetChange = (sub: DashboardOwnership) => {
    setSubset(sub)
    setTaskFilters(prev => {
      const { taskType, ...rest } = prev
      return { taskType: sub, ...rest }
    })
  }

  const tasksColumns = useMemo(() => {
    return mappedGroupedTasks
      .map(([_, statuses]) =>
        statusColumns.map(statusKey => {
          const tasks = statuses[statusKey]
          const name = t(`project.tasks.status.${statusKey}`) + ` (${tasks?.length || 0})`
          return { id: statusKey as string, name }
        }),
      )
      .flat()
  }, [mappedGroupedTasks, statusColumns, t])

  const externalTasksColumns = useMemo(() => {
    if (!useExternalStatuses) return []
    return mappedGroupedTasks
      .map(([_, statuses]) =>
        wrikeWorkflows!.customStatuses.map(customStatus => {
          const tasks = statuses[customStatus.wrikeId]
          const name = customStatus.name + ` (${tasks?.length || 0})`
          return { id: customStatus.wrikeId, name }
        }),
      )
      .flat()
  }, [mappedGroupedTasks, wrikeWorkflows, useExternalStatuses])

  const columns = useMemo(() => {
    // If the default status filter is enabled and we are not using external statuses
    if (isDefaultStatusFilter && !useExternalStatuses) {
      return (allStatuses || []).map(status => ({
        id: status,
        name: t(`project.tasks.status.${status}`),
      }))
    }

    const selectedColumns = useExternalStatuses ? externalTasksColumns : tasksColumns

    // Ensure there are at least 3 columns by adding empty objects if needed
    return selectedColumns.length >= 3
      ? selectedColumns
      : [...selectedColumns, ...Array(3 - selectedColumns.length).fill({})]
  }, [isDefaultStatusFilter, useExternalStatuses, externalTasksColumns, tasksColumns, t])

  return (
    <Flex direction="column" gap={24} className={styles.tasksContainer}>
      <PageBackToTop scrollTopOffset={220} onChangeState={setHideSubHeader} />
      <Flex justify="between">
        <Flex gap={12}>
          <WppInput
            size="s"
            name="search"
            placeholder={t('project.tasks.field_search_placeholder')!}
            onWppChange={setSearchDebounced}
            type="search"
            data-testid="project-tasks-search"
            className={styles.searchInput}
          />
          <WppSegmentedControl
            slot="actions"
            value={subset}
            size="s"
            onWppChange={e => {
              handleSubsetChange(e.target.value as DashboardOwnership)
            }}
            data-testid="project-tasks-switch"
          >
            <WppSegmentedControlItem value={DashboardOwnership.ALL}>
              {t('project.tasks.filters.switch_all_tasks')}
            </WppSegmentedControlItem>
            <WppSegmentedControlItem value={DashboardOwnership.MY}>
              {t('project.tasks.filters.switch_my_tasks')}
            </WppSegmentedControlItem>
          </WppSegmentedControl>

          <TaskStatusFilter
            useExternalStatuses={useExternalStatuses}
            filters={filters}
            setFilters={setFilters}
            statusesOptions={statusesOptions}
            externalStatusesEntries={externalStatusesEntries}
            wrikeWorkflowsLoading={!wrikeWorkflows}
          />

          <WppFilterButton
            onClick={() =>
              showTasksFiltersModal({
                filters,
                onFiltersSave: handleCloseFilterModal,
              })
            }
            data-testid="tasks-filter-button"
            counter={filtersCounts}
          >
            {t('project.files.btn_filter')!}
          </WppFilterButton>
        </Flex>
      </Flex>
      <>
        {isTasksLoading ? (
          <TasksSkeleton />
        ) : (
          <>
            {!tasks.length ? (
              <EmptyState
                title={isFiltersApplied ? t('common.no_search_results') : t('project.tasks.no_tasks')}
                filtersApplied={isFiltersApplied}
                description={
                  isFiltersApplied ? t('common.no_results_description') : t('project.tasks.no_tasks_description')
                }
                testToken="tasks"
              />
            ) : (
              <DndProvider backend={HTML5Backend}>
                <Flex direction="column" className={styles.draggableTasks} ref={containerRef}>
                  <StickySubheader
                    columns={columns}
                    headerHidden={!hideSubHeader}
                    containerRef={containerRef}
                    columnClass={styles.stickyColumnOverview}
                  />
                  <TasksStatusColumns
                    tasks={tasks}
                    useExternalStatuses={useExternalStatuses}
                    mappedFilters={mappedFilters}
                    statuses={filters.selectedStatuses}
                    hideSubHeader={hideSubHeader}
                  />
                </Flex>
              </DndProvider>
            )}
          </>
        )}
      </>
    </Flex>
  )
}
