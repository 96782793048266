import { LabelConfig } from '@platform-ui-kit/components-library'
import { SegmentedControlValue } from '@platform-ui-kit/components-library/dist/types/components/wpp-segmented-control/types'
import { DropdownConfig } from '@platform-ui-kit/components-library/dist/types/types/common'
import { WppLabel, WppSegmentedControl, WppSegmentedControlItem } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentProps, ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/form/formSegmentControl/FormSegmentControl.module.scss'
import { useCommonLabelProps } from 'components/form/utils'
import { useField } from 'hooks/form/useField'

export interface FormSelectOption {
  label: string
  value: string
}

interface Props<T = any>
  extends Omit<ComponentPropsWithoutRef<typeof WppSegmentedControl>, 'onChange' | 'onWppChange' | 'value'> {
  name: string
  options: T[]
  getOptionLabel?: (option: T) => string
  getOptionValue?: (option: T) => SegmentedControlValue
  'data-testid'?: string
  labelConfig?: LabelConfig
  labelTooltipConfig?: DropdownConfig
  required?: boolean
  containerProps?: ComponentProps<typeof Flex>
}

export const FormSegmentControl = forwardRef<HTMLWppSegmentedControlElement, Props>(
  (
    {
      name,
      options,
      onWppBlur,
      width = '100%',
      getOptionLabel = option => option,
      getOptionValue = option => option,
      'data-testid': dataTestId,
      labelConfig,
      labelTooltipConfig,
      required,
      containerProps,
      className,
      ...rest
    },
    ref,
  ) => {
    const {
      field: { ref: fieldRef, value, onChange },
    } = useField({
      name,
    })

    const innerRef = useRef<HTMLWppSegmentedControlElement>(null)

    useImperativeHandle(
      fieldRef,
      () => ({
        focus: () => innerRef.current?.focus(),
      }),
      [],
    )

    const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

    return (
      <Flex direction="column" gap={8} {...containerProps}>
        {!!labelConfig && (
          <WppLabel
            config={labelProps.labelConfig}
            tooltipConfig={labelProps.labelTooltipConfig}
            optional={!required}
          />
        )}

        <WppSegmentedControl
          {...rest}
          ref={mergeRefs([innerRef, ref])}
          value={value}
          className={clsx(styles.root, className)}
          onWppChange={({ detail }) => onChange(detail.value)}
          data-testid={dataTestId}
          width={width}
        >
          {options.map(option => {
            const value = getOptionValue(option)

            return (
              <WppSegmentedControlItem key={value} value={value}>
                {getOptionLabel(option)}
              </WppSegmentedControlItem>
            )
          })}
        </WppSegmentedControl>
      </Flex>
    )
  },
) as <T>(props: Props<T>) => JSX.Element
