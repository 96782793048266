import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'pages/project/components/timeline/components/taskListTable/TaskListHeader.module.scss'

interface Props {
  headerHeight: number
  rowWidth: string
  fontFamily: string
  fontSize: string
}

export const ProjectTimelineHeader = ({ headerHeight, fontFamily, fontSize, rowWidth }: Props) => {
  const { t } = useTranslation()
  return (
    <div
      className={styles.ganttTable}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      <div
        className={styles.ganttTable_Header}
        style={{
          height: headerHeight - 2,
        }}
      >
        <div
          className={styles.ganttTable_HeaderItem}
          style={{
            minWidth: rowWidth,
          }}
        >
          <WppTypography className={styles.marginLeft} type="s-strong">
            {t('dashboard.timeline_table_header')}
          </WppTypography>
        </div>
      </div>
    </div>
  )
}
