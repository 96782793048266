import { projectApi } from 'api'
import { AttachmentMeta } from 'types/attaschments/attachments'

interface Props {
  activityId: string
  projectFilesIds: string[]
  files: AttachmentMeta[]
}

export const addActivityFiles = ({ activityId, ...data }: Props) =>
  projectApi.post(`/activities/${activityId}/files`, { ...data })
