// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FSDgm{color:var(--wpp-success-color-500)}.G_Pgu{color:var(--wpp-grey-color-1000)}.DR1jP{color:var(--wpp-grey-color-800)}.Uo9_6{color:var(--wpp-grey-color-800)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/tasks/components/statusText/StatusText.module.scss"],"names":[],"mappings":"AAAA,OACE,kCAAA,CAGF,OACE,gCAAA,CAGF,OACE,+BAAA,CAGF,OACE,+BAAA","sourcesContent":[".COMPLETED {\n  color: var(--wpp-success-color-500);\n}\n\n.ARCHIVED {\n  color: var(--wpp-grey-color-1000);\n}\n\n.IN_PROGRESS {\n  color: var(--wpp-grey-color-800);\n}\n\n.TO_DO {\n  color: var(--wpp-grey-color-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"COMPLETED": `FSDgm`,
	"ARCHIVED": `G_Pgu`,
	"IN_PROGRESS": `DR1jP`,
	"TO_DO": `Uo9_6`
};
export default ___CSS_LOADER_EXPORT___;
