// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JYCka{margin-bottom:2px}.th03O{color:var(--wpp-grey-color-600)}.HIBax{color:var(--wpp-grey-color-800)}`, "",{"version":3,"sources":["webpack://./src/pages/components/backToTemplatesButton/BackToTemplatesButton.module.scss"],"names":[],"mappings":"AAAA,OAEE,iBAAA,CAGF,OACE,+BAAA,CAGF,OACE,+BAAA","sourcesContent":[".container {\n  // to compensate tabs bottom border\n  margin-bottom: 2px;\n}\n\n.icon {\n  color: var(--wpp-grey-color-600);\n}\n\n.greyColor800 {\n  color: var(--wpp-grey-color-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `JYCka`,
	"icon": `th03O`,
	"greyColor800": `HIBax`
};
export default ___CSS_LOADER_EXPORT___;
