import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { AttributeProps, getFieldName } from 'pages/components/projectModal/components/attributes/utils'

export function TextAreaAttribute({ attribute, formGroup }: AttributeProps) {
  const name = getFieldName(attribute, formGroup)
  return (
    <FormTextareaInput
      name={name}
      data-testid={`modal-project-${attribute.contractName}`}
      labelConfig={{
        text: attribute.name,
        ...(attribute.iconText && {
          icon: 'wpp-icon-info',
          description: attribute.iconText,
        }),
      }}
      message={attribute.helperText}
      required={attribute?.required ?? true}
      disabled={attribute?.enabled === false}
    />
  )
}
