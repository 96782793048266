import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { useProject } from 'hooks/useProject'
import { ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { ActivityItem, PhaseItem, PhaseItemType } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

const dummyContext = { members: [], linearData: null, fluidData: null, project: null }

export const useHasProjectRole = () => {
  const { osContext } = useOs()

  // @TODO: WPPLONOP-19812 - that's a temporary workaround. All project-coupled logic should be move out of common components
  const projectContext = useProject()

  const { members, linearData, fluidData, project } = projectContext ? projectContext : dummyContext

  const me = useMemo(
    () => members.find(({ email }) => isEqualEmails(email, osContext.userDetails.email)),
    [osContext.userDetails, members],
  )

  const hasRole = useCallback((roles: ProjectRole[]) => roles.includes(me?.role || ProjectRole.VIEWER), [me?.role])

  const checkIfResponsibleInsideActivity = useCallback(
    (activityItem: ActivityItem) => {
      return activityItem.items.some(item => isEqualEmails(item.application.assignUser, me?.email))
    },
    [me],
  )

  const isResponsible = useCallback(
    (email?: string) => {
      if (project?.processType === ProcessType.FLUID) {
        return fluidData?.items.reduce((prev, curr) => {
          if (prev) return prev
          return isEqualEmails(curr.assignUser, email || me?.email)
        }, false)
      }

      if (project?.processType === ProcessType.LINEAR) {
        return Object.values(linearData?.phases || {}).reduce((prev, phase) => {
          if (prev) return prev

          const phaseItems = phase.itemIds.map(id => linearData!.items[id]) as PhaseItem[]

          return (
            isEqualEmails(phase.assignUser, email || me?.email) ||
            phaseItems.some(phaseItem => phaseItem.item.assignUser === (email || me?.email)) ||
            phaseItems.some(
              phaseItem =>
                phaseItem.itemType === PhaseItemType.Activity &&
                checkIfResponsibleInsideActivity(phaseItem.item as ActivityItem),
            )
          )
        }, false)
      }
    },
    [linearData, checkIfResponsibleInsideActivity, fluidData?.items, me?.email, project?.processType],
  )

  return { hasRole, isResponsible, me }
}
