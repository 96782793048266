import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { FluidAppPatchAction, LinearData } from 'pages/project/components/canvas/utils'

export interface ApplicationMoveRequest {
  id: string

  phaseId?: MayBeNull<string>
  activityId?: MayBeNull<string>
  orderNumber?: number

  projectId?: MayBeNull<string>
  coordinateX?: number
  coordinateY?: number

  // service field, for fluid optimistic update
  fluidOptimisticAction?: FluidAppPatchAction
  // linear optimistic update data
  optimisticData?: Partial<Pick<LinearData, 'phases' | 'items'>>
}
export const moveApplicationApi = ({ id, fluidOptimisticAction, optimisticData, ...body }: ApplicationMoveRequest) =>
  projectApi.patch(`/applications/${id}/move`, body)
