// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l91NG{--wpp-typography-color: var(--wpp-grey-color-800)}`, "",{"version":3,"sources":["webpack://./src/components/common/userInfo/UserInfo.module.scss"],"names":[],"mappings":"AAAA,OACE,iDAAA","sourcesContent":[".userInfo {\n  --wpp-typography-color: var(--wpp-grey-color-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userInfo": `l91NG`
};
export default ___CSS_LOADER_EXPORT___;
