import {
  WppIconAvailableCheckmark,
  WppIconCircle,
  WppIconSuccess,
  WppListItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { PhaseStatus } from 'types/projects/workflow'

interface Props {
  onChange: (status: PhaseStatus) => void
  selectedStatus: PhaseStatus
}

export const PhaseStatusChange = ({ onChange, selectedStatus }: Props) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={4} style={{ marginTop: '8px' }}>
      {Object.values(PhaseStatus).map(status => (
        <WppListItem
          key={status}
          data-testid={`context-phase-${status}`}
          onWppChangeListItem={() => onChange(status)}
          checked={selectedStatus === status}
        >
          {selectedStatus === status ? (
            <>
              {selectedStatus === PhaseStatus.COMPLETED ? (
                <WppIconSuccess slot="left" />
              ) : (
                <WppIconAvailableCheckmark slot="left" />
              )}
            </>
          ) : (
            <WppIconCircle slot="left" />
          )}
          <WppTypography
            slot="label"
            type="s-body"
            className={clsx({ [styles.selectedText]: selectedStatus === status })}
          >
            {t(`project.phase.status.${status}`)!}
          </WppTypography>
        </WppListItem>
      ))}
    </Flex>
  )
}
