import { WppFilterButton, WppInput, WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import { ViewMode } from 'gantt-task-react'
import { SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useDebounceFn } from 'hooks/useDebounceFn'
import 'gantt-task-react/dist/index.css'
import { useProject } from 'hooks/useProject'
import { TaskStatusFilter } from 'pages/project/components/tasks/components/statusFilter/TaskStatusFilter'
import { showTasksFiltersModal } from 'pages/project/components/tasks/components/tasksFilters/TasksFiltersModal'
import { useCustomExternalStatuses } from 'pages/project/components/tasks/hooks/useCustomExternalStatuses'
import { allStatuses } from 'pages/project/components/tasks/utils'
import { TimelineChangeViewMode } from 'pages/project/components/timeline/components/timelineActionBar//TimelineChangeViewMode'
import styles from 'pages/project/components/timeline/components/timelineActionBar/TimelineActionBar.module.scss'
import { useFocusToday } from 'pages/project/components/timeline/components/useFocusToday'
import { TimelineFilters } from 'pages/project/components/timeline/utils'
import { Members } from 'types/members/members'

interface Props {
  filters: TimelineFilters
  setFilters: (cb: SetStateAction<TimelineFilters>) => void
}

const SEARCH_LENGTH_THRESHOLD = 2
const SEARCH_DEBOUNCE_TIME = 300

export const TimelineActionBar = ({ filters, setFilters }: Props) => {
  const { t } = useTranslation()
  const focusToday = useFocusToday()
  const { members } = useProject()
  const { useExternalStatuses, wrikeWorkflows } = useProject()
  const { externalStatusesEntries, externalStatusesMapped } = useCustomExternalStatuses(wrikeWorkflows)

  const statusesOptions = useMemo(() => {
    return useExternalStatuses ? externalStatusesMapped : allStatuses
  }, [externalStatusesMapped, useExternalStatuses])

  const setSearchDebounced = useDebounceFn((search: string) => {
    const searchQuery = search.trim().length >= SEARCH_LENGTH_THRESHOLD ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, SEARCH_DEBOUNCE_TIME)

  const setPartialFilters = (newFilter: Partial<TimelineFilters>) => {
    setFilters(filters => ({ ...filters, ...newFilter }))
  }

  const handleSetView = (viewMode: ViewMode) => {
    setFilters(filters => ({ ...filters, viewMode }))
  }

  const { viewMode, assignee, ...otherFilters } = filters

  return (
    <Flex align="center" gap={8}>
      <WppInput
        size="s"
        name="search"
        placeholder={t('dashboard.field_search_placeholder')!}
        onWppChange={e => setSearchDebounced(e.detail.value || '')}
        type="search"
        data-testid="timeline-search"
        className={styles.searchInput}
      />

      <TaskStatusFilter
        useExternalStatuses={useExternalStatuses}
        filters={filters}
        setFilters={filtersStateAction => {
          const { selectedStatuses } = filtersStateAction(filters)
          setPartialFilters({ selectedStatuses })
        }}
        statusesOptions={statusesOptions}
        externalStatusesEntries={externalStatusesEntries}
        wrikeWorkflowsLoading={!wrikeWorkflows}
      />

      <WppSelect
        size="s"
        placeholder="Select assignee"
        type="multiple"
        withFolder
        withSearch
        value={filters.assignee}
        onWppChange={e => setFilters(filters => ({ ...filters, assignee: e.detail.value }))}
      >
        {members.map((member: Members) => (
          <WppListItem key={member.id} value={member.email}>
            <p slot="label">{`${member.firstname} ${member.lastname}`}</p>
          </WppListItem>
        ))}
      </WppSelect>

      <WppFilterButton
        onClick={() =>
          showTasksFiltersModal({
            filters: otherFilters,
            onFiltersSave: ({ dueDateRanges }) => setPartialFilters({ dueDateRanges }),
          })
        }
        counter={filters.dueDateRanges?.length}
        data-testid="tasks-filter-button"
      >
        {t('project.files.btn_filter')!}
      </WppFilterButton>

      <TimelineChangeViewMode filters={filters} setViewMode={handleSetView} focusToday={focusToday} />
    </Flex>
  )
}
