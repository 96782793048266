import { MayBeNull } from '@wpp-open/core'
import { RawDraftContentState } from 'draft-js'

export interface RichText {
  plain: MayBeNull<string>
  formatted: MayBeNull<RawDraftContentState>
}

export function parseRichText(data: string): MayBeNull<RichText> {
  try {
    return JSON.parse(data) as RichText
  } catch (e) {
    console.error('Unable to parse rich text', e)
    return null
  }
}
