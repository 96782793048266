import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchProjectFilesApi } from 'api/projects/fetchers/fetchProjectFilesApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useProjectFilesInfiniteApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.PROJECTS_FILES_INFINITE,
  fetcher: fetchProjectFilesApi,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: lastPage => {
    return lastPage.data.paginator.totalPages === lastPage.data.paginator.page
      ? undefined
      : lastPage.data.paginator.page + 1
  },
})
