// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bQEvv{--wpp-typography-color: #000000;--wpp-typography-xs-body-color: #000000;position:absolute;z-index:999;background:rgba(238,232,255,.5);inset:8px 0 0;border-radius:8px;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}.GH9IL{margin-top:8px}.Ay8tb{position:fixed;top:50%;left:50%;z-index:999;transform:translate(-50%, -50%)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/lockOverlay/LockOverlay.module.scss"],"names":[],"mappings":"AAAA,OACE,+BAAA,CACA,uCAAA,CAEA,iBAAA,CACA,WAAA,CACA,+BAAA,CACA,aAAA,CACA,iBAAA,CACA,iCAAA,CAAA,yBAAA,CAGF,OACE,cAAA,CAGF,OACE,cAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,+BAAA","sourcesContent":[".lockOverlay {\n  --wpp-typography-color: #000000;\n  --wpp-typography-xs-body-color: #000000;\n\n  position: absolute;\n  z-index: 999;\n  background: rgb(238 232 255 / 50%);\n  inset: 8px 0 0;\n  border-radius: 8px;\n  backdrop-filter: blur(5px);\n}\n\n.userContainer {\n  margin-top: 8px;\n}\n\n.textCenter {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  z-index: 999;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lockOverlay": `bQEvv`,
	"userContainer": `GH9IL`,
	"textCenter": `Ay8tb`
};
export default ___CSS_LOADER_EXPORT___;
