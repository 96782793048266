import { WppDivider, WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { BackToTemplatesButton } from 'pages/components/backToTemplatesButton/BackToTemplatesButton'
import styles from 'pages/project/Project.module.scss'

export const TemplateSkeleton = () => {
  return (
    <Flex direction="column" className={styles.container}>
      <BackToTemplatesButton />
      <Flex gap={16} direction="column">
        <Flex direction="column" gap={8}>
          <Flex justify="between">
            <WppSkeleton variant="rectangle" height="40" width="430px " />
            <Flex gap={12}>
              <WppSkeleton variant="rectangle" height="40" width="40px " />
              <WppSkeleton variant="rectangle" height="40" width="90px " />
            </Flex>
          </Flex>

          <Flex>
            <WppSkeleton variant="rectangle" height="24" width="100px " />
          </Flex>

          <WppDivider className={styles.divider} />
        </Flex>
      </Flex>
    </Flex>
  )
}
