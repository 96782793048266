import { useEffect } from 'react'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { FluidTab } from 'pages/project/components/canvas/fluidCanvas/FluidTab'
import { LinearProvider } from 'pages/project/components/canvas/linearCanvas/LinearProvider'
import { LinearTab } from 'pages/project/components/canvas/linearCanvas/LinearTab'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { ProcessType } from 'types/projects/projects'

export const CanvasTab = () => {
  const { project } = useProject()
  const { trackAction } = useTrackAction()

  const { me } = useHasProjectRole()

  useEffect(() => {
    const analyticsData = {
      ...getCommonProjectAnalyticsData(project, me),
    }

    trackAction(ACTION_ANALYTICS.ACTION_VIEW_PROJECT_CANVAS, analyticsData)

    // exclude `project` data, as we should track only tab open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackAction])

  return project.processType === ProcessType.LINEAR ? (
    <LinearProvider>
      <LinearTab />
    </LinearProvider>
  ) : (
    <FluidTab />
  )
}
