import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { NativeAppType } from 'types/products/nativeApp'
import { ApplicationItem } from 'types/projects/workflow'
import { getEventTrackParams } from 'utils/getEventTrackParams'
import { simulateLinkClick } from 'utils/link'

interface Props {
  application: ApplicationItem
}

// TODO temporary fix, must be removed when https://jira.uhub.biz/browse/WPPLONOP-25517 will be resolved
export type OpenAppHandlerOverride = (
  appInstanceId: string,
  params?: {
    analytics?: {
      launchedFrom: string
    }
  },
) => void

export const useOpenApplication = ({ application }: Props) => {
  const {
    osApi: { navigation },
    osContext: { userDetails },
  } = useOs()

  const projectContext = useProject()
  const project = projectContext?.project
  const linearData = projectContext?.linearData

  const { trackAction } = useTrackAction()

  const { externalAppVersions, externalAppVersionId } = application

  const selectedVersion = useMemo(
    () => externalAppVersions?.find(version => version.id === externalAppVersionId),
    [externalAppVersionId, externalAppVersions],
  )

  const openApp = navigation.openApp as OpenAppHandlerOverride
  const appLaunchErrors = useAppLaunchErrors(application)

  const handleOpenApplication = useCallback(
    async (phaseId: MayBeNull<string | undefined>) => {
      const { name } = application

      if (!project) {
        console.warn(`Can't run "${name}" application, project is absent.`)
        return
      }

      const canRunApp = !appLaunchErrors.length
      if (!canRunApp) {
        console.warn(`Can't run "${name}" application, please check app configuration.`)
        return
      }

      if (application.type === NativeAppType.EXTERNAL_LINK) {
        // If application type is different than NativeAppType.NATIVE, then noCodeApp is true. Since this is definetly an External_Link we can pass true
        const data = getEventTrackParams({
          application,
          userDetails,
          isNoCodeApp: true,
          selectedVersion,
          project,
          phaseId,
          linearData,
        })

        trackAction(ACTION_ANALYTICS.APPLICATION_LAUNCH, data)

        simulateLinkClick({ href: application.externalAppVersions[0].config.url!, target: '_blank' })
        return
      }

      openApp(application.appInstanceId, { analytics: { launchedFrom: 'Project workflow' } })
    },
    [application, appLaunchErrors, openApp, project, linearData, selectedVersion, trackAction, userDetails],
  )

  return { handleOpenApplication }
}
