// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cVnyV{width:100%}.cVnyV::part(datepicker-container){width:100%}.zGbQh{color:var(--wpp-grey-color-800)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/editPhaseModal/EditPhaseModal.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAEA,mCACE,UAAA,CAIJ,OACE,+BAAA","sourcesContent":[".datePicker {\n  width: 100%;\n\n  &::part(datepicker-container) {\n    width: 100%;\n  }\n}\n\n.greyColor800 {\n  color: var(--wpp-grey-color-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePicker": `cVnyV`,
	"greyColor800": `zGbQh`
};
export default ___CSS_LOADER_EXPORT___;
