import { WppCard, WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/TasksStatusBoard.module.scss'
import { STATUS_MAP, STATUSES_ORDER } from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/utils'
import { DashboardProgressData } from 'types/projects/DashboardData'
import { TaskStatus } from 'types/projects/tasks'
import { routesManager } from 'utils/routesManager'

interface Props {
  phase: DashboardProgressData
}

export const ScaleChart = ({ phase }: Props) => {
  const { project } = useProject()
  const navigate = useNavigate()

  const sortedTasksByStatus = useMemo(
    () => phase.tasks.sort((a, b) => STATUSES_ORDER[a.status] - STATUSES_ORDER[b.status]),
    [phase],
  )

  const countTasksByStatus = useMemo(() => {
    const statusCounts: Record<TaskStatus, number> = {
      [TaskStatus.TO_DO]: 0,
      [TaskStatus.COMPLETED]: 0,
      [TaskStatus.IN_PROGRESS]: 0,
      [TaskStatus.ARCHIVED]: 0,
    }

    phase.tasks.forEach(task => {
      statusCounts[task.status]++
    })

    const { ARCHIVED, ...rest } = statusCounts

    return rest
  }, [phase])

  const toolTipText = `${STATUS_MAP[TaskStatus.COMPLETED]}: ${countTasksByStatus[TaskStatus.COMPLETED]}; ${
    STATUS_MAP[TaskStatus.IN_PROGRESS]
  }: ${countTasksByStatus[TaskStatus.IN_PROGRESS]}; ${STATUS_MAP[TaskStatus.TO_DO]}: ${
    countTasksByStatus[TaskStatus.TO_DO]
  }`

  return (
    <WppTooltip className={styles.tooltip} header={phase.name} text={toolTipText}>
      <WppCard
        data-testid="tasks-widget-card"
        variant="secondary"
        className={styles.cardContainer}
        onClick={() => navigate(routesManager.project.tasks.getURL({ id: project.id }))}
      >
        <WppTypography slot="header" type="s-strong" className={styles.phaseName}>
          {phase.name}
        </WppTypography>

        <Flex gap={sortedTasksByStatus.length > 20 ? 0 : 1} style={{ width: '100%' }}>
          {sortedTasksByStatus.map(task => (
            <div key={task.id} className={clsx(styles[task.status], styles.flex)} />
          ))}
        </Flex>
      </WppCard>
    </WppTooltip>
  )
}
