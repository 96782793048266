import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useOrderPinnedFilesApi } from 'api/attachments/mutation/useOrderPinnedFilesApi'
import { PaginatedResponse } from 'api/common/types'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { queryClient } from 'providers/osQueryClient/utils'
import { FilesDTO } from 'types/projects/Files'

export const useReorderPinFiles = ({ projectId }: { projectId: string }) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { mutateAsync: orderPinnedFiles } = useOrderPinnedFilesApi()

  const move = useCallback(
    async (id: string, currentIndex: number, direction: 'up' | 'down') => {
      const projectFilesQueryKey = [ApiQueryKeys.PROJECTS_FILES, { projectId, pinned: true }]
      await queryClient.cancelQueries(projectFilesQueryKey)

      const previousResponse = queryClient.getQueryData<{ data: PaginatedResponse<FilesDTO> }>(projectFilesQueryKey)
      const files = previousResponse?.data.data || []

      if (direction === 'up') {
        const swappedItem = files[currentIndex - 1]
        if (!swappedItem) {
          console.warn(`Swapped file is out of boundaries index ${currentIndex}, list length ${files.length}`)
          return
        }
        files[currentIndex - 1] = files[currentIndex]
        files[currentIndex] = swappedItem
      } else {
        const swappedItem = files[currentIndex + 1]
        if (!swappedItem) {
          console.warn(`Swapped file is out of boundaries index ${currentIndex}, list length ${files.length}`)
          return
        }
        files[currentIndex + 1] = files[currentIndex]
        files[currentIndex] = swappedItem
      }

      const newData = {
        ...previousResponse,
        data: {
          ...previousResponse!.data,
          data: files,
        },
      }
      queryClient.setQueryData(projectFilesQueryKey, newData)

      try {
        const filesOrder = files.map(({ id }, index) => ({ fileId: id, pinOrder: index }))
        await orderPinnedFiles({ projectId, filesOrder })
      } catch (e) {
        console.error(e)
        showToast({ type: 'error', message: t('common.generic_error')! })
      } finally {
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES])
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES_FETCHER])
      }
    },
    [orderPinnedFiles, projectId, showToast, t],
  )

  return { move }
}
