import { WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'

export const SideModalFooterSkeleton = () => (
  <Flex slot="actions" justify="end" gap={12}>
    <div />
    <Flex justify="end">
      <WppSkeleton variant="rectangle" height="40px" width="119px" />
    </Flex>
  </Flex>
)
