import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { NewConnectionProps } from 'api/canvas/fetchers/createFluidConnection'
import { useCreateFluidConnectionApi } from 'api/canvas/mutation/useCreateFluidConnectionApi'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { queryClient } from 'providers/osQueryClient/utils'
import { FluidWorkflow, IConnection } from 'types/projects/workflow'

export function useCreateFluidConnection({ projectId }: { projectId: string }) {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const projectContext = useProject()
  const { me } = useHasProjectRole()
  const { trackAction } = useTrackAction()
  const projectFluidQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }]

  const { mutateAsync: handleCreateConnection, isLoading } = useCreateFluidConnectionApi({
    onMutate: async newConnection => {
      await queryClient.cancelQueries(projectFluidQueryKey)

      const previousFluid = queryClient.getQueryData<{ data: FluidWorkflow }>(projectFluidQueryKey)
      const tempId = crypto.randomUUID()

      if (previousFluid) {
        const updatedData = {
          ...previousFluid.data,
          connections: [...previousFluid.data.connections, { ...newConnection, id: tempId }],
        }

        const newData = { ...previousFluid, data: updatedData }

        queryClient.setQueryData(projectFluidQueryKey, newData)
      }

      return { previousFluid, tempId }
    },
    onSuccess: ({ data }: AxiosResponse, _, context) => {
      const newConnection: IConnection = data

      // replace temp id with a real one immediately
      const previousFluid = queryClient.getQueryData<{ data: FluidWorkflow }>(projectFluidQueryKey)

      if (previousFluid) {
        const updatedData = {
          ...previousFluid.data,
          connections: previousFluid.data.connections.map(connection => {
            if (connection.id === context?.tempId) {
              return newConnection
            }

            return connection
          }),
        }

        const newData = { ...previousFluid, data: updatedData }

        queryClient.setQueryData(projectFluidQueryKey, newData)
      }
    },
    onError: (_error, _, context) => {
      if (context?.previousFluid) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }], context.previousFluid)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
    },
  })

  const createConnection = useCallback(
    async (connectionData: NewConnectionProps) => {
      const { sourceItemType, targetItemType, ...rest } = connectionData

      try {
        await handleCreateConnection(rest)

        if (projectContext) {
          const { project } = projectContext
          const analyticsData = {
            ...getCommonProjectAnalyticsData(project, me),
            source_item_type: sourceItemType,
            target_item_type: targetItemType,
          }

          trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_CONNECTION_ADDED, analyticsData)
        }
      } catch (error) {
        showToast({
          type: 'error',
          message: t('common.generic_error'),
        })
      }
    },
    [handleCreateConnection, me, projectContext, showToast, t, trackAction],
  )

  return { createConnection, isLoading }
}
