import 'gantt-task-react/dist/index.css'

import { WppTypography } from '@platform-ui-kit/components-library-react'
import { ViewMode } from 'gantt-task-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { TimelineChangeViewMode } from 'pages/project/components/timeline/components/timelineActionBar//TimelineChangeViewMode'
import { useFocusToday } from 'pages/project/components/timeline/components/useFocusToday'
import styles from 'pages/project/components/timeline/Timeline.module.scss'
import { ProjectFilter } from 'types/projects/projects'

interface Props {
  filters: ProjectFilter
  setViewMode: (viewMode: ViewMode) => void
}

export const ProjectsTimelineActionBar = ({ filters, setViewMode }: Props) => {
  const { t } = useTranslation()
  const focusToday = useFocusToday()

  return (
    <Flex align="center" gap={8} className={styles.projectsTimelineActionBarWrapper}>
      <WppTypography type="m-strong">{t('dashboard.projects_timeline_title')}</WppTypography>
      <TimelineChangeViewMode filters={filters} setViewMode={setViewMode} focusToday={focusToday} />
    </Flex>
  )
}
