import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'

import { useProject } from 'hooks/useProject'
import { NativeAppVersionDTO } from 'types/products/nativeApp'
import { AppIssueEnum } from 'types/projects/application'
import { ApplicationItem } from 'types/projects/workflow'
import { validateAppContext } from 'utils/validateAppContext'

export interface AppLaunchError {
  issue: AppIssueEnum
  appName: string
  missedCtx?: string[]
}

interface NativeAppData {
  id: string
  name: string
  versions: MayBeNull<NativeAppVersionDTO[]>
}

export const useNativeAppsErrors = (apps: NativeAppData[]) => {
  const {
    osContext: { tenant, navigationTree },
  } = useOs()
  const projectContext = useProject()

  // @TODO that's a temporary workaround. All project-coupled logic should be move out of common components
  const project = projectContext ? projectContext.project : null
  const contextParams = { project, tenant, navigationTree }

  return apps.reduce((acc, { id, name, versions }) => {
    acc[id] = (versions ?? [null]).flatMap(version => {
      const errors: AppLaunchError[] = []

      if (!version) {
        errors.push({
          issue: AppIssueEnum.NotPublished,
          appName: name,
        })
      }

      const ctxStatus = validateAppContext(contextParams, version)
      if (!ctxStatus.isValid) {
        errors.push({
          issue: AppIssueEnum.WrongContext,
          missedCtx: ctxStatus.missingLevels,
          appName: name,
        })
      }
      return errors
    })

    return acc
  }, {} as { [appId: string]: AppLaunchError[] })
}

export const useNativeAppErrors = (id: string, name: string, versions: NativeAppVersionDTO[]): AppLaunchError[] => {
  return useNativeAppsErrors([{ id, name, versions }])[id] ?? []
}

export const useAppLaunchErrors = (application: MayBeNull<ApplicationItem>): AppLaunchError[] => {
  const {
    osContext: { tenant, navigationTree },
  } = useOs()
  const projectContext = useProject()

  if (!application) {
    return []
  }

  // @TODO that's a temporary workaround. All project-coupled logic should be move out of common components
  const project = projectContext ? projectContext.project : null
  const contextParams = { project, tenant, navigationTree }

  const appName = application.name!
  const errors: AppLaunchError[] = []
  const { isDeleted, isAvailable } = application
  const isPublished = application.externalAppVersions?.length > 0
  const actualVersion = application.externalAppVersions?.find(({ id }) => id === application.externalAppVersionId)
  const ctxStatus = validateAppContext(contextParams, actualVersion)

  if (isDeleted) {
    errors.push({
      issue: AppIssueEnum.Deleted,
      appName,
    })
    return errors
  }

  if (!isPublished) {
    errors.push({
      issue: AppIssueEnum.NotPublished,
      appName,
    })
  }

  if (!isAvailable) {
    errors.push({
      issue: AppIssueEnum.NotAvailable,
      appName,
    })
  }

  if (!ctxStatus.isValid) {
    errors.push({
      issue: AppIssueEnum.WrongContext,
      missedCtx: ctxStatus.missingLevels,
      appName,
    })
  }

  if (!application.appInstanceId) {
    errors.push({
      issue: AppIssueEnum.NoInstance,
      appName,
    })
  }

  return errors
}
