import { useInfiniteQuery, UseInfiniteQueryOptions, InfiniteData } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMemo } from 'react'

import { QueryFetcher, QueryFetcherParams, QueryFetcherResponse } from 'api/common/types'

export function createUseInfiniteQuery<
  F extends QueryFetcher,
  S,
  P = QueryFetcherParams<F>,
  R = QueryFetcherResponse<F>,
>({
  queryKey,
  fetcher,
  selector,
  getNextPageParam,
}: {
  queryKey: string
  fetcher: F
  selector: (data?: InfiniteData<R>) => S
  getNextPageParam: (lastPage: R, allPages: R[]) => number | undefined
}) {
  return function useCustomInfiniteQuery(
    options: { params?: Omit<P, 'page'> } & Omit<
      UseInfiniteQueryOptions<R, AxiosError>,
      'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn' | 'select' | 'getPreviousPageParam' | 'getNextPageParam'
    > = {},
  ) {
    const { params = {}, enabled = true, ...queryOptions } = options

    const { data, isLoading, ...rest } = useInfiniteQuery<R, AxiosError>(
      [queryKey, params],
      ({ signal, pageParam = 1 }) => fetcher({ ...params, page: pageParam })(signal) as R,
      {
        enabled,
        getNextPageParam,
        ...queryOptions,
      },
    )

    return {
      ...rest,
      isLoading: enabled && isLoading,
      data: useMemo(() => selector(data), [data]),
      response: data,
    }
  }
}
