import clsx from 'clsx'
import { RefObject, useEffect, useRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import styles from 'pages/project/components/canvas/linearCanvas/components/stickySubheader/StickySubheader.module.scss'

interface Props {
  columns: { name: string; id: string }[]
  headerHidden: boolean
  containerRef: RefObject<HTMLDivElement>
  columnClass?: string
}

const preventWheelScroll = (e: WheelEvent) => e.preventDefault()

export const StickySubheader = ({ columns, headerHidden, containerRef, columnClass }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // container, that scroll will be listened on
    const containerScroll = containerRef?.current
    // sticky content that will be scrolled, only programmatically (instead of implementing two-way scroll watchers)
    const stickyContentScroll = scrollRef?.current

    const scrollHandler = () => {
      const scrollLeft = containerScroll?.scrollLeft || 0

      if (stickyContentScroll) {
        stickyContentScroll.scrollLeft = scrollLeft
      }
    }

    containerScroll?.addEventListener('scroll', scrollHandler)
    stickyContentScroll?.addEventListener('wheel', preventWheelScroll)

    return () => {
      containerScroll?.removeEventListener('scroll', scrollHandler)
      stickyContentScroll?.removeEventListener('wheel', preventWheelScroll)
    }
  }, [containerRef])

  return (
    <Flex className={clsx(styles.stickySubheader, { [styles.headerHidden]: headerHidden })}>
      <Flex className={styles.scrollWrapper} ref={scrollRef}>
        <Flex className={styles.contentWrapper}>
          {columns.map(({ id, name }) => {
            return (
              <Truncate
                className={clsx(styles.stickySubheaderColumn, columnClass)}
                lines={2}
                type="m-strong"
                data-testid="phase-name"
                key={id}
              >
                {name}
              </Truncate>
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}
