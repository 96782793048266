import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useProjectAttributesApi } from 'api/projects/queries/useProjectAttributesApi'
import { useToast } from 'hooks/useToast'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

const CACHE_TIME = 10 * 60 * 1000

export const withAttributes = <TProps extends NiceModalWrappedProps>(Component: FC<TProps>) => {
  return (props: TProps) => {
    const { t } = useTranslation()
    const { showToast } = useToast()
    const {
      data: attributes,
      isLoading: isSystemAttributesLoading,
      error,
    } = useProjectAttributesApi({ staleTime: CACHE_TIME })

    if (isSystemAttributesLoading) {
      return null
    }

    if (error) {
      console.error(error)
      showToast({ type: 'error', message: t('common.generic_error')! })
      return null
    }

    return <Component {...props} attributes={attributes} />
  }
}
