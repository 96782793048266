import { projectApi } from 'api'

export interface Props {
  containerId: string
  coordinateX: number
  coordinateY: number
}

export const patchFluidContainer = ({ containerId, ...body }: Props) =>
  projectApi.patch(`/fluid_containers/${containerId}`, body)
