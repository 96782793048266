import { colliderApi } from 'api'

export interface WrikeContact {
  id: string
  firstName: string
  lastName: string
  primaryEmail: string
}

export const fetchContactsMyApi = () => colliderApi.get<WrikeContact>('/wrike/api/contacts/my')
