import { createContext, PropsWithChildren } from 'react'

import { useProject } from 'hooks/useProject'
import { Members } from 'types/members/members'
import { ProcessType } from 'types/projects/projects'
import { isFluidTimeline, Timeline, TimelinePhase, TimelineTask } from 'types/projects/timeline'

export type TimelineAssignees = Members & { tasks: TimelineTask[] }

interface TimelineContextProps {
  processType: ProcessType
  timeline: Timeline
  allPhases: TimelinePhase[]
  allTasks: TimelineTask[]
  allAssignees: TimelineAssignees[]
}

export const TimelineContext = createContext<TimelineContextProps>(null!)

export const TimelineProvider = ({
  children,
  timeline,
  assignee,
}: PropsWithChildren<{
  timeline: Timeline
  assignee: string[]
}>) => {
  const {
    project: { processType, members },
  } = useProject()

  const allPhases = isFluidTimeline(timeline) ? [] : timeline.phases

  const allTasks = isFluidTimeline(timeline)
    ? timeline.tasks
    : [...timeline.phases.flatMap(phase => phase.tasks ?? []), ...timeline.externalTasks]

  const allAssignees = (members?.filter(member => assignee.includes(member.email)) || []).map<TimelineAssignees>(
    member => ({
      ...member,
      tasks: [
        ...((member as TimelineAssignees)?.tasks || []),
        ...allTasks.filter(task => task.assignee === member.email),
      ],
    }),
  )

  return (
    <TimelineContext.Provider
      value={{
        processType,
        timeline,
        allPhases,
        allTasks,
        allAssignees,
      }}
    >
      {children}
    </TimelineContext.Provider>
  )
}
