import { memo, useMemo } from 'react'

import { FluidEditableActivity } from 'pages/project/components/canvas/fluidCanvas/components/fluidActivity/FluidEditableActivity'
import { FluidPreviewActivity } from 'pages/project/components/canvas/fluidCanvas/components/fluidActivity/FluidPreviewActivity'
import { ActivityItem } from 'types/projects/workflow'

interface Props {
  activity: ActivityItem
  containerId: string
  childrenCount: number
  isEditable?: boolean
  preview?: boolean
  templateView?: boolean
}

export const FluidActivity = memo(
  ({ isEditable, containerId, preview, templateView, activity, childrenCount }: Props) => {
    const showAction = isEditable
    const isDisabled = !showAction || !!templateView
    const { externalLinks } = activity

    const sortedExternalLinksByNewest = useMemo(() => {
      return externalLinks?.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
    }, [externalLinks])

    return (
      <>
        {preview ? (
          <FluidPreviewActivity
            containerId={containerId}
            activityItem={activity}
            isDisabled={isDisabled}
            colId="fluid"
            sortedExternalLinks={sortedExternalLinksByNewest}
            childrenCount={childrenCount}
            templateView={templateView}
          />
        ) : (
          <FluidEditableActivity
            containerId={containerId}
            activityItem={activity}
            showAction={showAction!}
            isEditable={!!isEditable}
            isDisabled={isDisabled}
            childrenCount={childrenCount}
            variant="primary"
            sortedExternalLinks={sortedExternalLinksByNewest}
          />
        )}
      </>
    )
  },
)
