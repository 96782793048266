import {
  WppActionButton,
  WppIconExternalLink,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Task as GanttTask } from 'gantt-task-react'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ProjectStatusIcon } from 'pages/dashboard/components/projectsCardView/components/projectStatus/ProjectStatusIcon'
import { ProjectsTimelineContext } from 'pages/dashboard/components/projectsTimelineView/ProjectsTimelineProvider'
import { GanttTaskWithTenant } from 'pages/dashboard/components/projectsTimelineView/utils'
import styles from 'pages/project/components/timeline/components/taskListTable/TaskListTable.module.scss'
import { highlightRow, unHighlightRow } from 'pages/project/components/timeline/components/taskListTable/utils'
import { ProjectTimelinePhase } from 'types/projects/projectsTimeline'
import { routesManager } from 'utils/routesManager'

interface Props {
  rowHeight: number
  rowWidth: string
  fontFamily: string
  fontSize: string
  locale: string
  tasks: GanttTask[]
}

export const ProjectsListTable = ({ rowHeight, rowWidth, tasks: ganttTasks, fontFamily, fontSize }: Props) => {
  const { timeline } = useContext(ProjectsTimelineContext)
  const navigate = useNavigate()
  const { osContext } = useOs()
  const isMultiTenant = osContext.tenant.tenantType === TenantType.Agency

  const timelineTasks = useMemo(() => {
    const allProjects = Object.fromEntries(timeline.map(task => [task.id, task]))

    return allProjects
  }, [timeline])

  const handleRedirect = (project: GanttTask) => {
    const tenantProject = project as GanttTaskWithTenant
    const tenant = tenantProject.tenant
    const fromCurrentTenant = osContext.tenant.id === tenantProject.tenant?.id
    const redirectLink = `${
      isMultiTenant && !fromCurrentTenant ? `${tenant?.homeUrl}/orchestration` : ''
    }${routesManager.project.timeline.getURL({
      id: project.id,
    })}`
    if (fromCurrentTenant) {
      // Navigate within the current tenant (same tab)
      navigate(redirectLink)
    } else {
      // Open in a new tab
      window.open(redirectLink, '_blank')
    }
  }

  const isFromCurrentTenant = useCallback(
    (task: GanttTask) => {
      const tenantProject = task as GanttTaskWithTenant
      return osContext.tenant.id === tenantProject.tenant?.id
    },
    [osContext.tenant.id],
  )

  const combinePhaseNames = useCallback((phases: ProjectTimelinePhase[]) => {
    return phases.map(phase => phase.name).join(', ')
  }, [])

  return (
    <div
      className={styles.taskListWrapper}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {ganttTasks.map((task, idx) => {
        const timelineTask = timelineTasks[task.id]
        const isCurrentTenant = isFromCurrentTenant(task)

        if (!timelineTask) {
          return null
        }

        const node = (
          <div
            className={clsx(styles.cursorPointer, styles.taskListCell)}
            style={{
              minWidth: rowWidth,
              maxWidth: rowWidth,
            }}
            title={timelineTask.name}
            onClick={() => handleRedirect(task)}
          >
            <Flex gap={8} align="center" className={styles.widthWrapper}>
              <ProjectStatusIcon status={timelineTask!.status} />
              <Flex
                direction="column"
                className={clsx(styles.contentWrapper, {
                  [styles.contentWrapperWorkspace]: !isCurrentTenant,
                })}
              >
                <div>
                  <WppTypography type="s-body" className={clsx(styles.ellipsis, styles.name)}>
                    {timelineTask.name}
                  </WppTypography>
                </div>

                {!!timelineTask.phases.length && (
                  <WppTypography className={clsx(styles.ellipsis, styles.grey800)} type="xs-body">
                    {combinePhaseNames(timelineTask.phases)}
                  </WppTypography>
                )}
              </Flex>
              {isMultiTenant && !isCurrentTenant && (
                <Flex className={styles.externalButton}>
                  <WppTooltip text={timelineTask.tenant.name}>
                    <WppActionButton className={styles.icon}>
                      <WppIconExternalLink />
                    </WppActionButton>
                  </WppTooltip>
                </Flex>
              )}
            </Flex>
          </div>
        )

        return (
          <div
            className={clsx(styles.taskListTableRow)}
            style={{ height: rowHeight }}
            key={`${task.id}row`}
            onMouseEnter={() => highlightRow(idx)}
            onMouseLeave={() => unHighlightRow(idx)}
          >
            {node}
          </div>
        )
      })}
    </div>
  )
}
