// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ztT2v{box-sizing:border-box;height:41px;padding:10px 6px;border:1px solid var(--wpp-grey-color-600);border-radius:0 0 8px 8px}.nXRsT{width:inherit;color:inherit;text-decoration:underline;background-color:inherit;border-radius:inherit}.T3bKV::part(button){display:flex;justify-content:center;width:20px;height:20px;padding:0;color:var(--wpp-grey-color-600);border-radius:4px}.T3bKV.sISoh{color:var(--wpp-grey-color-800);background-color:var(--wpp-grey-color-300)}`, "",{"version":3,"sources":["webpack://./src/components/richText/editorToolbar/EditorToolbar.module.scss"],"names":[],"mappings":"AAAA,OACE,qBAAA,CACA,WAAA,CACA,gBAAA,CACA,0CAAA,CACA,yBAAA,CAGF,OACE,aAAA,CACA,aAAA,CACA,yBAAA,CACA,wBAAA,CACA,qBAAA,CAIA,qBACE,YAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,+BAAA,CACA,iBAAA,CAGF,aACE,+BAAA,CACA,0CAAA","sourcesContent":[".textEditorFooter {\n  box-sizing: border-box;\n  height: 41px;\n  padding: 10px 6px;\n  border: 1px solid var(--wpp-grey-color-600);\n  border-radius: 0 0 8px 8px;\n}\n\n.underline {\n  width: inherit;\n  color: inherit;\n  text-decoration: underline;\n  background-color: inherit;\n  border-radius: inherit;\n}\n\n.actionButton {\n  &::part(button) {\n    display: flex;\n    justify-content: center;\n    width: 20px;\n    height: 20px;\n    padding: 0;\n    color: var(--wpp-grey-color-600);\n    border-radius: 4px;\n  }\n\n  &.isSelected {\n    color: var(--wpp-grey-color-800);\n    background-color: var(--wpp-grey-color-300);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textEditorFooter": `ztT2v`,
	"underline": `nXRsT`,
	"actionButton": `T3bKV`,
	"isSelected": `sISoh`
};
export default ___CSS_LOADER_EXPORT___;
