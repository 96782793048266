import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { AttributeProps, getFieldName } from 'pages/components/projectModal/components/attributes/utils'

export function DateRangeAttribute({ attribute, formGroup }: AttributeProps) {
  const name = getFieldName(attribute, formGroup)
  return (
    <FormDatepicker
      name={name}
      data-testid={`modal-project-${attribute.contractName}`}
      labelConfig={{
        text: attribute.name,
        ...(attribute.iconText && {
          icon: 'wpp-icon-info',
          description: attribute.iconText,
        }),
      }}
      range
      required={attribute?.required ?? true}
      disabled={attribute?.enabled === false}
    />
  )
}
