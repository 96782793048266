// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A_6SZ{padding:12px;background-color:var(--wpp-grey-color-100);border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/pages/projectDemo/components/editAppPresentation/EditAppPresentationModal.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,0CAAA,CACA,iBAAA","sourcesContent":[".appInfo {\n  padding: 12px;\n  background-color: var(--wpp-grey-color-100);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appInfo": `A_6SZ`
};
export default ___CSS_LOADER_EXPORT___;
