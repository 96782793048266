import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useProjectActivityApi } from 'api/projects/queries/useProjectActivityApi'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ActivityDetailsMain } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/detailsModal/ActivityDetailsMain'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { DetailsModalType } from 'types/common/utils'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

export enum ActivityTabsTypes {
  apps = 'apps',
  resources = 'resources',
}

export const activityTabs = [
  { id: ActivityTabsTypes.apps, value: 'modals.activity_details.tabs.apps' },
  { id: ActivityTabsTypes.resources, value: 'modals.activity_details.tabs.resources' },
]

interface Props extends NiceModalWrappedProps {
  activityId: string
  phaseId?: string
  appId?: string
}

const ActivityDetailsModal = ({ activityId, onClose, appId, onCloseComplete, phaseId, isOpen }: Props) => {
  const { data: activity, isLoading, isError, error } = useProjectActivityApi({ params: { id: activityId } })

  const [, setSearchParams] = useSearchParams()

  const selectedModalTestId = useMemo(() => {
    if (activity?.items.find(app => app.application.id === appId)) return 'app-details-modal'
    return 'activity-details-modal'
  }, [activity?.items, appId])

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="m"
      data-testid={selectedModalTestId}
      withBackButton={!!activity && !!appId}
      onWppSideModalBackButtonClick={() =>
        setSearchParams({
          view: DetailsModalType.ACTIVITY_DETAILS_PREVIEW,
          ...(activity?.id && { activityId: activity?.id }),
          ...(phaseId && { phaseId }),
        })
      }
    >
      <ActivityDetailsMain
        activity={activity}
        onClose={onClose}
        isLoading={isLoading}
        isError={isError}
        error={error}
        phaseId={phaseId}
        appId={appId}
      />
    </SideModal>
  )
}

export const { showModal: showActivityDetailsModal, useModal: useShowActivityDetailsModal } = createProjectModal<Props>(
  ActivityDetailsModal,
  'activity-details-modal',
)
