import { useEffect } from 'react'

import { useStableCallback } from 'hooks/useStableCallback'
import { DashboardSkeleton } from 'pages/dashboard/Dashboard'

const postMessage = (message: any) => {
  window.opener.postMessage(message, window.location.origin)
  window.close()
}

export const MiroAuthCallback = () => {
  const handleRedirect = useStableCallback(() => {
    postMessage({ error: false, miro: true })
  })

  useEffect(() => {
    handleRedirect()
  }, [handleRedirect])

  return <DashboardSkeleton />
}
