import { WppCardGroup } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, ReactNode, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppCardGroup>, 'onChange' | 'onWppChange' | 'value'> {
  name: string
  children: ReactNode
}

export const FormRadioGroup = forwardRef<HTMLWppCardGroupElement, Props>(
  ({ name, children, onWppBlur, ...rest }, ref) => {
    const innerRef = useRef<HTMLWppCardGroupElement>(null)

    const {
      field: { value, onChange, onBlur },
    } = useField({ name })

    return (
      <WppCardGroup
        {...rest}
        ref={mergeRefs([innerRef, ref])}
        name={name}
        value={value}
        onWppChange={event => onChange(event.detail.value)}
        onWppBlur={e => {
          onBlur()
          onWppBlur?.(e)
        }}
      >
        {children}
      </WppCardGroup>
    )
  },
)
