export const defaultTaxonomy = {
  app: 'app',
  App: 'App',
  apps: 'apps',
  Apps: 'Apps',
  application: 'application',
  Application: 'Application',
  applications: 'applications',
  Applications: 'Applications',
  project: 'project',
  Project: 'Project',
  projects: 'projects',
  Projects: 'Projects',
  client: 'client',
  Client: 'Client',
  clients: 'clients',
  Clients: 'Clients',
  brand: 'brand',
  Brand: 'Brand',
  brands: 'brands',
  Brands: 'Brands',
} as const
