// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YhAPb{box-sizing:content-box;width:4px;padding-top:1px;background:var(--wpp-grey-color-400);border-radius:1px}`, "",{"version":3,"sources":["webpack://./src/pages/components/phasesIndicator/PhasesIndicator.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CACA,SAAA,CACA,eAAA,CACA,oCAAA,CACA,iBAAA","sourcesContent":[".phase {\n  box-sizing: content-box;\n  width: 4px;\n  padding-top: 1px;\n  background: var(--wpp-grey-color-400);\n  border-radius: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phase": `YhAPb`
};
export default ___CSS_LOADER_EXPORT___;
