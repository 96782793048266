import { facadeApi } from 'api'
import { WrikeRedirectPlatform } from 'api/wrike/fetchers/fetchAuthLinkApi'
import { getMiroUrl } from 'utils/miro'

interface Params {
  clientId?: string
  teamId?: string
  state?: string
}

interface MiroAuthLinkResponse {
  url: string
}

export const fetchMiroAuthLinkApi = ({ clientId, teamId, state }: Params) =>
  facadeApi.get<MiroAuthLinkResponse>('/miro/auth/link', {
    params: {
      clientId,
      teamId,
      state,
      redirectUri: getMiroUrl(),
      platform: WrikeRedirectPlatform.Orchestration,
    },
  })
