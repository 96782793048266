// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AX_ga{margin-left:auto}.p7f2u{color:var(--wpp-grey-color-700)}.DC0GD{visibility:hidden}.DC0GD::slotted(button){display:flex;justify-content:center;width:32px;height:32px}.z_AaE{padding:8px 10px;border-radius:5px;cursor:pointer}.z_AaE:hover{background:var(--wpp-grey-color-200)}.z_AaE:hover .DC0GD{visibility:visible}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/projectDashboard/coponents/projectIntegration/ProjectIntegration.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CAGF,OACE,+BAAA,CAGF,OACE,iBAAA,CAEA,wBACE,YAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CAIJ,OACE,gBAAA,CACA,iBAAA,CACA,cAAA,CAEA,aACE,oCAAA,CAEA,oBACE,kBAAA","sourcesContent":[".icon {\n  margin-left: auto;\n}\n\n.subTitle {\n  color: var(--wpp-grey-color-700);\n}\n\n.menuButton {\n  visibility: hidden;\n\n  &::slotted(button) {\n    display: flex;\n    justify-content: center;\n    width: 32px;\n    height: 32px;\n  }\n}\n\n.item {\n  padding: 8px 10px;\n  border-radius: 5px;\n  cursor: pointer;\n\n  &:hover {\n    background: var(--wpp-grey-color-200);\n\n    .menuButton {\n      visibility: visible;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `AX_ga`,
	"subTitle": `p7f2u`,
	"menuButton": `DC0GD`,
	"item": `z_AaE`
};
export default ___CSS_LOADER_EXPORT___;
