import { useEffect, RefObject, useState } from 'react'
import useMeasureDirty from 'react-use/lib/useMeasureDirty'
import { useStoreApi } from 'reactflow'

export const useUpdateAppMargin = (
  containerId: string,
  activityCardRef: RefObject<any>,
  appsStarRef: RefObject<any>,
) => {
  const cardRect = useMeasureDirty(activityCardRef)
  const store = useStoreApi()
  const [innerAppsMargin, setInnerAppsMargin] = useState(0)

  useEffect(() => {
    const activitiesStart = activityCardRef.current?.getBoundingClientRect().top ?? 0
    const appsStart = appsStarRef.current?.getBoundingClientRect().top ?? 0
    const zoom = store.getState().transform[2]
    const appsMargin = Math.round((appsStart - activitiesStart) / zoom)
    setInnerAppsMargin(appsMargin)
  }, [containerId, cardRect, activityCardRef, appsStarRef, store])

  useEffect(() => {
    document.body.style.setProperty(`--inner-start-${containerId}`, `${innerAppsMargin}px`)
    const edges = store.getState().edges
    store.getState().setEdges(
      edges.map(edge => {
        return { ...edge, data: { ...edge.data } }
      }),
    )

    return () => {
      document.body.style.removeProperty(`--inner-start-${containerId}`)
    }
  }, [containerId, innerAppsMargin, store])
}
