import { WppAvatar } from '@platform-ui-kit/components-library-react'
import { forwardRef, ComponentPropsWithoutRef, useMemo } from 'react'

type Props = ComponentPropsWithoutRef<typeof WppAvatar>

export const AVATAR_COLORS_VARIANTS = [
  'var(--wpp-dataviz-color-cat-neutral-1)',
  'var(--wpp-dataviz-color-cat-neutral-2)',
  'var(--wpp-dataviz-color-cat-neutral-3)',
  'var(--wpp-dataviz-color-cat-neutral-4)',
  'var(--wpp-dataviz-color-cat-neutral-5)',
  'var(--wpp-dataviz-color-cat-neutral-6)',
  'var(--wpp-dataviz-color-cat-neutral-7)',
  'var(--wpp-dataviz-color-cat-neutral-8)',
  'var(--wpp-dataviz-color-cat-neutral-9)',
]

export const getAvatarColor = ({ name = '' }: { name?: string }) => {
  const charCode = name?.toLowerCase().charCodeAt(0)
  // Valid colors for avatars: theme.color.dataviz.cat.neutral-[1-9]
  const colorIndex = isNaN(charCode) ? 1 : charCode % AVATAR_COLORS_VARIANTS.length
  return AVATAR_COLORS_VARIANTS[colorIndex]
}

export const Avatar = forwardRef<HTMLWppAvatarElement, Props>(function Avatar({ name, ...rest }, ref) {
  const color = useMemo(() => getAvatarColor({ name }), [name])

  return <WppAvatar ref={ref} name={name} color={color} {...rest} />
})
