import styles from 'components/svg/common.module.scss'

export const SequenceArrowSvg = (props: JSX.IntrinsicElements['svg']) => (
  <svg {...props} width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.50001C0.723857 3.50001 0.5 3.72387 0.5 4.00001C0.5 4.27615 0.723858 4.50001 1 4.50001L1 3.50001ZM21.3536 4.35355C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464467C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35355ZM1 4.50001L21 4.5L21 3.5L1 3.50001L1 4.50001Z"
      className={styles.fillBrand500}
    />
  </svg>
)
