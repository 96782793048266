import { TenantType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { ViewMode, Gantt, Task as GanttTask } from 'gantt-task-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ProjectTimelineHeader } from 'pages/dashboard/components/projectsTimelineView/projectListHeader/ProjectTimelineHeader'
import { ProjectsListTable } from 'pages/dashboard/components/projectsTimelineView/projectListTable/ProjectsListTable'
import { ProjectTooltipContent } from 'pages/dashboard/components/projectsTimelineView/projectTooltipContent/ProjectTooltipContent'
import { GanttTaskWithTenant } from 'pages/dashboard/components/projectsTimelineView/utils'
import styles from 'pages/project/components/timeline/components/TimelineChart.module.scss'
import { useFocusToday, useShiftToday } from 'pages/project/components/timeline/components/useFocusToday'
import 'gantt-task-react/dist/index.css'
import { routesManager } from 'utils/routesManager'

interface Props {
  projects: GanttTaskWithTenant[]
  viewMode: ViewMode
}

const DAY_COLUMN_WIDTH = 72
const MONTH_COLUMN_WIDTH = 253

export const ProjectsTimelineChart = ({ projects, viewMode }: Props) => {
  const focusToday = useFocusToday()
  const { osContext } = useOs()
  const navigate = useNavigate()

  const isMultiTenant = osContext.tenant.tenantType === TenantType.Agency

  useEffect(() => {
    focusToday()
  }, [focusToday, viewMode])

  const { refCallback } = useShiftToday(MONTH_COLUMN_WIDTH)

  const handleClick = (project: GanttTask) => {
    const tenantProject = project as GanttTaskWithTenant
    const tenant = tenantProject.tenant
    const fromCurrentTenant = osContext.tenant.id === tenantProject.tenant?.id
    const redirectLink = `${
      isMultiTenant && !fromCurrentTenant ? `${tenant?.homeUrl}/orchestration` : ''
    }${routesManager.project.timeline.getURL({
      id: project.id,
    })}`
    if (fromCurrentTenant) {
      // Navigate within the current tenant (same tab)
      navigate(redirectLink)
    } else {
      // Open in a new tab
      window.open(redirectLink, '_blank')
    }
  }

  return (
    <div className={clsx(styles.ganttWrapper, styles.noMarginTop, 'ganttWrapper', styles[viewMode])} ref={refCallback}>
      <Gantt
        tasks={projects}
        viewMode={viewMode}
        barCornerRadius={6}
        listCellWidth="284px"
        columnWidth={viewMode === ViewMode.Day ? DAY_COLUMN_WIDTH : MONTH_COLUMN_WIDTH}
        rowHeight={52}
        TooltipContent={ProjectTooltipContent}
        TaskListHeader={ProjectTimelineHeader}
        TaskListTable={ProjectsListTable}
        onClick={handleClick}
        ganttHeight={window.innerHeight - 380}
      />
    </div>
  )
}
