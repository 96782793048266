import { useOs } from '@wpp-open/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { colliderApi, facadeApi, masterDataApi, projectApi, userDetailsApi } from 'api'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import config, { API_TYPES } from 'constants/app-config'
import { queryClient } from 'providers/osQueryClient/utils'
import { is401Error, is422Error } from 'utils/error'

const apiInstances = [projectApi, facadeApi, userDetailsApi, masterDataApi, colliderApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const {
    osApi,
    osContext: { tenant },
  } = useOs()

  useEffect(() => {
    const requestInterceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        instanceConfig => {
          const bearer = osApi.getAccessToken()
          if (bearer) {
            instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
          }

          if (
            instance.client.defaults.baseURL === config[API_TYPES.WPP_PROJECTS_API] ||
            instance.client.defaults.baseURL === config[API_TYPES.WPP_COLLIDER_API]
          ) {
            instanceConfig!.headers!['X-Tenant-Id'] = tenant.id
          }

          return instanceConfig
        },
        error => Promise.reject(error),
      ),
    )

    const responseInterceptors = apiInstances.map(instance =>
      instance.client.interceptors.response.use(
        response => response,
        error => {
          if (instance.client.defaults.baseURL === config[API_TYPES.WPP_COLLIDER_API]) {
            const isAuthError = is401Error(error)
            const isWrongTokenError =
              is422Error(error) && error?.response?.data?.detail === 'Wrike authentication is missing'

            if (isAuthError || isWrongTokenError) {
              queryClient.setQueryData([ApiQueryKeys.WRIKE_ME, {}], null)
            }
          }

          return Promise.reject(error)
        },
      ),
    )

    setIsReady(true)

    return () => {
      requestInterceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })

      responseInterceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.response.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken])

  return <>{isReady && children}</>
}
