import {
  WppButton,
  WppIconApp,
  WppIconPair,
  WppIconPlus,
  WppListItem,
  WppMenuContext,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { ReactFlowProvider } from 'reactflow'

import { Flex } from 'components/common/flex/Flex'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useTemplate } from 'hooks/useTemplate'
import styles from 'pages/project/components/canvas/Canvas.module.scss'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { FluidSkeleton } from 'pages/project/components/canvas/components/canvasSkeleton/FluidSkeleton'
import FluidCanvas from 'pages/project/components/canvas/fluidCanvas/FluidCanvas'
import { showAddEditActivityModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AddEditActivityModal'
import { AppPermissions } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'

export const FluidTemplate = () => {
  const { template, fluidData, isFluidLoading, isOwner } = useTemplate()
  const { t } = useTranslation()

  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE) || isOwner

  const openAppPickerModal = async () => {
    showAppPickerModal({
      projectId: template.id,
      selectedCanvas: template.processType,
      isTemplate: true,
    })
  }

  const addActivityModal = () => {
    showAddEditActivityModal({
      projectId: template.id,
      selectedCanvas: ProcessType.FLUID,
      isTemplate: true,
    })
  }

  return (
    <>
      <Flex direction="column" className={styles.bodyFluid}>
        <Flex justify="end">
          {isFluidLoading ? (
            <WppSkeleton variant="rectangle" height="32px" width="132px" />
          ) : (
            <>
              {isOwnerOrGlobalManage && (
                <Flex gap={12}>
                  <WppMenuContext data-testid="context-add" className={styles.fluidCtxMenu}>
                    <WppButton size="s" slot="trigger-element" data-testid="add-item-button">
                      <WppIconPlus slot="icon-start" />
                      {t('project.canvas.btn_add_item')}
                    </WppButton>
                    <div>
                      <WppListItem onWppChangeListItem={() => openAppPickerModal()} data-testid="context-add-app">
                        <WppIconApp slot="left" />
                        <p slot="label">{t('project.canvas.application')}</p>
                      </WppListItem>
                      <WppListItem onWppChangeListItem={addActivityModal} data-testid="context-add-activity">
                        <WppIconPair slot="left" />
                        <p slot="label">{t('project.canvas.activity')}</p>
                      </WppListItem>
                    </div>
                  </WppMenuContext>
                </Flex>
              )}
            </>
          )}
        </Flex>
        {isFluidLoading ? (
          <FluidSkeleton />
        ) : (
          <>
            {fluidData && (
              <ReactFlowProvider>
                <FluidCanvas projectId={template.id} fluidData={fluidData} />
              </ReactFlowProvider>
            )}
          </>
        )}
      </Flex>
    </>
  )
}
