import {
  WppActionButton,
  WppCard,
  WppIconFile,
  WppIconMore,
  WppIconRemoveCircle,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useDeleteActivityFiles } from 'api/canvas/mutation/useDeleteActivityFiles'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { Truncate } from 'components/common/truncate/Truncate'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useIsPermitted } from 'hooks/useIsPermitted'
import styles from 'pages/project/components/canvas/components/attachedFile/AttachedFile.module.scss'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { showFileDeleteModal } from 'pages/project/components/files/components/fileDeleteModal/FileDeleteModal'
import { useDownloadProjectFile } from 'pages/project/hooks/useDownloadProjectFile'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { queryClient } from 'providers/osQueryClient/utils'
import { DetailsModalType } from 'types/common/utils'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ActivityFiles } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'
import { getFileIcon } from 'utils/getFileIcon'

interface Props {
  file: ActivityFiles
  activityId: string
  showAction?: boolean
  isDisabled?: boolean
  preview?: boolean
  isInFluid?: boolean
  isInactive?: boolean
  fileAction?: boolean
  variant?: 'primary' | 'secondary'
}

export const AttachedFile = memo(
  ({ file, activityId, preview, isDisabled, showAction, isInactive, isInFluid, fileAction, variant }: Props) => {
    const { t } = useTranslation()
    const [, setParams] = useSearchParams()
    const { downloadFileAction } = useDownloadProjectFile()

    const { isPermitted } = useIsPermitted()
    const { hasRole } = useHasProjectRole()

    const { osContext } = useOs()

    const { userDetails } = osContext
    const canDelete = useMemo(
      () =>
        isEqualEmails(file.createdByEmail, userDetails.email) ||
        hasRole([ProjectRole.OWNER]) ||
        isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE),

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hasRole, isPermitted],
    )

    const { mutateAsync: removeFile } = useDeleteActivityFiles()
    const handleRemoveFile = useCallback(async () => {
      try {
        await removeFile({ activityId, fileId: file.id })
        invalidateCanvas()
        queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES_FETCHER])
        tableActions.reload([TableKey.PROJECT_FILE_LIST])
      } catch (e) {
        console.error(e)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openPreview = useCallback(
      () => setParams({ view: DetailsModalType.FILE_DETAILS_PREVIEW, id: file.id }),
      [file.id, setParams],
    )
    const downloadFile = useCallback(() => downloadFileAction(file.key), [downloadFileAction, file.key])

    const handleDeleteFile = useCallback(() => showFileDeleteModal({ fileId: file.id, isInFluid }), [file, isInFluid])

    const Icon = useMemo(() => getFileIcon(file.name), [file.name])

    return (
      <WppCard
        size="s"
        className={clsx(styles.fileCard, { [styles.previewCard]: preview })}
        data-testid="activity-file"
        variant={variant || 'primary'}
      >
        <Flex gap={8} align="center">
          {Icon}
          <Truncate
            lines={1}
            type="s-body"
            className={clsx(styles.fileName, {
              [styles.preview]: !preview,
              [styles.disabled]: isDisabled && !fileAction,
            })}
            onClick={fileAction ? downloadFile : openPreview}
            data-testid="attached-file-name"
          >
            {file.name}
          </Truncate>
          {!preview && showAction && !isInactive && (
            <div className={styles.externalLinkMenu}>
              <WppMenuContext dropdownConfig={{ appendTo: () => document.body }}>
                <WppActionButton slot="trigger-element" variant="secondary">
                  <WppIconMore slot="icon-start" direction="horizontal" />
                </WppActionButton>

                <WppListItem onWppChangeListItem={openPreview} data-testid="file-details-action">
                  <WppIconFile slot="left" />
                  <WppTypography slot="label" type="s-body">
                    {t('project.canvas.file_details')}
                  </WppTypography>
                </WppListItem>

                {/* DO_NOT_DELETE: ternary operator coz error: Can't remove ReactNode. More like Tippy issue, remove node firstly */}
                <div style={{ display: isInactive ? 'none' : 'block' }}>
                  <WppListItem onWppChangeListItem={handleRemoveFile} data-testid="remove-file-action">
                    <WppIconRemoveCircle slot="left" />
                    <WppTypography slot="label" type="s-body">
                      {t('project.canvas.remove_from_activity')}
                    </WppTypography>
                  </WppListItem>
                  {canDelete && (
                    <WppListItem onWppChangeListItem={handleDeleteFile} data-testid="delete-file-action">
                      <WppIconTrash slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('project.canvas.delete')}
                      </WppTypography>
                    </WppListItem>
                  )}
                </div>
              </WppMenuContext>
            </div>
          )}
        </Flex>
      </WppCard>
    )
  },
)
