import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/workspaceWarning/WorkspaceWarning.module.scss'
import { ContextHierarchy } from 'types/projects/projects'

export const WorkspaceWarning = ({
  contextHierarchy,
  horizontal,
}: {
  contextHierarchy: ContextHierarchy[]
  horizontal?: boolean
}) => {
  return (
    !!contextHierarchy?.length && (
      <Flex direction={horizontal ? 'row' : 'column'} gap={horizontal ? 48 : 2}>
        {contextHierarchy.map(el => (
          <Flex key={el.value} direction={horizontal ? 'column' : 'row'}>
            <WppTypography className={styles.title} type="s-body">
              {el.title}: &nbsp;
            </WppTypography>
            <WppTypography className={styles.fontColor} type="s-strong">
              {el.name || '-'}
            </WppTypography>
          </Flex>
        ))}
      </Flex>
    )
  )
}
