import { useRef } from 'react'

export function useLatestDistinct<T>(value: T, comparator: (prev: T, curr: T) => boolean = (a, b) => a === b) {
  const ref = useRef<T>(value)

  if (!comparator(ref.current, value)) {
    ref.current = value
  }

  return ref.current
}
