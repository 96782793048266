import { useLocation } from 'react-router-dom'

function useEventSource() {
  const { pathname } = useLocation()
  const eventSource = pathname.split('/').pop()

  return eventSource
}

export default useEventSource
