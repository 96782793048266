import { isAxiosError } from 'axios'

import { canvasLockApi } from 'api/canvas/fetchers/canvasLockApi'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useCanvasLockApi = createUseQuery({
  queryKey: ApiQueryKeys.PROJECT_CANVAS_LOCK,
  fetcher: canvasLockApi,
  selector: res => {
    if (isAxiosError(res)) {
      return null
    }

    return res?.data
  },
})
