import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { SelectChangeEventDetail } from '@platform-ui-kit/components-library/dist/types/components/wpp-select/types'
import { WppListItem, WppSelect, WppSpinner } from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchTenantsApi } from 'api/tenant/queries/useFetchTenantsApi'
import styles from 'pages/dashboard/components/tenantFilterSelect/TenantFilterSelect.module.scss'

interface Props {
  tenantIds: string[]
  onChange: (event: WppSelectCustomEvent<SelectChangeEventDetail>) => void
}

export const TenantFilterSelect = ({ tenantIds, onChange }: Props) => {
  const { t } = useTranslation()
  const {
    osContext: { tenant },
  } = useOs()

  const isAgencyWorkspace = tenant.tenantType === TenantType.Agency

  const { data: clientTenants, isLoading: isTenantsLoading } = useFetchTenantsApi({
    enabled: isAgencyWorkspace,
    params: {
      tenantType: TenantType.Client,
    },
  })

  //  We need filter tenants by type==client AND current tenant.
  const tenantsOptions = useMemo(() => {
    return [tenant, ...clientTenants?.sort((a, b) => a.name.localeCompare(b.name))]
  }, [tenant, clientTenants])

  if (!isAgencyWorkspace) {
    return null
  }

  return (
    <WppSelect
      name="tenant"
      value={tenantIds}
      size="s"
      type="multiple"
      data-testid="tenant-select"
      placeholder={t('modals.dashboard_filters.field_tenant_placeholder')!}
      locales={{
        allSelectedText: t('tasksDashboard.all_workspaces_selected'),
        clearAllText: t('common.clear_all'),
        selectAllText: t('common.select_all'),
        selectLabel: t('common.selected_label'),
      }}
      withFolder
      withSearch
      required
      onWppChange={onChange}
    >
      {isTenantsLoading ? (
        <WppSpinner className={styles.selectLoader} />
      ) : (
        tenantsOptions?.map(tenant => (
          <WppListItem key={tenant.id} value={tenant.id}>
            <span slot="label">{tenant.name}</span>
          </WppListItem>
        ))
      )}
    </WppSelect>
  )
}
