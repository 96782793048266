import styles from 'components/svg/common.module.scss'

export const FluidTemplateSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="11" y="7" width="7" height="5" rx="1" className={styles.fillGrey400} />
    <rect x="2" y="13" width="7" height="5" rx="1" className={styles.fillGrey400} />
    <rect x="2" y="2" width="7" height="5" rx="1" className={styles.fillGrey400} />
    <rect x="5" y="4" width="10" height="12" rx="2" className={styles.strokeGrey400} strokeWidth="1.25" />
  </svg>
)
