interface getURLParams {
  id: string | number
  selectedAppId?: string | number
  selectedPhaseId?: string | number
}

export const routesManager = {
  projects: {
    root: {
      pattern: '/projects',
      shortPattern: 'projects',
      getURL: () => '/projects',
    },
    cards: {
      pattern: '/projects/cards',
      shortPattern: 'cards',
      getURL: () => '/projects/cards',
    },
    table: {
      pattern: '/projects/table',
      shortPattern: 'table',
      getURL: () => '/projects/table',
    },
    timeline: {
      pattern: '/projects/timeline',
      shortPattern: 'timeline',
      getURL: () => '/projects/timeline',
    },
  },
  project: {
    root: {
      pattern: '/project/:projectId',
      getURL: ({ id }: getURLParams) => `/project/${id}`,
    },
    overview: {
      pattern: '/project/:projectId/overview',
      shortPattern: 'overview',
      getURL: ({ id }: getURLParams) => `/project/${id}/overview`,
    },
    workflow: {
      pattern: '/project/:projectId/workflow',
      shortPattern: 'workflow',
      getURL: ({ id }: getURLParams) => `/project/${id}/workflow`,
    },
    canvas: {
      pattern: '/project/:projectId/canvas',
      shortPattern: 'canvas',
      getURL: ({ id }: getURLParams) => `/project/${id}/canvas`,
    },
    members: {
      pattern: '/project/:projectId/members',
      shortPattern: 'members',
      getURL: ({ id }: getURLParams) => `/project/${id}/members`,
    },
    files: {
      pattern: '/project/:projectId/files',
      shortPattern: 'files',
      getURL: ({ id }: getURLParams) => `/project/${id}/files`,
    },
    tasks: {
      pattern: '/project/:projectId/tasks',
      shortPattern: 'tasks',
      getURL: ({ id }: getURLParams) => `/project/${id}/tasks`,
    },
    timeline: {
      pattern: '/project/:projectId/timeline',
      shortPattern: 'timeline',
      getURL: ({ id }: getURLParams) => `/project/${id}/timeline`,
    },

    // separate page, not a tab
    demo: {
      pattern: '/project/:projectId/demo',
      shortPattern: 'demo',
      getURL: ({ id, selectedAppId = '', selectedPhaseId }: getURLParams) => {
        let url = `/project/${id}/demo`

        if (selectedAppId) {
          url += `?selectedAppId=${selectedAppId}`
        }

        if (selectedPhaseId) {
          url += selectedAppId ? `&selectedPhaseId=${selectedPhaseId}` : `?selectedPhaseId=${selectedPhaseId}`
        }

        return url
      },
    },
  },
  miroAuthCallback: {
    pattern: '/miro-auth-callback',
    getURL: () => '/miro-auth-callback',
  },
  templates: {
    root: {
      pattern: '/templates/:templateId?',
      shortPattern: 'templates',
      getURL: () => '/templates',
    },
  },
  tasks: {
    root: {
      pattern: '/tasks',
      shortPattern: 'tasks',
      getURL: () => '/tasks',
    },
  },
  settings: {
    root: {
      pattern: '/settings',
      shortPattern: 'settings',
      getURL: () => '/settings',
    },
    integrations: {
      pattern: '/settings/integrations',
      shortPattern: 'integrations',
      getURL: () => '/settings/integrations',
    },
    attributes: {
      pattern: '/settings/attributes',
      shortPattern: 'attributes',
      getURL: () => '/settings/attributes',
    },
  },
  '404': {
    root: {
      pattern: '/404',
      getURL: () => '/404',
    },
  },
  template: {
    root: {
      pattern: '/template/:templateId',
      getURL: ({ id }: getURLParams) => `/template/${id}`,
    },
  },
}
