import {
  WppActionButton,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import { ViewMode } from 'gantt-task-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import 'gantt-task-react/dist/index.css'
import styles from 'pages/project/components/timeline/components/timelineActionBar/TimelineActionBar.module.scss'
import { TimelineFilters } from 'pages/project/components/timeline/utils'
import { ProjectFilter } from 'types/projects/projects'

interface Props {
  filters: TimelineFilters | ProjectFilter
  setViewMode: (viewMode: ViewMode) => void
  focusToday: () => void
}

export const TimelineChangeViewMode = ({ setViewMode, filters, focusToday }: Props) => {
  const { t } = useTranslation()

  return (
    <Flex className={styles.rightPart} gap={12}>
      <WppActionButton data-testid="focus-today-button" onClick={focusToday}>
        {t('timeline.today')}
      </WppActionButton>
      <WppSegmentedControl
        value={filters.viewMode}
        onWppChange={e => {
          setViewMode(e.target.value as ViewMode)
        }}
        size="s"
      >
        <WppSegmentedControlItem data-testid="viewMode-day" value={ViewMode.Day}>
          {t('timeline.viewMode.day')}
        </WppSegmentedControlItem>
        <WppSegmentedControlItem data-testid="viewMode-week" value={ViewMode.Week}>
          {t('timeline.viewMode.week')}
        </WppSegmentedControlItem>
        <WppSegmentedControlItem data-testid="viewMode-month" value={ViewMode.Month}>
          {t('timeline.viewMode.month')}
        </WppSegmentedControlItem>
      </WppSegmentedControl>
    </Flex>
  )
}
