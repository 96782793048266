import { TaskStatus } from 'types/projects/tasks'

export enum DashboardOwnership {
  MY = 'MY',
  ALL = 'ALL',
}

export interface DashboardProgressData {
  id: string
  name: string
  description: string
  tasks: PhaseTaskProgress[]
}

export interface PhaseTaskProgress {
  id: string
  targetType: string
  status: Exclude<TaskStatus, TaskStatus.ARCHIVED>
}

export interface DashboardWorkflow {
  phases: DashboardWorkflowItem[]
}

export interface DashboardWorkflowItem {
  name: string
  description: string
  id: string
}
