import { WppCard, WppEmptyDataviz, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TimelineParams } from 'api/projects/fetchers/fetchProjectTimelineApi'
import { useProjectTimelineApi } from 'api/projects/queries/useProjectTimelineApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { useCustomExternalStatuses } from 'pages/project/components/tasks/hooks/useCustomExternalStatuses'
import { allStatuses } from 'pages/project/components/tasks/utils'
import { TimelineActionBar } from 'pages/project/components/timeline/components/timelineActionBar/TimelineActionBar'
import { TimelineChart } from 'pages/project/components/timeline/components/TimelineChart'
import styles from 'pages/project/components/timeline/Timeline.module.scss'
import { TimelineProvider } from 'pages/project/components/timeline/TimelineProvider'
import { initialTimelineFilters, timeline2Gantt, TimelineFilters } from 'pages/project/components/timeline/utils'
import { LocalStorageKey } from 'types/common/localStorage'
import { TaskStatus } from 'types/projects/tasks'
import { isFluidTimeline } from 'types/projects/timeline'

export const TimelineSkeleton = () => (
  <Flex className={styles.timelineContainer}>
    <WppSkeleton variant="rectangle" className={styles.timelineSkeleton} />
  </Flex>
)
export const TimelineEmpty = () => {
  const { t } = useTranslation()

  return (
    <Flex className={styles.timelineContainer}>
      <EmptyState
        noRecordsIcon={<WppEmptyDataviz width={120} />}
        title={t('timeline.noData.title')}
        description={t('timeline.noData.description')}
      />
    </Flex>
  )
}

export const Timeline = () => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    osContext: { userDetails },
  } = useOs()
  const { project, useExternalStatuses, wrikeWorkflows, members } = useProject()
  const { externalStatusesMapped } = useCustomExternalStatuses(wrikeWorkflows)

  const statusesOptions = useMemo(() => {
    return useExternalStatuses ? externalStatusesMapped : allStatuses
  }, [externalStatusesMapped, useExternalStatuses])

  const filteredStatuses = !useExternalStatuses
    ? statusesOptions.filter(status => status !== TaskStatus.ARCHIVED)
    : statusesOptions

  const [lsFilters, setLsFilters] = useLocalStorage<TimelineFilters>(
    `${LocalStorageKey.PROJECT_TIMELINE_FILTERS}:${project.id}:${userDetails.id}`,
    {} as TimelineFilters,
  )
  const [filters, setFilters] = useState<TimelineFilters>({
    ...initialTimelineFilters,
    ...lsFilters,
    selectedStatuses: filteredStatuses,
  })

  useEffect(() => {
    const { search, ...rest } = filters
    setLsFilters(rest)
  }, [setLsFilters, filters])

  const isFilterActive = Object.values(filters).some(filter => !!filter?.length)

  const mapExternalFilterStatusesToOs = useMemo(() => {
    if (!wrikeWorkflows?.statusMappings) return []
    return wrikeWorkflows.statusMappings
      .filter(status => filters.selectedStatuses?.includes(status.wrikeStatusId) && status.direction === 'to_os')
      .map(status => status.osStatus)
  }, [filters.selectedStatuses, wrikeWorkflows?.statusMappings])

  const mappedFilters: TimelineParams = useMemo(() => {
    const statuses = useExternalStatuses ? mapExternalFilterStatusesToOs : filters.selectedStatuses
    return {
      projectId: project.id,
      search: filters.search,
      dueDateRanges: filters.dueDateRanges,
      assignee: filters.assignee,
      statuses,
    }
  }, [filters, mapExternalFilterStatusesToOs, project.id, useExternalStatuses])

  const {
    data: timeline,
    isLoading: isTimelineLoading,
    isError: isTimelineError,
  } = useProjectTimelineApi({
    params: mappedFilters,
    keepPreviousData: true,
  })

  useEffect(() => {
    if (isTimelineError) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
    }
  }, [isTimelineError, showToast, t])

  const ganttTasks = useMemo(
    () => timeline2Gantt({ timeline, members, withAssignee: filters.assignee || [] }),
    [filters.assignee, members, timeline],
  )

  const noData =
    timeline &&
    ((isFluidTimeline(timeline) && timeline.tasks.length === 0) ||
      (!isFluidTimeline(timeline) && timeline.phases.length === 0 && timeline.externalTasks.length === 0))
  const isEmpty = !timeline || noData

  if (isTimelineLoading) {
    return <TimelineSkeleton />
  }

  if (isEmpty && !isFilterActive) {
    return <TimelineEmpty />
  }

  return (
    <>
      <TimelineActionBar filters={filters} setFilters={setFilters} />

      <WppCard size="m" className="wpp-spacing-24-top">
        {isEmpty && (
          <EmptyState
            title={t('common.no_search_results')}
            filtersApplied={isFilterActive}
            description={t('common.no_results_description')}
            testToken="timeline"
          />
        )}

        {!isEmpty && (
          <TimelineProvider timeline={timeline!} assignee={filters.assignee || []}>
            <TimelineChart tasks={ganttTasks} viewMode={filters.viewMode} />
          </TimelineProvider>
        )}
      </WppCard>
    </>
  )
}
