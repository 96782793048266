// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PmIAw{padding-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/saveAsTemplateModal/SaveAsTemplateModal.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA","sourcesContent":[".container {\n  padding-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PmIAw`
};
export default ___CSS_LOADER_EXPORT___;
