import { filesize } from 'filesize'
export const formatBytes = (bytes: number) => filesize(bytes, { base: 2, standard: 'jedec' }) as string

export const bytes = (n: number, unit: 'kb' | 'mb' | 'gb'): number => {
  const binaryBase = 1024

  if (unit === 'kb') {
    return n * binaryBase
  }

  if (unit === 'mb') {
    return bytes(n * binaryBase, 'kb')
  }

  return bytes(n * binaryBase, 'mb')
}
