// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YxtH5{position:absolute;right:0;bottom:20px}._jTFA{padding:2px 8px;background:var(--wpp-white-color);border-radius:16px;cursor:pointer}.RvlOd{z-index:10}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/fluidCanvas/components/fluidLegend/FluidLegend.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,OAAA,CACA,WAAA,CAGF,OACE,eAAA,CACA,iCAAA,CACA,kBAAA,CACA,cAAA,CAGF,OACE,UAAA","sourcesContent":[".Container {\n  position: absolute;\n  right: 0;\n  bottom: 20px;\n}\n\n.Item {\n  padding: 2px 8px;\n  background: var(--wpp-white-color);\n  border-radius: 16px;\n  cursor: pointer;\n}\n\n.Tooltip {\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `YxtH5`,
	"Item": `_jTFA`,
	"Tooltip": `RvlOd`
};
export default ___CSS_LOADER_EXPORT___;
