import { Task as GanttTask } from 'gantt-task-react'
import { useContext } from 'react'

import { TimelineContext } from 'pages/project/components/timeline/TimelineProvider'

interface Props {
  task: GanttTask
}

export const TooltipContent = ({ task: ganttTask }: Props) => {
  const { allTasks, allPhases } = useContext(TimelineContext)

  const tasksAndPhasesCombined = [...allTasks, ...allPhases]

  const entity = tasksAndPhasesCombined.find(task => task.id === ganttTask.id)

  if (!entity) {
    return null
  }

  return null
}
