import { PropsWithChildren } from 'react'

import { useProject } from 'hooks/useProject'
import { useMoveLinearActivity } from 'pages/project/components/canvas/hooks/useMoveLinearActivity'
import { useMoveLinearApp } from 'pages/project/components/canvas/hooks/useMoveLinearApp'
import { usePatchLinearWorkflow } from 'pages/project/components/canvas/hooks/usePatchLinearWorkflow'
import { GenericLinearProvider } from 'providers/common/LinearGenericProvider'

export const LinearProvider = ({ children }: PropsWithChildren<{}>) => {
  const { linearData, project } = useProject()

  const { patchWorkflow } = usePatchLinearWorkflow({ projectId: project.id })
  const { moveActivity } = useMoveLinearActivity({ projectId: project.id })
  const { moveApp } = useMoveLinearApp({ projectId: project.id })

  return (
    <GenericLinearProvider
      id={project.id}
      linearData={linearData}
      patchWorkflow={patchWorkflow}
      moveActivity={moveActivity}
      moveApp={moveApp}
    >
      {children}
    </GenericLinearProvider>
  )
}
