// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OXZ1F{height:100%;overflow-y:auto}.mw4GL{height:400px;padding-top:20px}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectSkeleton/components/workflow/WorkflowSkeleton.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,eAAA,CAGF,OACE,YAAA,CACA,gBAAA","sourcesContent":[".container {\n  height: 100%;\n  overflow-y: auto;\n}\n\n.canvas {\n  height: 400px;\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `OXZ1F`,
	"canvas": `mw4GL`
};
export default ___CSS_LOADER_EXPORT___;
