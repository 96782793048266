// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hFSjq{display:flex;align-items:center;min-height:40px}.oPJ3q{cursor:pointer}.oPJ3q.T8XIB{cursor:not-allowed}.TGosy{flex-grow:1}.tbfs3{margin-top:8px}`, "",{"version":3,"sources":["webpack://./src/pages/settings/attributes/components/multiValues/MultiValues.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,eAAA,CAGF,OACE,cAAA,CAEA,aACE,kBAAA,CAIJ,OACE,WAAA,CAGF,OACE,cAAA","sourcesContent":[".alignWrap {\n  display: flex;\n  align-items: center;\n  min-height: 40px; // align with input height\n}\n\n.iconDrag {\n  cursor: pointer;\n\n  &.noDrag {\n    cursor: not-allowed;\n  }\n}\n\n.valueInput {\n  flex-grow: 1;\n}\n\n.addBtn {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignWrap": `hFSjq`,
	"iconDrag": `oPJ3q`,
	"noDrag": `T8XIB`,
	"valueInput": `TGosy`,
	"addBtn": `tbfs3`
};
export default ___CSS_LOADER_EXPORT___;
