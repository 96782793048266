export enum PresetType {
  DEFAULT = 'DEFAULT',
  PITCH = 'PITCH',
  MINIMAL = 'MINIMAL',
  CUSTOM = 'CUSTOM',
}

export enum ProjectPartKey {
  AppIcon = 'appIcon',
  AppWarning = 'appWarning',
  StatusBar = 'statusBar',
  Descriptions = 'descriptions',
  ResponsiblePersons = 'responsiblePersons',
  Dates = 'dates',
  ActivityFilesAndLinks = 'activityFilesAndLinks',
}

export type ProjectViewSettings = { preset: PresetType } & { [key in ProjectPartKey]: boolean } & {
  id?: string
}
