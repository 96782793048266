import { createContext, PropsWithChildren } from 'react'

import { ProjectTimeline } from 'types/projects/projectsTimeline'

interface TimelineContextProps {
  timeline: ProjectTimeline[]
}

export const ProjectsTimelineContext = createContext<TimelineContextProps>(null!)

export const ProjectsTimelineProvider = ({
  children,
  timeline,
}: PropsWithChildren<{
  timeline: ProjectTimeline[]
}>) => {
  return (
    <ProjectsTimelineContext.Provider
      value={{
        timeline,
      }}
    >
      {children}
    </ProjectsTimelineContext.Provider>
  )
}
