// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uQkAk{display:grid;grid-auto-columns:1fr;grid-auto-flow:row;grid-template-rows:auto;grid-template-columns:1fr 1fr;gap:12px 12px;width:100%;padding:2px;overflow:hidden}.R_Bnb{width:100%;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/appPickerSkeleton/AppPickerSkeleton.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,uBAAA,CACA,6BAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CAGF,OACE,UAAA,CACA,mBAAA","sourcesContent":[".grid {\n  display: grid;\n  grid-auto-columns: 1fr;\n  grid-auto-flow: row;\n  grid-template-rows: auto;\n  grid-template-columns: 1fr 1fr;\n  gap: 12px 12px;\n  width: 100%;\n  padding: 2px;\n  overflow: hidden;\n}\n\n.card {\n  width: 100%;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `uQkAk`,
	"card": `R_Bnb`
};
export default ___CSS_LOADER_EXPORT___;
