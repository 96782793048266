import { WppActionButton, WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from 'pages/components/backToTemplatesButton/BackToTemplatesButton.module.scss'
import { routesManager } from 'utils/routesManager'

export const BackToTemplatesButton = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <WppActionButton
      onClick={() => navigate(routesManager.templates.root.getURL())}
      className={styles.container}
      data-testid="back-to-templates-button"
    >
      <WppIconChevron direction="left" slot="icon-start" className={styles.icon} />
      <WppTypography type="s-body" className={styles.greyColor800}>
        {t('template.all_templates')}
      </WppTypography>
    </WppActionButton>
  )
}
