import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { CreateProjectAttributes } from 'api/projects/fetchers/addAttributesApi'
import { ProjectAttribute, ProjectAttributeType } from 'types/projects/attributes'

export type RequiredVale = 'optional' | 'required'
export type FormValues = Pick<
  CreateProjectAttributes,
  'name' | 'helperText' | 'iconText' | 'externalKey' | 'enabled' | 'type' | 'valueOptions'
> & { required: RequiredVale }

export const useTypeOptions = () => {
  const { t } = useTranslation()
  return useMemo(
    () => [
      {
        label: t('project.settings.attributes_fields.text_input'),
        value: ProjectAttributeType.TEXT_INPUT,
      },
      {
        label: t('project.settings.attributes_fields.multi_select'),
        value: ProjectAttributeType.MULTI_SELECT,
      },
    ],
    [t],
  )
}

export const addAttributesSchema = (attList: ProjectAttribute[]) =>
  zod
    .object({
      name: zod
        .string()
        .min(3, 'This field is required and should have at least 3 symbols')
        .max(100, 'Name should be from 3 to 100 characters long'),
      helperText: zod
        .string()
        .min(3, 'Helper text should contain at least 3 symbols')
        .max(100, 'Helper text should be from 3 to 100 characters long')
        .optional()
        .or(zod.literal('')),
      iconText: zod
        .string()
        .min(3, 'Info icon text should contain at least 3 symbols')
        .max(100, 'Info icon text should be from 3 to 100 characters long')
        .or(zod.literal('')),
      externalKey: zod
        .string()
        .min(3, 'External key should contain at least 3 symbols')
        .max(100, 'External key should be from 3 to 100 characters long')
        .or(zod.literal('')),
      type: zod.string(),
      valueOptions: zod.array(zod.string().min(1, 'Name can’t be empty')).nullable(),
    })
    .refine(
      data => {
        if (data.name.trim().length >= 3)
          return !attList.some(att => att.name.toLowerCase() === data.name.trim().toLowerCase())
        return true
      },
      {
        message: 'Name already exists. Use a different one',
        path: ['name'],
      },
    )
    .superRefine((val, ctx) => {
      const duplicateIdx = findDuplicates(val.valueOptions ?? [])
      if (duplicateIdx.length) {
        duplicateIdx.forEach(idx => {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: 'All values must be unique',
            path: ['valueOptions', idx],
          })
        })
      }
    })

export const multiValuesSchema = () =>
  zod
    .object({
      valueOptions: zod.array(
        zod.object({
          value: zod.string().min(1, 'Name can’t be empty'),
        }),
      ),
    })
    .superRefine((val, ctx) => {
      const duplicateIdx = findDuplicates(val.valueOptions.map(({ value }) => value) ?? [])
      if (duplicateIdx.length) {
        duplicateIdx.forEach(idx => {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: 'All values must be unique',
            path: ['valueOptions', idx, 'value'],
          })
        })
      }
    })

export function findDuplicates(arr: string[]): number[] {
  const indexMap: Map<string, number[]> = new Map()
  const duplicateIndexes: number[] = []

  arr.forEach((str, index) => {
    if (!indexMap.has(str)) {
      indexMap.set(str, [])
    }
    indexMap.get(str)!.push(index)
  })

  indexMap.forEach(indexes => {
    if (indexes.length > 1) {
      duplicateIndexes.push(...indexes)
    }
  })

  return duplicateIndexes
}
