import { WppAvatar, WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import styles from 'pages/components/projectModal/components/appCtxWarningModal/components/AppCtxWarningModal.module.scss'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface AppWarning {
  name: string
  logoUrl: string
}

interface Props extends NiceModalWrappedProps {
  warnings: AppWarning[]
  onForceSave: () => Promise<any>
}

const AppCtxWarningModal = ({ isOpen, onClose, onCloseComplete, warnings, onForceSave }: Props) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)

  const saveAction = useCallback(async () => {
    setIsSaving(true)

    await onForceSave()
    onClose()

    setIsSaving(false)
  }, [onForceSave, onClose])

  return (
    <Modal
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      className={styles.modalWrapper}
      size="s"
    >
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          {t('modals.edit_project.app_ctx_issues.title')}
        </Flex>
      </WppTypography>

      <Flex direction="column" slot="body">
        <WppTypography type="s-body">
          <Trans
            i18nKey="modals.edit_project.app_ctx_issues.message"
            values={{ count: warnings.length }}
            components={{ bold: <WppTypography type="m-strong" /> }}
          />
        </WppTypography>
        <WppTypography type="s-strong" className="wpp-spacing-16-top">
          {t('modals.edit_project.app_ctx_issues.list_title')}
        </WppTypography>

        <Flex direction="column" className={styles.appScrollContainer}>
          {warnings.map((warning, idx) => (
            <div key={idx} className="wpp-spacing-8-top">
              <Flex gap={8} align="center">
                <WppAvatar size="xs" src={warning.logoUrl} className={styles.appIcon} />
                <WppTypography type="s-body">{warning.name}</WppTypography>
              </Flex>
            </div>
          ))}
        </Flex>
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} size="s" variant="secondary">
          {t('common.btn_cancel')}
        </WppButton>
        <WppButton loading={isSaving} onClick={saveAction} size="s" variant="primary">
          {t('modals.edit_project.app_ctx_issues.btn_confirm')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showAppCtxWarningModal } = createNiceModal<Props>(AppCtxWarningModal, 'app-ctx-warning-modal')
