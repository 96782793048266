import { useRef } from 'react'

export const useTimeTracker = () => {
  const startTimeRef = useRef(new Date().toISOString())

  const calculateTimeDifferenceInSeconds = (): number => {
    const currentTime = new Date()
    const startTimeDate = new Date(startTimeRef.current)

    const diffInMilliseconds = currentTime.getTime() - startTimeDate.getTime()
    return Math.floor(diffInMilliseconds / 1000)
  }

  return { calculateTimeDifferenceInSeconds }
}
