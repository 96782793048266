import { LabelConfig } from '@platform-ui-kit/components-library'
import { WppTypography, WppLabel } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import {
  Editor,
  EditorState,
  RichUtils,
  DraftEditorCommand,
  convertToRaw,
  convertFromRaw,
  ContentState,
  EditorProps,
} from 'draft-js'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { EditorToolbar } from 'components/richText/editorToolbar/EditorToolbar'
import 'draft-js/dist/Draft.css'
import styles from 'components/richText/textEditor/TextEditor.module.scss'
import { RichText } from 'components/richText/utils'

interface TextEditorProps extends Partial<EditorProps> {
  initialValue?: RichText
  setValue?: (value: RichText) => void
  setError?: (error: MayBeNull<TextEditorError>) => void
  labelConfig?: LabelConfig
  name?: string
  optional?: boolean
  charactersLimit?: number
  'data-testid'?: string
}

export interface TextEditorError {
  key: 'limit'
  value: number
}

const createContentState = (text?: MayBeNull<RichText>) => {
  return text
    ? EditorState.createWithContent(
        text.formatted ? convertFromRaw(text.formatted) : ContentState.createFromText(text.plain || ''),
      )
    : EditorState.createEmpty()
}

export const TextEditor = forwardRef<Editor, TextEditorProps>(
  (
    {
      initialValue,
      setValue,
      setError,
      name,
      labelConfig,
      optional = true,
      charactersLimit = 5000,
      'data-testid': dataTestId,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const [editorState, setEditorState] = useState<EditorState>(() => createContentState(initialValue))
    const currentCharacters = editorState.getCurrentContent().getPlainText().length
    const charactersRemaining = charactersLimit - currentCharacters

    useEffect(() => {
      setValue &&
        setValue({
          plain: editorState.getCurrentContent().getPlainText(),
          formatted: convertToRaw(editorState.getCurrentContent()),
        })
    }, [setValue, editorState])

    useEffect(() => {
      if (!setError) {
        return
      }
      setError(charactersRemaining < 0 ? { key: 'limit', value: charactersRemaining } : null)
    }, [setError, charactersRemaining])

    const handleKeyCommand = useCallback(
      (command: DraftEditorCommand) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
          setEditorState(newState)
          return 'handled'
        }
        return 'not-handled'
      },
      [editorState],
    )

    return (
      <div>
        {name && labelConfig && (
          <WppLabel
            config={{
              ...labelConfig,
              locales: {
                optional: t('common.optional'),
              },
            }}
            htmlFor={name}
            typography="s-strong"
            optional={optional}
          />
        )}
        <div className={styles.textEditorWrapper} data-testid={dataTestId}>
          <Editor
            ref={ref}
            editorState={editorState}
            onChange={setEditorState}
            handleKeyCommand={handleKeyCommand}
            {...rest}
          />
        </div>

        <EditorToolbar editorState={editorState} setEditorState={setEditorState} />
        <Flex justify="end" className={clsx(styles.charactersLimit, { [styles.isError]: charactersRemaining <= 0 })}>
          <WppTypography type="xs-body">Characters remaining:</WppTypography>
          <WppTypography type="xs-strong">
            {currentCharacters}/{charactersRemaining}
          </WppTypography>
        </Flex>
      </div>
    )
  },
)
