// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lMdL_{width:100%;padding:12px 12px 12px 16px;background:var(--wpp-warning-color-200);border-radius:6px}.cret6{margin:4px auto}.bmdC1{flex-grow:1;margin:0 24px 0 8px}`, "",{"version":3,"sources":["webpack://./src/pages/components/appIssueCard/AppIssueCard.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,2BAAA,CACA,uCAAA,CACA,iBAAA,CAGF,OACE,eAAA,CAGF,OACE,WAAA,CACA,mBAAA","sourcesContent":[".card {\n  width: 100%;\n  padding: 12px 12px 12px 16px;\n  background: var(--wpp-warning-color-200);\n  border-radius: 6px;\n}\n\n.icon {\n  margin: 4px auto;\n}\n\n.body {\n  flex-grow: 1;\n  margin: 0 24px 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `lMdL_`,
	"icon": `cret6`,
	"body": `bmdC1`
};
export default ___CSS_LOADER_EXPORT___;
