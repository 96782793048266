import { fetchWorkflowApiLinear, fetchWorkflowApiFluid } from 'api/canvas/fetchers/fetchWorkflowApi'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ConnectionType } from 'pages/project/components/canvas/fluidCanvas/utils'
import { initData } from 'pages/project/components/canvas/utils'

export const useFetchWorkflowApiLinear = createUseQuery({
  queryKey: ApiQueryKeys.PROJECT_WORKFLOW_LINEAR,
  fetcher: fetchWorkflowApiLinear,
  selector: res => {
    return res?.data || initData
  },
})

export const useFetchWorkflowApiFluid = createUseQuery({
  queryKey: ApiQueryKeys.PROJECT_WORKFLOW_FLUID,
  fetcher: fetchWorkflowApiFluid,
  selector: res => {
    let data = res?.data

    if (data) {
      // temporary filter data connections, @see https://jira.uhub.biz/browse/WPPLONOP-15513
      data = {
        ...data,
        connections: data.connections.filter(connection => connection.type !== ConnectionType.DATA),
      }
      return data
    }

    return null
  },
})
