import { WppToggle } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppToggle>, 'checked' | 'onChange' | 'onWppChange'> {
  name: string
}

export const FormToggle = forwardRef<HTMLWppToggleElement, Props>(({ name, onWppBlur, ...rest }, ref) => {
  const innerRef = useRef<HTMLWppToggleElement>(null)

  const {
    field: { value, onChange, onBlur },
  } = useField({ name })

  return (
    <WppToggle
      {...rest}
      ref={mergeRefs([innerRef, ref])}
      name={name}
      checked={value}
      onWppChange={({ detail: { checked } }) => onChange(checked)}
      onWppBlur={e => {
        onBlur()
        onWppBlur?.(e)
      }}
    />
  )
})
