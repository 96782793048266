import { WppCard, WppAccordion, WppIconInfo, WppActionButton } from '@platform-ui-kit/components-library-react'
import { FC, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { AttachedFile } from 'pages/project/components/canvas/components/attachedFile/AttachedFile'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ExternalLinksPreviewList } from 'pages/project/components/canvas/components/externalLink/ExternalLinksPreviewList'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import { useReadWriteStorageFlag } from 'pages/project/components/canvas/hooks/useSaveFlagToStorage'
import styles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { LinearPreviewApplication } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/LinearPreviewApplication'
import { useUiPartEnabled } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { DetailsModalType } from 'types/common/utils'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, ExternalLink } from 'types/projects/workflow'

interface Props {
  activityItem: ActivityItem
  isDisabled: boolean
  sortedExternalLinks?: ExternalLink[]
  fileAction?: boolean
  templateView?: boolean
  phaseId?: string
}

export const LinearPreviewActivity: FC<Props> = ({
  activityItem,
  isDisabled,
  sortedExternalLinks,
  fileAction,
  templateView,
  phaseId,
}) => {
  const { useExternalStatuses } = useProject() ?? {}
  const [, setSearchParams] = useSearchParams()
  const { state, toggleState } = useReadWriteStorageFlag(activityItem.id, !templateView)
  const [isHover, setIsHover] = useState(false)

  const isUIPartEnabled = useUiPartEnabled(false)

  const { name, startDate, endDate, task, files, description } = activityItem
  const assignMember = useAssignMember(activityItem.assignUser)

  const showTask = isUIPartEnabled(ProjectPartKey.StatusBar) && task?.status === TaskStatus.COMPLETED
  const showCalendar = isUIPartEnabled(ProjectPartKey.Dates) && startDate && endDate
  const subBur = showTask ? (
    <div className={styles.dateWrapper}>
      <StatusText
        isExternal={useExternalStatuses}
        statusKey={useExternalStatuses ? task?.wrike?.externalStatus! : task?.status}
        small
      />
    </div>
  ) : showCalendar ? (
    <div className={styles.dateWrapper}>
      <Calendar startDate={startDate} endDate={endDate} size="xs-body" hideIcon data-testid="canvas-activity-dates" />
    </div>
  ) : null

  const openPhaseModal = useCallback(
    (id: string) =>
      setSearchParams({
        view: DetailsModalType.PHASE_DETAILS_PREVIEW,
        ...(phaseId && { phaseId }),
        ...(id && { activityId: id }),
      }),
    [phaseId, setSearchParams],
  )

  return (
    <WppCard
      className={styles.activityExpandableContainer}
      variant="secondary"
      size="s"
      data-testid="activity-item-preview"
    >
      <WppAccordion
        expanded={state}
        withDivider={false}
        className={styles.activityAccordion}
        size="s"
        onWppChange={toggleState}
        data-testid="activity-accordion"
      >
        <div
          slot="header"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={styles.activityHeader}
        >
          <Truncate lines={2} type="s-midi" data-testid="activity-name">
            {name}
          </Truncate>

          {subBur}
        </div>

        <Flex slot="actions" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
          {isHover && !templateView ? (
            <WppActionButton
              variant="secondary"
              data-testid="activity-details-action"
              onClick={() => {
                openPhaseModal(activityItem.id)
              }}
            >
              <WppIconInfo slot="icon-start" />
            </WppActionButton>
          ) : (
            <>
              {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && assignMember ? (
                <ResponsiblePerson assignMember={assignMember} size="xs" hideIcon data-testid="phase-item-assignee" />
              ) : (
                <div className={styles.responsiblePersonPlaceholder} />
              )}
            </>
          )}
        </Flex>

        <Flex direction="column" gap={8} className={styles.activityContentWrapper}>
          {isUIPartEnabled(ProjectPartKey.Descriptions) && description && (
            <Truncate
              lines={3}
              className={styles.greyColor800}
              title={description}
              type="xs-body"
              data-testid="description"
            >
              {description}
            </Truncate>
          )}

          {activityItem.items.length > 0 && (
            <Flex direction="column" data-id="activity-droppable-container" gap={8}>
              {activityItem.items.map((t, i) => (
                <LinearPreviewApplication
                  application={t.application}
                  isDisabled={isDisabled}
                  key={i}
                  inner
                  templateView={templateView}
                  activityId={activityItem.id}
                  phaseId={phaseId}
                />
              ))}
            </Flex>
          )}

          {isUIPartEnabled(ProjectPartKey.ActivityFilesAndLinks) && (
            <>
              {!!files.length && (
                <Flex direction="column" data-testid="activity-files">
                  <ShowMoreItems maxToShow={2}>
                    {files.map(file => (
                      <AttachedFile
                        preview
                        variant="secondary"
                        file={file}
                        key={file.id}
                        activityId={activityItem.id}
                        isDisabled={isDisabled}
                        fileAction={fileAction}
                      />
                    ))}
                  </ShowMoreItems>
                </Flex>
              )}

              {!!sortedExternalLinks?.length && (
                <Flex direction="column" className={styles.externalLinksContainer}>
                  <ExternalLinksPreviewList
                    activityId={activityItem.id}
                    links={sortedExternalLinks}
                    isDisabled={isDisabled}
                    variant="secondary"
                  />
                </Flex>
              )}
            </>
          )}
        </Flex>
      </WppAccordion>
    </WppCard>
  )
}
