import { projectApi } from 'api'
import { ContextHierarchy, Project, ProjectStatus } from 'types/projects/projects'

export interface UpdateProjectAttribute {
  id: string
  value: string
}

export interface UpdateProjectDTO {
  projectAttributes: UpdateProjectAttribute[]
  status?: ProjectStatus
  updateAllTasks?: boolean
  contextHierarchy?: ContextHierarchy[]
  wrikeProjectId?: string
  disconnectWrike?: boolean
  skipAffectedApps?: boolean
}

interface Params {
  id: string
  project: UpdateProjectDTO
}

export const updateProjectApi = ({ id, project }: Params) => projectApi.patch<Project>(`/projects/${id}`, project)
