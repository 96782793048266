import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'

import { useMembersListApi } from 'api/projects/queries/useMembersListApi'
import { useProject } from 'hooks/useProject'
import { Members } from 'types/members/members'
import { isEqualEmails } from 'utils/common'

export const useAssignMember = (
  email?: MayBeNull<string>,
  projectId?: string,
  isLoading?: boolean,
): Members | undefined => {
  const context = useProject()

  const { data: members } = useMembersListApi({
    params: { id: projectId ?? context?.project.id! },
    enabled: !isLoading && (!!projectId ?? !!context.project.id),
  })

  // TODO: WPPLONOP-19812 - temporary solution, to prevent errors when hook is called not in the project context
  const assignMember = useMemo(() => members?.find(member => isEqualEmails(member.email, email)), [email, members])

  if (!assignMember) {
    return
  }

  return assignMember
}
