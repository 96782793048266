// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xD6vO{max-height:400px;overflow-y:scroll;scrollbar-width:thin;scrollbar-color:var(--wpp-grey-color-400) rgba(0,0,0,0)}.xD6vO::-webkit-scrollbar{width:8px;height:8px}.xD6vO::-webkit-scrollbar-thumb{border:2px solid rgba(0,0,0,0);border-radius:4px;box-shadow:inset 0 0 0 2px var(--wpp-grey-color-400)}.xD6vO::-webkit-scrollbar:hover{background-color:var(--wpp-grey-color-200)}.xD6vO::-webkit-scrollbar-thumb:hover{border:0 solid rgba(0,0,0,0);box-shadow:inset 0 0 0 4px var(--wpp-grey-color-400)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/members/components/confirmIExternalModal/ConfirmExternalModal.module.scss","webpack://./node_modules/@platform-ui-kit/components-library/dist/collection/scrollbar.scss"],"names":[],"mappings":"AAEA,OACE,gBAAA,CACA,iBAAA,CCHA,oBAAA,CACA,uDAAA,CAEA,0BACE,SAAA,CACA,UAAA,CAGF,gCACE,8BAAA,CACA,iBAAA,CACA,oDAAA,CAKF,gCACE,0CAAA,CAGF,sCACE,4BAAA,CACA,oDAAA","sourcesContent":["@import '@platform-ui-kit/components-library/dist/collection/scrollbar';\n\n.container {\n  max-height: 400px;\n  overflow-y: scroll;\n\n  @include scrollbarThin;\n  @include scrollbarHover;\n}\n","@mixin scrollbarThin() {\n  scrollbar-width: thin;\n  scrollbar-color: var(--wpp-grey-color-400) transparent;\n\n  &::-webkit-scrollbar {\n    width: 8px;\n    height: 8px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border: 2px solid transparent;\n    border-radius: 4px;\n    box-shadow: inset 0 0 0 2px var(--wpp-grey-color-400);\n  }\n}\n\n@mixin scrollbarHover() {\n  &::-webkit-scrollbar:hover {\n    background-color: var(--wpp-grey-color-200);\n  }\n\n  &::-webkit-scrollbar-thumb:hover {\n    border: 0 solid transparent;\n    box-shadow: inset 0 0 0 4px var(--wpp-grey-color-400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `xD6vO`
};
export default ___CSS_LOADER_EXPORT___;
