import { useLocation, useNavigate } from 'react-router-dom'

export const useGoBackOrRedirect = (defaultPath: string): (() => void) => {
  const navigate = useNavigate()
  const location = useLocation()

  // Function to go back or redirect
  const goBackOrRedirect = () => {
    // Check if the user came from within the same project. The location.key property is a unique string associated with this location. On the initial location, this will be the string default. On all subsequent locations, this string will be a unique identifier.
    const doesAnyHistoryEntryExist = location.key !== 'default'

    if (doesAnyHistoryEntryExist) {
      navigate(-1)
    } else {
      // User came from an external source, navigate to the default path
      navigate(defaultPath)
    }
  }

  return goBackOrRedirect
}
