import NiceModal from '@ebay/nice-modal-react'
import { useCallback, useContext } from 'react'

export function useRemoveAllModals() {
  const modals = useContext(NiceModal.NiceModalContext)

  const removeAllModals = useCallback(() => {
    Object.keys(modals).forEach(modalId => NiceModal.remove(modalId))
  }, [modals])

  return {
    removeAllModals,
  }
}
