import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Table } from 'components/common/table'
import styles from 'pages/settings/attributes/components/attributesTable/AttributesTable.module.scss'
import { useAttributesTableColumns } from 'pages/settings/attributes/components/attributesTable/attributesTableColumns'
import { ProjectAttribute, ProjectAttributeClass } from 'types/projects/attributes'

interface Props {
  attributes: ProjectAttribute[]
  isLoading?: boolean
  type: ProjectAttributeClass
  withActions?: boolean
}

const loadingData = [null, null, null, null, null] as any

export function AttributesTable({ attributes, isLoading, type, withActions }: Props) {
  const { t } = useTranslation()
  const columnDefs = useAttributesTableColumns(withActions)

  if (!attributes.length && !isLoading) {
    return (
      <Flex justify="center" align="center" className={styles.noData}>
        <WppTypography type="m-strong">
          {t(
            type === ProjectAttributeClass.SYSTEM
              ? 'project.settings.attributes_table.no_results_system'
              : 'project.settings.attributes_table.no_results_custom',
          )}
        </WppTypography>
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap={29} className={styles.tableWrapper}>
      <Table
        className={styles.agGrid}
        rowModelType="clientSide"
        domLayout="autoHeight"
        rowData={isLoading ? loadingData : attributes}
        cacheBlockSize={50}
        rowHeight={48}
        columnDefs={columnDefs}
        rowSelection="multiple"
        suppressPaginationPanel
        suppressRowClickSelection
      />
    </Flex>
  )
}
