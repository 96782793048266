import { useMemo } from 'react'

import { useGenerateProjectUploadUrlsApi } from 'api/attachments/mutation/useGenerateProjectUploadUrlsApi'
import { useUploadProjectFileApi } from 'api/attachments/mutation/useUploadProjectFileApi'
import { createUploadCallback } from 'hooks/attachments/createUploadCallback'

export const useUploadProjectFilesApi = () => {
  const { mutateAsync: handleGenerateLeanProjectUrls } = useGenerateProjectUploadUrlsApi()
  const { mutateAsync: handleUploadProjectFile } = useUploadProjectFileApi()

  return useMemo(
    () =>
      createUploadCallback({
        generateUploadUrls: handleGenerateLeanProjectUrls,
        uploadFile: handleUploadProjectFile,
      }),
    [handleGenerateLeanProjectUrls, handleUploadProjectFile],
  )
}
