import { fetchNativeAppsApi } from 'api/applications/fetchers/fetchNativeAppsApi'
import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteNativeAppsApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.NATIVE_APPS_INFINITE,
  fetcher: fetchNativeAppsApi,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: lastPage => {
    return lastPage.data.paginator.totalPages === lastPage.data.paginator.page
      ? undefined
      : lastPage.data.paginator.page + 1
  },
})
