import { WppButton, WppFilterButton, WppTooltip } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'

import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { WorkflowSkeleton } from 'pages/components/projectSkeleton/components/workflow/WorkflowSkeleton'
import { viewSettingsModal } from 'pages/project/components/canvas/components/viewSettingsModal/ViewSettingsModal'
import FluidCanvas from 'pages/project/components/canvas/fluidCanvas/FluidCanvas'
import { Item } from 'pages/project/components/canvas/linearCanvas/components/item/Item'
import { Phase } from 'pages/project/components/canvas/linearCanvas/components/phase/Phase'
import { StickySubheader } from 'pages/project/components/canvas/linearCanvas/components/stickySubheader/StickySubheader'
import styles from 'pages/project/components/workflow/Workflow.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { PresetType } from 'types/projects/projectViewSettings'
import { ActivityItem, ContainerType, FluidWorkflow, PhaseItem, PhaseItemType } from 'types/projects/workflow'
import { routesManager } from 'utils/routesManager'

export const Workflow = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { trackAction } = useTrackAction()
  const { project, isLinearLoading, linearData, fluidData, isFluidLoading, viewSettings } = useProject()
  const [hideSubHeader, setHideSubHeader] = useState(false)
  const previewRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const analyticsData = {
      ...getCommonProjectAnalyticsData(project),
      viewing_preferences: viewSettings?.preset,
    }
    trackAction(ACTION_ANALYTICS.ACTION_PROJECT_WORKFLOW_VIEW, analyticsData)

    // exclude `project` data, as we should track only tab open or change of the view settings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewSettings, trackAction])

  const patchedFluid: MayBeNull<FluidWorkflow> = useMemo(() => {
    if (!fluidData) {
      return null
    }

    // hack for fluid workflow with hidden apps
    const newFluid = {
      ...fluidData,
      containers: fluidData.containers.map(container => ({ ...container })),
    }

    const containersMap = Object.fromEntries(newFluid.containers.map(container => [container.id, container]))
    const containersByItemMap = Object.fromEntries(newFluid.containers.map(container => [container.itemId, container]))
    const itemsMap = Object.fromEntries(newFluid.items.map(item => [item.id, item]))
    newFluid.containers.forEach(container => {
      if (container.itemType === PhaseItemType.Application && container.containerType === ContainerType.NESTED) {
        const rootContainer = containersMap[container.rootContainerId!]
        const activity = itemsMap[rootContainer.itemId] as ActivityItem

        activity.items
          .toSorted(({ orderNumber: orderNumberA }, { orderNumber: orderNumberB }) => {
            return orderNumberA === orderNumberB ? 0 : orderNumberA < orderNumberB ? -1 : 1
          })
          .filter(item => !item.application.hidden)
          .forEach((item, index) => {
            containersByItemMap[item.application.id].orderNumberInRoot = index
          })
      }
    })

    return newFluid
  }, [fluidData])

  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const { id } = project

  const counterNumber = useMemo(() => {
    return viewSettings?.preset !== PresetType.DEFAULT ? 1 : 0
  }, [viewSettings])

  const linearColumns = useMemo(() => {
    return linearData.phaseOrder.map(order => {
      const { id, name } = linearData.phases[order]
      return { id, name }
    })
  }, [linearData])

  return (
    <>
      {isLinearLoading || isFluidLoading ? (
        <Flex direction="column" className={styles.container} gap={20}>
          <WorkflowSkeleton isCanvasLoading={isLinearLoading} />
        </Flex>
      ) : (project.processType === ProcessType.LINEAR && linearData?.phaseOrder?.length) ||
        (project.processType === ProcessType.FLUID && patchedFluid?.containers?.length) ? (
        <>
          {project.processType === ProcessType.LINEAR && (
            <>
              <PageBackToTop scrollTopOffset={220} onChangeState={setHideSubHeader} />
              <Flex className={styles.filters} justify="end">
                <WppTooltip
                  text={t('project.canvas.preferences_button_tooltip')!}
                  config={{
                    trigger: counterNumber >= 1 ? 'mouseenter' : 'manual',
                    hideOnClick: false,
                    maxWidth: 400,
                  }}
                >
                  <WppFilterButton
                    counter={counterNumber}
                    data-testid="view-settings-button"
                    onClick={() => viewSettingsModal()}
                  >
                    {t('project.canvas.btn_preferences')}
                  </WppFilterButton>
                </WppTooltip>
              </Flex>

              <Flex direction="column" className={styles.container} gap={20}>
                <StickySubheader
                  columns={linearColumns}
                  headerHidden={!hideSubHeader}
                  containerRef={previewRef}
                  columnClass={styles.columnOverview}
                />
                <Flex className={styles.preview} ref={previewRef}>
                  {linearData.phaseOrder.map((order, index) => {
                    const phase = linearData.phases[order]
                    const phaseItems = linearData.phases[order].itemIds.map(id => linearData.items[id]) as PhaseItem[]

                    return (
                      <Phase
                        key={phase.id}
                        column={phase}
                        index={index}
                        variant="primary"
                        preview
                        projectId={project.id}
                        isWrikeConnected={!!project.wrike?.isConnected}
                      >
                        <Flex direction="column" gap={8} className={styles.itemsWrapper}>
                          {phaseItems.map((t, i) => (
                            <Item
                              variant="primary"
                              key={t.id}
                              phaseItem={t}
                              index={i}
                              preview
                              projectId={project.id}
                              isWrikeConnected={!!project.wrike?.isConnected}
                            />
                          ))}
                        </Flex>
                      </Phase>
                    )
                  })}
                </Flex>
              </Flex>
            </>
          )}

          {project.processType === ProcessType.FLUID && patchedFluid && (
            <Flex className={styles.bodyFluid} gap={16} direction="column">
              <Flex justify="end">
                <WppTooltip
                  text={t('project.canvas.preferences_button_tooltip')!}
                  config={{
                    trigger: counterNumber >= 1 ? 'mouseenter' : 'manual',
                    hideOnClick: false,
                    maxWidth: 400,
                  }}
                >
                  <WppFilterButton
                    counter={counterNumber}
                    data-testid="view-settings-button"
                    onClick={() => viewSettingsModal()}
                  >
                    {t('project.canvas.btn_preferences')}
                  </WppFilterButton>
                </WppTooltip>
              </Flex>
              <ReactFlowProvider>
                <FluidCanvas fluidData={patchedFluid} projectId={project.id} preview />
              </ReactFlowProvider>
            </Flex>
          )}
        </>
      ) : (
        <EmptyState
          title={t('overview.no_workflow')}
          description={!isOwnerOrGlobalManage ? t('overview.no_workflow_owner') : t('overview.no_workflow_description')}
          testToken="workflow"
        >
          {isOwnerOrGlobalManage && (
            <WppButton size="m" onClick={() => navigate(routesManager.project.canvas.getURL({ id }))}>
              {t('overview.open_canvas')}
            </WppButton>
          )}
        </EmptyState>
      )}
    </>
  )
}
