import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { IRowNode, SortModelItem, ValueGetterFunc } from 'ag-grid-community'

import { ColDef, TableProps } from 'components/common/table/Table'
import { isString } from 'utils/common'

export type TableInfiniteLoader<TData = any> = (params: {
  startRow: number
  endRow: number
  sortModel?: SortModelItem[]
}) => Promise<{ data: TData[]; totalRowsCount: number }>

export type TableInfiniteOnLoadSuccess<TData = any> = (params: {
  startRow: number
  endRow: number
  sortModel?: SortModelItem[]
  data: TData[]
  totalRowsCount: number
  /**
   * Indicates if the loader returned 0 items for the first page.
   */
  isEmptySource: boolean
}) => void

export type TableInfiniteOnLoadError = (error: unknown) => void

export type TableInfiniteProps<TData = any> = Omit<
  TableProps<TData>,
  'rowModelType' | 'datasource' | 'rowData' | 'suppressRowClickSelection' | 'rowSelection'
> & {
  /**
   * Loader function used to create datasource.
   * This function reference should be maintained and changed only if you want
   * to table be reloaded with changed parameters.
   * Ex. filters or search was changed.
   */
  loader: TableInfiniteLoader<TData>
  onLoadSuccess?: TableInfiniteOnLoadSuccess<TData>
  onLoadError?: TableInfiniteOnLoadError
  toggleStickyHeader?: boolean
}

export type TooltipValueGetter<TData = any> = NonNullable<ColDef<TData>['tooltipValueGetter']>

export function isLoadingMoreRow<TData = any>({ data, node }: { data?: TData; node: IRowNode<TData> }) {
  return !data && !node.group && !node.isExpandable()
}

export function getValueGetter<TData = any>(valueGetter: string | ValueGetterFunc<TData>): ValueGetterFunc<TData> {
  return function (params) {
    const { data, node } = params

    if (node && isLoadingMoreRow({ data, node })) {
      return null
    }

    return isString(valueGetter) ? data![valueGetter as keyof typeof data] : valueGetter(params)
  }
}

export function getToolipValueGetter<TData = any>(
  tooltipValueGetter: TooltipValueGetter<TData>,
): TooltipValueGetter<TData> {
  return function (params) {
    const { data, node } = params

    if (node && isLoadingMoreRow({ data, node })) {
      return null
    }

    return tooltipValueGetter(params)
  }
}

export function getCellRenderer<TData = any>(colDef: ColDef<TData>): NonNullable<ColDef<TData>['cellRenderer']> {
  const { cellRenderer: CellRenderer, loadingCellRenderer: LoadingCellRenderer = () => <WppSkeleton height="50%" /> } =
    colDef

  return function (params) {
    const { value, valueFormatted, data, node } = params

    return isLoadingMoreRow<TData>({ data, node }) ? (
      <LoadingCellRenderer {...params} />
    ) : (
      <>{CellRenderer ? <CellRenderer {...params} /> : valueFormatted || value}</>
    )
  }
}
