// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lE8uK{height:100%}.CX0EX{height:100%}.JVduO{height:100%}.Rg4FH{margin-bottom:16px}.R7LlD{position:relative;width:100%;min-height:100%;overflow:auto}.tj5UW{position:relative;height:100%;margin:-24px;padding:24px;overflow:hidden}.wAM8A{color:var(--wpp-primary-color-500);text-decoration:underline}.bhjR5{position:absolute;right:24px;z-index:1}.kFJ44{flex:1 1 100%;min-width:350px}.kFJ44+.kFJ44{margin-left:16px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/workflow/Workflow.module.scss"],"names":[],"mappings":"AAEA,OACE,WAAA,CAGF,OACE,WAAA,CAGF,OACE,WAAA,CAGF,OACE,kBAAA,CAGF,OACE,iBAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CAGF,OACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,eAAA,CAGF,OACE,kCAAA,CACA,yBAAA,CAGF,OACE,iBAAA,CACA,UAAA,CACA,SAAA,CAGF,OACE,aAAA,CACA,eAAA,CAEA,cACE,gBAAA","sourcesContent":["@import '@platform-ui-kit/components-library/dist/collection/scrollbar';\n\n.container {\n  height: 100%;\n}\n\n.workflowContainer {\n  height: 100%;\n}\n\n.canvas {\n  height: 100%;\n}\n\n.filters {\n  margin-bottom: 16px;\n}\n\n.preview {\n  position: relative;\n  width: 100%;\n  min-height: 100%;\n  overflow: auto;\n}\n\n.bodyFluid {\n  position: relative;\n  height: 100%;\n  margin: -24px;\n  padding: 24px;\n  overflow: hidden;\n}\n\n.link {\n  color: var(--wpp-primary-color-500);\n  text-decoration: underline;\n}\n\n.fluidPreferencesButton {\n  position: absolute;\n  right: 24px;\n  z-index: 1;\n}\n\n.columnOverview {\n  flex: 1 1 100%;\n  min-width: 350px;\n\n  & + & {\n    margin-left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `lE8uK`,
	"workflowContainer": `CX0EX`,
	"canvas": `JVduO`,
	"filters": `Rg4FH`,
	"preview": `R7LlD`,
	"bodyFluid": `tj5UW`,
	"link": `wAM8A`,
	"fluidPreferencesButton": `bhjR5`,
	"columnOverview": `kFJ44`
};
export default ___CSS_LOADER_EXPORT___;
