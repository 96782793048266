import { useTranslation } from 'react-i18next'

import { usePatchTaskApi } from 'api/canvas/mutation/usePatchTaskApi'
import { TasksParams } from 'api/projects/fetchers/fetchProjectTasksApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { queryClient } from 'providers/osQueryClient/utils'
import { MayBeNull } from 'types/common/utils'
import { Task } from 'types/projects/tasks'

interface IProps {
  id: string
  startDate?: MayBeNull<string> | undefined
  endDate?: MayBeNull<string> | undefined
  assignUser?: string | null
}

interface WorkflowDataContext<T> {
  previousData?: { data: T }
}

export function useUpdateTaskItem({ filters }: { filters: TasksParams }) {
  const { showToast } = useToast()
  const { t } = useTranslation()

  const { mutateAsync: handleUpdateTask } = usePatchTaskApi({
    onMutate: async newTask => {
      const projectTasksQueryKey = [ApiQueryKeys.PROJECT_TASKS_LIST, { ...filters }]
      await queryClient.cancelQueries(projectTasksQueryKey)

      // Update the local state optimistically
      const { id, startDate, endDate, assignUser } = newTask
      const previousData = queryClient.getQueryData<{ data: Task[] }>(projectTasksQueryKey)

      if (previousData) {
        const updatedTasks = previousData.data.map((taskGroup: Task) => {
          if (taskGroup.id === id) {
            return {
              ...taskGroup,
              startDate: startDate !== undefined ? startDate : taskGroup.startDate,
              endDate: endDate !== undefined ? endDate : taskGroup.endDate,
              assignUser: assignUser !== undefined ? assignUser : taskGroup.assignUser,
            }
          }

          return taskGroup
        })

        const newData = { ...previousData, data: updatedTasks }

        queryClient.setQueryData(projectTasksQueryKey, newData)
      }
      return { previousData }
    },
    onError: (_err, _newTask, context) => {
      const typedContext: WorkflowDataContext<Task[]> = context || {}
      if (typedContext?.previousData) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_TASKS_LIST, { ...filters }], typedContext.previousData)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TASKS_LIST])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_DASHBOARD_DATA])
    },
  })

  const updateItem = async ({ ...props }: IProps) => {
    try {
      await handleUpdateTask(props)
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
    }
  }

  return { updateItem }
}
