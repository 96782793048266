import { WppNavSidebar, WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/projectDemo/ProjectDemo.module.scss'

export const ProjectDemoSkeleton = () => {
  return (
    <Flex className={styles.container}>
      <WppNavSidebar className={styles.sidebar} />
      <WppSkeleton variant="rectangle" height="100vh" />
    </Flex>
  )
}
