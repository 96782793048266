import * as zod from 'zod'

import { FormSelectOption } from 'components/form/formSelect/FormSelect'
import { ProcessType, ProjectType } from 'types/projects/projects'

export interface ProjectTypeOption extends FormSelectOption {
  value: ProjectType
  label: string
}

export const VALIDATION = {
  name: {
    min: 3,
    max: 120,
  },
  description: {
    min: 1,
    max: 600,
  },
  tag: {
    min: 1,
    max: 40,
  },
}

export const templateValidationSchema = zod
  .object({
    name: zod
      .string()
      .min(VALIDATION.name.min, 'Name should contain at least 3 character')
      .max(VALIDATION.name.max, 'Name exceeded the maximum number of characters'),
    description: zod
      .string()
      .max(VALIDATION.description.max, `Description should have ${VALIDATION.description.max} characters at most`),
    tag: zod
      .union([
        zod.literal(''),
        zod.intersection(
          zod.intersection(
            zod.string().min(VALIDATION.tag.min, 'Tag should contain at least 1 character'),
            zod.string().max(VALIDATION.tag.max, 'Tag exceeded the maximum number of characters'),
          ),
          zod.string().regex(/^(\d|\w|\s)+$/, 'Special characters are not allowed'),
        ),
      ])
      .optional()
      .transform(value => value || ''),
    tags: zod.string().array().max(10),
  })
  .passthrough()
  .refine(({ tag, tags }) => !tags.includes(tag), {
    message: 'Tag already exists',
    path: ['tag'],
  })
  .optional()

export const FORM_DEFAULTS = {
  name: '',
  description: '',
  tag: '',
  tags: [] as string[],
  projectTypes: [] as ProjectType[],
  processType: ProcessType.LINEAR,
} as const
