import { useOs } from '@wpp-open/react'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { useUpdateItem } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { LinearActivity } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/LinearActivity'
import { LinearApplication } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/LinearApplication'
import { isActivityItem, isApplicationItem } from 'pages/project/components/canvas/phaseUtils'
import { showConfirmExternalModal } from 'pages/project/components/members/components/confirmIExternalModal/ConfirmExternalModal'
import { ProjectMember } from 'types/projects/projectMembers'
import { PhaseItem } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

interface Props {
  phaseItem: PhaseItem
  index: number
  isIAssignToThisPhase?: boolean
  isEditable?: boolean
  toggleEditModal?: () => void
  variant?: 'primary' | 'secondary'
  preview?: boolean
  isDraggingDisabled?: boolean
  projectId: string
  isWrikeConnected?: boolean
  isTemplate?: boolean
  isOwnerOrGlobalManage?: boolean
}

export const Item = forwardRef<HTMLDivElement, Props>(
  (
    {
      phaseItem,
      index,
      isIAssignToThisPhase,
      isEditable,
      variant,
      toggleEditModal,
      preview,
      isDraggingDisabled,
      projectId,
      isWrikeConnected,
      isTemplate,
      isOwnerOrGlobalManage,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const {
      osContext: { userDetails },
    } = useOs()
    const { useExternalStatuses } = useProject() ?? {}

    const assignMember = useAssignMember(phaseItem.item.assignUser, projectId)

    const isMeAssignToThisApp = useMemo(
      () => isEqualEmails(userDetails.email, assignMember?.email),
      [userDetails, assignMember],
    )

    const showAction = !!isEditable && (isOwnerOrGlobalManage || isIAssignToThisPhase || isMeAssignToThisApp)

    const isDisabled = !!isEditable && !showAction

    const { updateItem } = useUpdateItem({
      id: phaseItem.item.id,
      type: phaseItem.itemType,
      name: phaseItem.item.name,
      projectId,
      useExternalStatuses,
    })

    const toggleAssignee = async (newAssignee: ResponsibleUser) => {
      const isDeselecting = assignMember?.id === newAssignee.id

      if (isDeselecting || (!newAssignee.isExternal && newAssignee.isMember)) {
        await updateItem({
          assignUser: isDeselecting ? null : newAssignee,
          analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_ASSIGN,
        })
      } else {
        showConfirmExternalModal({
          title: t(
            newAssignee.isExternal
              ? 'modals.invite_member.assign_external_title'
              : 'modals.invite_member.assign_member_title',
          ),
          description: t(
            newAssignee.isExternal
              ? newAssignee.isMember
                ? 'modals.invite_member.assign_external_description_member'
                : 'modals.invite_member.assign_external_description'
              : 'modals.invite_member.assign_member_description',
            {
              member: `${newAssignee.firstname} ${newAssignee.lastname}`,
              item: phaseItem.item.name,
            },
          ),
          withDelete: false,
          isMember: newAssignee.isMember,
          members: [newAssignee as unknown as ProjectMember],
          inviteMembers: async ids => {
            if (!!ids.length) {
              await updateItem({ assignUser: isDeselecting ? null : newAssignee })
            }
          },
        })
      }
    }

    const changeStatus = (status: string) =>
      updateItem({ status, analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_STATUS_CHANGE })

    const changeDates = (dates: Date[]) =>
      updateItem({ dates, analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DATES_EDIT })

    return (
      <div
        ref={ref}
        data-testid={`phase-item-card-${phaseItem.item.id}`}
        itemType={isApplicationItem(phaseItem) ? 'application' : 'activity'}
      >
        {isApplicationItem(phaseItem) && (
          <LinearApplication
            phaseId={phaseItem.phaseId}
            application={phaseItem.item}
            isEditable={isEditable}
            isDisabled={isDisabled}
            showAction={showAction}
            variant={variant}
            index={index}
            editApp={toggleEditModal}
            changeAssignee={toggleAssignee}
            changeDates={dates => changeDates(dates)}
            changeStatus={status => changeStatus(status)}
            changeHidden={hidden =>
              updateItem({ hidden, analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_VISIBILITY_CHANGE })
            }
            preview={preview}
            isDraggingDisabled={isDraggingDisabled}
            projectId={projectId}
            isWrikeConnected={isWrikeConnected}
            isTemplate={isTemplate}
          />
        )}
        {isActivityItem(phaseItem) && (
          <LinearActivity
            activity={phaseItem.item}
            phaseItemId={phaseItem.id}
            isDisabled={isDisabled}
            isEditable={!!isEditable}
            showAction={showAction}
            isIAssignToThisPhase={isIAssignToThisPhase}
            variant={variant}
            index={index}
            changeAssignee={toggleAssignee}
            changeDates={dates => changeDates(dates)}
            changeStatus={status => changeStatus(status)}
            editActivity={toggleEditModal}
            phaseId={phaseItem.phaseId}
            preview={preview}
            isDraggingDisabled={isDraggingDisabled}
            projectId={projectId}
            isWrikeConnected={isWrikeConnected}
            isTemplate={isTemplate}
          />
        )}
      </div>
    )
  },
)
