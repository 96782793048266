// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eMQD3{margin:20px 8px 0}.ciHxy{margin-top:20px;color:var(--wpp-grey-color-800);word-break:normal}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/viewSettingsModal/ViewSettingsModal.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAGF,OACE,eAAA,CACA,+BAAA,CACA,iBAAA","sourcesContent":[".togglesContainer {\n  margin: 20px 8px 0;\n}\n\n.hint {\n  margin-top: 20px;\n  color: var(--wpp-grey-color-800);\n  word-break: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"togglesContainer": `eMQD3`,
	"hint": `ciHxy`
};
export default ___CSS_LOADER_EXPORT___;
