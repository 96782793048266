import { MiroConfigStatus } from 'api/miro/fetchers/getMiroConfigStatus'
import { useGetMiroConfigStatus } from 'api/miro/queries/useGetMiroConfigStatus'
import { useGetMiroMe } from 'api/miro/queries/useGetMiroMe'

export const useMiroConnected = (enabled: boolean) => {
  const { error: miroProfileError, isFetching: isMiroProfileFetching } = useGetMiroMe({ enabled })
  const { data: miroConfigStatus, isFetching: isMiroConfigFetching } = useGetMiroConfigStatus({ enabled })

  const isMiroConfigured = miroConfigStatus?.status === MiroConfigStatus.VALID
  const isMiroConnected = enabled && !miroProfileError
  const isMiroNotConnected = enabled && miroProfileError

  return {
    isMiroFetching: isMiroProfileFetching || isMiroConfigFetching,
    isMiroConfigured,
    isMiroConnected,
    isMiroNotConnected,
  }
}
