import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { TenantGroup } from 'types/tenants/tenant'

interface Params {
  tenantId: string
}

export const fetchTenantGroupsApi = ({ tenantId, search, page = 1, itemsPerPage = 50 }: PaginationParams<Params>) =>
  facadeApi.get<PaginatedResponse<TenantGroup>>(`/tenants/${tenantId}/groups`, {
    params: { page, itemsPerPage, ...(search?.length ? { 'filter[search]': search } : {}) },
  })
