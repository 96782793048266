// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pIUB4{position:relative;height:max-content}.bz3Bz{position:relative;min-height:130px}.Ct0Wi{box-sizing:content-box;padding-bottom:80px}.uv8Su{position:absolute;z-index:10;width:348px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/linearCanvas/components/phase/DragPhase.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,kBAAA,CAGF,OACE,iBAAA,CACA,gBAAA,CAGF,OAEE,sBAAA,CACA,mBAAA,CAGF,OACE,iBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".dragPhaseWrapper {\n  position: relative;\n  height: max-content;\n}\n\n.column {\n  position: relative;\n  min-height: 130px; // in order to be able to place tasks into empty phases\n}\n\n.isSomeItemDragging {\n  // to avoid reduce of the column minimum height\n  box-sizing: content-box;\n  padding-bottom: 80px;\n}\n\n.phasePlaceholder {\n  position: absolute;\n  z-index: 10;\n  width: 348px; // same as set in `Phase.module.scss`\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragPhaseWrapper": `pIUB4`,
	"column": `bz3Bz`,
	"isSomeItemDragging": `Ct0Wi`,
	"phasePlaceholder": `uv8Su`
};
export default ___CSS_LOADER_EXPORT___;
