import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ApplicationMoveRequest } from 'api/canvas/fetchers/moveApplicationApi'
import { useMoveApplicationApi } from 'api/canvas/mutation/useMoveApplicationApi'
import { ACTION_ANALYTICS, CanvasItemType } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { LinearData } from 'pages/project/components/canvas/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { queryClient } from 'providers/osQueryClient/utils'

export type PatchLinearAppProps = ApplicationMoveRequest & Required<Pick<ApplicationMoveRequest, 'optimisticData'>>

export function useMoveLinearApp({ projectId }: { projectId: string }) {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const projectContext = useProject()
  const { trackAction } = useTrackAction()
  const { me } = useHasProjectRole()

  const { mutateAsync: handlePatchApp, isLoading } = useMoveApplicationApi({
    onMutate: async patchData => {
      const projectLinearQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }]
      await queryClient.cancelQueries(projectLinearQueryKey)

      const previousLinear = queryClient.getQueryData<{ data: LinearData }>(projectLinearQueryKey)

      if (previousLinear) {
        const newData = {
          ...previousLinear,
          data: {
            ...previousLinear.data,
            ...patchData.optimisticData,
          },
        }

        queryClient.setQueryData(projectLinearQueryKey, newData)
      }

      return { previousLinear }
    },
    onError: (_error, _, context) => {
      if (context?.previousLinear) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }], context.previousLinear)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    },
  })

  const moveApp = useCallback(
    async (params: PatchLinearAppProps) => {
      try {
        await handlePatchApp(params)
        if (projectContext) {
          const { project } = projectContext
          const analyticsData = {
            ...getCommonProjectAnalyticsData(project, me),
            type: CanvasItemType.APPLICATION,
          }

          trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_MOVE, analyticsData)
        }
      } catch (error) {
        showToast({
          type: 'error',
          message: t('common.generic_error'),
        })
        console.error(error)
      }
    },
    [handlePatchApp, me, projectContext, showToast, t, trackAction],
  )

  return { moveApp, isLoading }
}
