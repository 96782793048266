import { WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal.module.scss'
import { NativeAppVersionDTO } from 'types/products/nativeApp'

interface Props {
  options: NativeAppVersionDTO[]
  selectedVersions: NativeAppVersionDTO[]
  onSelectVersions: (version: NativeAppVersionDTO[]) => void
  onHideDropdown: () => void
}

export interface SelectVersionRef {
  openDropdown: () => void
}

export const SELECT_VERSION_CLASS_TRIGGER = 'selectVersionClassTrigger'
export const SELECT_ITEM_CLASS_TRIGGER = 'selectItemClassTrigger'

export const SelectVersion = forwardRef<SelectVersionRef, Props>(
  ({ options, onSelectVersions, selectedVersions, onHideDropdown }, ref) => {
    const { t } = useTranslation()
    const selectRef = useRef<HTMLWppSelectElement>(null)

    useImperativeHandle(ref, () => ({
      openDropdown() {
        const inputElement = (selectRef.current as any)?.host.shadowRoot.querySelector('.input') as HTMLDivElement
        inputElement?.click()
      },
    }))

    const optionsMap = useMemo(() => Object.fromEntries(options.map(version => [version.id, version])), [options])

    return (
      <WppSelect
        value={selectedVersions.map(({ id }) => id)}
        onWppChange={e => {
          const selectedId = e.detail.value as string[]
          onSelectVersions(selectedId.map(id => optionsMap[id]))
        }}
        type="multiple"
        size="s"
        className={clsx(SELECT_VERSION_CLASS_TRIGGER, styles.selectVersionMenu, styles.wrapText)}
        ref={selectRef}
        placeholder={t('modals.app_create_card.placeholder')}
        dropdownConfig={{
          onHidden: () => {
            selectRef.current?.blur()
            onHideDropdown()
          },
        }}
        data-testid="app-versions-select"
      >
        {options.map(option => (
          <WppListItem
            key={option.id}
            value={option.id}
            data-testid="app-version-option"
            className={SELECT_ITEM_CLASS_TRIGGER}
            data-version-value={option.id}
          >
            <span slot="label" className={SELECT_ITEM_CLASS_TRIGGER} data-version-value={option.id}>
              {option.name} {`(${option.versionType.toLowerCase()})`}
            </span>
          </WppListItem>
        ))}
      </WppSelect>
    )
  },
)
