import { useOs } from '@wpp-open/react'
import { useEffect, ReactNode, useState } from 'react'

import i18n from 'i18n/i18n'
import { capitalizeFirstLetter } from 'utils/common'

export const TaxonomyProvider = ({ children }: { children: ReactNode }) => {
  const { osContext } = useOs()
  const [isTaxonomyInjected, setIsTaxonomyInjected] = useState(false)

  useEffect(() => {
    if (!isTaxonomyInjected && osContext.taxonomy && i18n.options.interpolation) {
      i18n.options.interpolation.defaultVariables = Object.entries(osContext.taxonomy).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
          [capitalizeFirstLetter(key)]: capitalizeFirstLetter(value),
        }),
        {},
      )
      setIsTaxonomyInjected(true)
    }
  }, [isTaxonomyInjected, osContext])

  return isTaxonomyInjected && children
}
