import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { useToast } from 'hooks/useToast'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'
import { useInitiateMiroOAuth2Flow } from 'utils/miro'

interface Props extends NiceModalWrappedProps {
  email: string
}

const ConnectMiroToUserProfileModal = ({ id, isOpen, onClose, onCloseComplete, email }: Props) => {
  const { t } = useTranslation()
  const { handleInitiateMiroOAuth2Flow } = useInitiateMiroOAuth2Flow()
  const { showToast } = useToast()

  const [{ loading }, onGenerateAuthCodeClick] = useAsyncFn(async () => {
    try {
      await handleInitiateMiroOAuth2Flow()
      onClose()
    } catch {
      showToast({
        message: t('common.generic_error'),
        type: 'error',
      })
      onClose()
    }
  }, [handleInitiateMiroOAuth2Flow, showToast, t, onClose])

  return (
    <Modal data-testid={id} open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete}>
      <WppTypography slot="header" type="xl-heading">
        {t('integrations.miro.connect_title')}
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        {t('integrations.miro.connect_description', { email })}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s">
          {t('common.btn_cancel')}
        </WppButton>
        <WppButton onClick={onGenerateAuthCodeClick} size="s" loading={loading}>
          {t('integrations.connect_account')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showConnectMiroToUserProfileModal } = createNiceModal(
  ConnectMiroToUserProfileModal,
  'connect-miro-to-user-profile-modal',
)
