import { projectApi } from 'api'
import { ConnectionType, EdgePosition } from 'pages/project/components/canvas/fluidCanvas/utils'

export interface NewConnectionProps {
  type: ConnectionType
  sourceId: string
  sourceAnchorSide: EdgePosition
  targetId: string
  targetAnchorSide: EdgePosition
  sourceItemType?: string
  targetItemType?: string
}

export const createFluidConnection = (body: NewConnectionProps) => projectApi.post('/fluid_connections', body)
