import { projectApi } from 'api'
import { ExternalLink } from 'types/projects/workflow'

interface Props {
  activityId: string
  externalLinks: ExternalLink[]
}

export const addActivityLinks = ({ activityId, ...data }: Props) =>
  projectApi.post(`/activities/${activityId}/external-links`, { ...data })
