// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RXtli{margin-right:8px;margin-left:-8px}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/EditProjectModal.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CACA,gBAAA","sourcesContent":[".backButton {\n  margin-right: 8px;\n  margin-left: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": `RXtli`
};
export default ___CSS_LOADER_EXPORT___;
