import { projectApi } from 'api'
import { DashboardOwnership } from 'types/projects/DashboardData'
import { Task, TaskDueDate } from 'types/projects/tasks'

export interface TasksParams {
  projectId: string
  text?: string
  subset?: DashboardOwnership
  statuses?: string[]
  dueDateRanges?: (keyof typeof TaskDueDate)[]
}

export const fetchProjectTasksApi = ({ projectId, text, subset, statuses, dueDateRanges }: TasksParams) =>
  projectApi.post<Task[]>(`/projects/${projectId}/tasks/search`, {
    ...(text?.length && { text }),
    ...(statuses?.length && { statuses }),
    dueDateRanges,
    subset,
  })
