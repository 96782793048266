import { WppInlineMessage } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { AxiosError } from 'axios'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { MiroConnect } from 'pages/components/projectModal/components/integrations/miro/MiroConnect'
import { WrikeConnect } from 'pages/components/projectModal/components/integrations/wrike/WrikeConnect'
import { WrikeIntegrationsMessage } from 'pages/components/projectModal/components/integrations/wrike/WrikeIntegrationsMessage'

interface Props {
  // miro
  showMiroConnect: boolean
  miroNotConfigured: boolean
  isMiroConnected: boolean

  // wrike
  showWrikeConnect: boolean
  wrikeError: MayBeNull<AxiosError<any>>
}

export const Integrations = ({
  showWrikeConnect,
  wrikeError,
  showMiroConnect,
  isMiroConnected,
  miroNotConfigured,
}: Props) => {
  const { t } = useTranslation()
  const {
    formState: { submitCount },
  } = useFormContext()

  const showMiroConnectWarning = showMiroConnect && !isMiroConnected && submitCount > 0

  return (
    <>
      {/* Warnings */}
      {miroNotConfigured && (
        <Flex className="wpp-spacing-24-bottom">
          <WppInlineMessage
            size="l"
            hideCloseBtn
            titleText={t('integrations.miro.tenant_not_configured_title')!}
            message={t('integrations.miro.tenant_not_configured_text')!}
            type="error"
          />
        </Flex>
      )}
      {showMiroConnectWarning && (
        <Flex className="wpp-spacing-24-bottom">
          <WppInlineMessage size="m" message={t('integrations.miro.connect_template_description')!} type="error" />
        </Flex>
      )}

      {/* action buttons */}
      {showWrikeConnect && (
        <Flex direction="column" className="wpp-spacing-24-bottom" gap={16}>
          <WrikeConnect wrikeError={wrikeError} />
        </Flex>
      )}

      {showMiroConnect && (
        <Flex direction="column" className="wpp-spacing-24-bottom" gap={16}>
          <MiroConnect disabled={miroNotConfigured} isConnected={isMiroConnected} />
        </Flex>
      )}

      <WrikeIntegrationsMessage />
    </>
  )
}
