import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'

import { TasksParams } from 'api/projects/fetchers/fetchProjectTasksApi'
import { Flex } from 'components/common/flex/Flex'
import { useProject } from 'hooks/useProject'
import { useUpdateTaskColumn } from 'pages/project/components/tasks/hooks/useUpdateTaskColumn'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { TasksStatusColumn } from 'pages/project/components/tasks/TasksStatusColumn'
import { allStatuses, groupTasks } from 'pages/project/components/tasks/utils'
import { Task } from 'types/projects/tasks'
import { CustomStatus } from 'types/projects/WrikeWorkflows'

export const TasksSkeleton = () => (
  <Flex direction="row" gap={28} className={styles.tasksContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
  </Flex>
)

interface Props {
  tasks: Task[]
  useExternalStatuses: boolean
  mappedFilters: TasksParams
  hideSubHeader: boolean
  statuses?: string[]
}

export const TasksStatusColumns = ({ tasks, useExternalStatuses, mappedFilters, statuses }: Props) => {
  const { project, wrikeWorkflows } = useProject()

  const isDefaultStatusFilter = !statuses?.length

  const { updateItem } = useUpdateTaskColumn({ filters: mappedFilters })

  const externalStatuses = useMemo(() => {
    if (!wrikeWorkflows?.customStatuses) return []
    const customStatuses = isDefaultStatusFilter
      ? wrikeWorkflows?.customStatuses.map(customStatus => customStatus.wrikeId)
      : wrikeWorkflows?.statusMappings
          .filter(customStatus => {
            return statuses!.includes(customStatus.wrikeStatusId) && customStatus.direction === 'to_os'
          })
          .map(customStatus => customStatus.wrikeStatusId)

    return customStatuses as unknown as string[]
  }, [isDefaultStatusFilter, statuses, wrikeWorkflows?.customStatuses, wrikeWorkflows?.statusMappings])

  const statusColumns = useMemo(
    () => (isDefaultStatusFilter ? allStatuses : allStatuses.filter(status => statuses!.includes(status))),
    [isDefaultStatusFilter, statuses],
  )

  const mappedTasks = useMemo(
    () =>
      groupTasks({
        tasks,
        statuses: useExternalStatuses ? externalStatuses : allStatuses,
        useExternalStatuses: !!useExternalStatuses,
      }),
    [externalStatuses, tasks, useExternalStatuses],
  )

  const mappedGroupedTasks = useMemo(() => Object.entries(mappedTasks), [mappedTasks])

  return (
    <>
      {useExternalStatuses
        ? mappedGroupedTasks.map(([key, statuses]) => (
            <Flex key={key} gap={28} className={styles.flexGrow} data-testid="tasks-container">
              {wrikeWorkflows?.customStatuses.map((customStatus: CustomStatus) => {
                const tasks = statuses[customStatus.wrikeId]

                if (!tasks) return null

                return (
                  <TasksStatusColumn
                    statusKey={customStatus.wrikeId}
                    name={customStatus.name}
                    tasks={tasks}
                    key={customStatus.wrikeId}
                    projectId={project.id}
                    updateItem={updateItem}
                    filters={mappedFilters}
                    useExternalStatuses={true}
                    isWrikeConnected={!!project.wrike?.isConnected}
                  />
                )
              })}
            </Flex>
          ))
        : mappedGroupedTasks.map(([key, statuses]) => (
            <Flex key={key} gap={28} className={styles.flexGrow} data-testid="tasks-container">
              {statusColumns.map(statusKey => {
                const tasks = statuses[statusKey]
                if (!tasks) return null

                return (
                  <TasksStatusColumn
                    statusKey={statusKey}
                    name={statusKey}
                    tasks={tasks}
                    key={statusKey}
                    projectId={project.id}
                    updateItem={updateItem}
                    filters={mappedFilters}
                    isWrikeConnected={!!project.wrike?.isConnected}
                  />
                )
              })}
            </Flex>
          ))}
    </>
  )
}
