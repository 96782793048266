import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import camelCase from 'lodash.camelcase'
import { FormProvider } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { CreateProjectAttributes } from 'api/projects/fetchers/addAttributesApi'
import { UpdateProjectAttributesResponse } from 'api/projects/fetchers/updateAttributesApi'
import { useAddAttributesApi } from 'api/projects/mutation/useAddAttributesApi'
import { useUpdateAttributesApi } from 'api/projects/mutation/useUpdateAttributesApi'
import { useProjectAttributesApi } from 'api/projects/queries/useProjectAttributesApi'
import { showConfirmModal } from 'components/common/confirmModal/ConfirmModal'
import { Flex } from 'components/common/flex/Flex'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSegmentControl } from 'components/form/formSegmentControl/FormSegmentControl'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import {
  addAttributesSchema,
  FormValues,
  useTypeOptions,
} from 'pages/settings/attributes/components/addAttributeModal/utils'
import { MultiValues } from 'pages/settings/attributes/components/multiValues/MultiValues'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectAttribute, ProjectAttributeClass, ProjectAttributeType } from 'types/projects/attributes'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const requiredOptions = [
  { label: 'Optional', value: 'optional' },
  { label: 'Mandatory', value: 'required' },
]

const titles: Record<string, string> = {
  helperText: 'Helper text',
  iconText: 'Info icon details',
  enabled: 'Attribute enablement',
  required: 'Requirement configurations',
}

const defaultValues: FormValues = {
  name: '',
  helperText: '',
  iconText: '',
  externalKey: '',
  enabled: true,
  required: 'optional',
  type: ProjectAttributeType.TEXT_INPUT,
  valueOptions: [],
}

interface Props extends NiceModalWrappedProps {
  data?: ProjectAttribute
}

const AttributeModal = ({ isOpen, onClose, onCloseComplete, data: editAttribute }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    osContext: { tenant },
  } = useOs()

  const { data: attributes } = useProjectAttributesApi({
    enabled: !editAttribute,
  })
  const { mutateAsync: handleAddAttributes } = useAddAttributesApi()
  const { mutateAsync: handleUpdateAttributes } = useUpdateAttributesApi()
  const typeOptions = useTypeOptions()

  const form = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      ...editAttribute,
      required: editAttribute?.required ? 'required' : 'optional',
    },
    validationSchema: addAttributesSchema(!!editAttribute ? [] : attributes),
  })
  const {
    getValues,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = form

  const [type] = watch(['type'])

  const onSubmit = handleSubmit(async () => {
    const { required, ...rest } = getValues()

    try {
      if (editAttribute && editAttribute.id) {
        const data: UpdateProjectAttributesResponse = {
          name: rest.name.trim(),
          contractName: camelCase(rest.name.trim()),
          enabled: rest.enabled,
          required: required === 'required',
          helperText: rest.helperText,
          iconText: rest.iconText,
          externalKey: rest.externalKey,
          valueOptions: rest.valueOptions,
          defaultValue: null,
        }

        const haveChangesForAllProjects = ['helperText', 'iconText']
          .filter(
            key => editAttribute[key as keyof ProjectAttribute] !== data[key as keyof UpdateProjectAttributesResponse],
          )
          .map(key => titles[key])
          .join(', ')

        const haveChangesForNewProjects = ['enabled', 'required']
          .filter(
            key => editAttribute[key as keyof ProjectAttribute] !== data[key as keyof UpdateProjectAttributesResponse],
          )
          .map(key => titles[key])
          .join(', ')

        if (!!haveChangesForAllProjects.length || !!haveChangesForNewProjects.length) {
          showConfirmModal({
            title: t('project.settings.attributes_modal.save_changes'),
            btnSubmitText: t('common.btn_save'),
            confirmMessage: haveChangesForAllProjects.length ? (
              <Trans
                i18nKey="project.settings.attributes_modal.update_all_projects"
                values={{ attributes: haveChangesForAllProjects }}
                components={{ bold: <WppTypography type="s-strong" /> }}
              />
            ) : (
              ''
            ),
            confirmSubMessage: haveChangesForNewProjects.length ? (
              <Trans
                i18nKey="project.settings.attributes_modal.update_new_projects"
                values={{ attributes: haveChangesForNewProjects }}
                components={{ bold: <WppTypography type="s-strong" /> }}
              />
            ) : (
              ''
            ),
            handleSubmit: async () => {
              await handleUpdateAttributes({ id: editAttribute.id, data })
              queryClient.invalidateQueries([ApiQueryKeys.PROJECT_ATTRIBUTES_BLUEPRINT])
              showToast({ message: t('project.settings.attributes_modal.attribute_updated'), type: 'success' })
              onClose()
            },
          })
        } else {
          await handleUpdateAttributes({ id: editAttribute.id, data })
          queryClient.invalidateQueries([ApiQueryKeys.PROJECT_ATTRIBUTES_BLUEPRINT])
          showToast({ message: t('project.settings.attributes_modal.attribute_updated'), type: 'success' })
          onClose()
        }
      } else {
        const data: CreateProjectAttributes[] = [
          {
            ...rest,
            name: rest.name.trim(),
            required: required === 'required',
            tenantId: tenant.id,
            contractName: camelCase(rest.name.trim()),
            classification: ProjectAttributeClass.CUSTOM,
            valueOptions: rest.type === ProjectAttributeType.MULTI_SELECT ? rest.valueOptions : null,
            defaultValue: null,
          },
        ]

        await handleAddAttributes({ data })
        showToast({ message: t('project.settings.attributes_modal.attribute_create'), type: 'success' })
        queryClient.invalidateQueries([ApiQueryKeys.PROJECT_ATTRIBUTES_BLUEPRINT])
        onClose()
      }
    } catch (e) {
      console.error(e)
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="s"
      >
        <WppTypography slot="header" type="2xl-heading">
          {editAttribute ? 'Edit Attribute' : 'Add Attribute'}
        </WppTypography>
        <div slot="body">
          <Flex direction="column" gap={24}>
            <FormInput
              name="name"
              labelConfig={{
                text: t('project.settings.attributes_modal.form_name'),
              }}
              disabled={!!editAttribute}
              data-testid="attribute-name"
              required
            />
            <FormInput
              name="helperText"
              data-testid="attribute-helper-text"
              labelConfig={{
                text: t('project.settings.attributes_modal.form_helper_text'),
                icon: 'wpp-icon-info',
                description: 'Helper text appears beneath the input.',
              }}
            />
            <FormInput
              name="iconText"
              data-testid="attribute-info-text"
              labelConfig={{
                text: t('project.settings.attributes_modal.form_info_icon'),
                icon: 'wpp-icon-info',
                description: 'Info text is shown while hovering over the info icon',
              }}
            />
            {/* we not need this field on a start*/}
            {/*<FormInput*/}
            {/*  name="externalKey"*/}
            {/*  labelConfig={{*/}
            {/*    text: t('project.settings.attributes_modal.form_external_key'),*/}
            {/*    icon: 'wpp-icon-info',*/}
            {/*    description: 'Link attribute to an external platform for auto-sync',*/}
            {/*  }}*/}
            {/*  disabled={!!editAttribute}*/}
            {/*/>*/}
            <FormCheckbox
              name="enabled"
              data-testid="attribute-enabled"
              labelConfig={{ text: t('project.settings.attributes_modal.form_enabled') }}
              required
            />
            <FormSegmentControl
              name="required"
              data-testid="attribute-required"
              required
              options={requiredOptions}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
              labelConfig={{ text: t('project.settings.attributes_modal.form_required') }}
            />
            <FormSelect
              name="type"
              data-testid="attribute-type"
              required
              labelConfig={{
                text: 'Type',
                icon: 'wpp-icon-info',
                description: 'More types will be added soon',
              }}
              options={typeOptions}
              disabled={!!editAttribute}
            />

            {type === ProjectAttributeType.MULTI_SELECT && <MultiValues initialValues={editAttribute?.valueOptions} />}
          </Flex>
        </div>
        <Flex justify="end" slot="actions" gap={8}>
          <WppButton variant="secondary" size="m" onClick={onClose} data-testid="cancel-attribute">
            {t('modals.create_project.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} data-testid="submit-attribute">
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showAttributeModal } = createNiceModal<Props>(AttributeModal, 'add-attribute-modal')
