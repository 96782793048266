import {
  WppActionButton,
  WppButton,
  WppIconReset,
  WppLabel,
  WppSkeleton,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUpdateAttributesApi } from 'api/projects/mutation/useUpdateProjectsViewSettingsApi'
import { useFetchProjectsViewSettingsApi } from 'api/projects/queries/useFetchProjectsViewSettingsApi'
import { Flex } from 'components/common/flex/Flex'
import { FormToggle } from 'components/form/formToggle/FormToggle'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectAttributeClass } from 'types/projects/attributes'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const Skeleton = () => (
  <>
    <WppSkeleton slot="header" variant="rectangle" height="35px" width="320px" />
    <Flex direction="column" slot="body" gap={16}>
      <WppSkeleton variant="rectangle" height="600px" width="100%" />
    </Flex>
    <Flex slot="actions" gap={12} justify="end">
      <WppSkeleton slot="header" variant="rectangle" height="40px" width="87px" />
      <WppSkeleton slot="header" variant="rectangle" height="40px" width="87px" />
    </Flex>
  </>
)

const ProjectsViewSettingsModal = ({ isOpen, onClose, onCloseComplete }: NiceModalWrappedProps) => {
  const { t } = useTranslation()
  const { data: settings, isLoading: isSettingsLoading } = useFetchProjectsViewSettingsApi()
  const { mutateAsync: updateSettings } = useUpdateAttributesApi()

  const defaultValues = settings.reduce(
    (acc, { name, classification }) => ({
      ...acc,
      [name]: classification === ProjectAttributeClass.SYSTEM,
    }),
    {},
  )

  const form = useForm({
    defaultValues: {
      ...defaultValues,
      ...settings.reduce(
        (acc, { name, enabled }) => ({
          ...acc,
          [name]: enabled,
        }),
        {},
      ),
    },
  })

  const {
    watch,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form

  const onReset = () => reset(defaultValues)
  const onSubmit = handleSubmit(async values => {
    try {
      await updateSettings({
        data: settings
          .filter(({ id }) => Boolean(id))
          .map(({ id, name }) => ({
            id,
            enabled: values[name as keyof typeof values],
          })),
      })
      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_VIEW_SETTINGS])
      onClose()
    } catch (e) {
      console.error(e)
    }
  })

  const values = watch()
  const resetDisabled = JSON.stringify(values) === JSON.stringify(defaultValues)

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="s"
        data-testid="projects-view-settings-modal"
      >
        {isSettingsLoading ? (
          <Skeleton />
        ) : (
          <>
            <WppTypography slot="header" type="2xl-heading" data-testid="settings-header">
              Projects view settings
            </WppTypography>
            <Flex slot="body" direction="column" gap={18}>
              {settings.map(attr => (
                <Flex justify="between" key={attr.id || attr.name}>
                  <WppLabel typography="s-body">{attr.name}</WppLabel>
                  <FormToggle
                    disabled={attr.classification === ProjectAttributeClass.SYSTEM}
                    name={attr.name}
                    data-testid={`toggle-row-${attr.name}`}
                  />
                </Flex>
              ))}
            </Flex>
            <Flex justify="between" slot="actions">
              <WppActionButton disabled={resetDisabled} onClick={onReset} data-testid="projects-view-settings-reset">
                <WppIconReset slot="icon-start" /> {t('common.btn_reset')}
              </WppActionButton>
              <Flex gap={12}>
                <WppButton variant="secondary" size="m" onClick={onClose}>
                  {t('modals.dashboard_filters.btn_cancel')}
                </WppButton>
                <WppButton
                  variant="primary"
                  size="m"
                  type="submit"
                  loading={isSubmitting}
                  data-testid="projects-view-settings-submit"
                >
                  {t('common.btn_save')}
                </WppButton>
              </Flex>
            </Flex>
          </>
        )}
      </SideModal>
    </FormProvider>
  )
}
export const { showModal: showProjectsViewSettingsModal } = createNiceModal<NiceModalWrappedProps>(
  ProjectsViewSettingsModal,
  'projects-view-settings-modal',
)
