import { useMemo } from 'react'

interface UsePaginatorReturnType {
  itemsLoadedSoFar: number
  itemsRemaining: number
}

export const useRemainingPaginationItems = ({
  itemsPerPage = 0,
  page = 1,
  totalItems = 0,
}: {
  itemsPerPage?: number
  page?: number
  totalItems?: number
} = {}): UsePaginatorReturnType => {
  const currentPage = Math.max(1, page)
  const firstPageItemsLoaded = Math.min(itemsPerPage, totalItems)

  const itemsLoadedSoFar = useMemo(() => {
    return currentPage === 1 ? firstPageItemsLoaded : (currentPage - 1) * itemsPerPage + firstPageItemsLoaded
  }, [currentPage, itemsPerPage, firstPageItemsLoaded])

  const itemsRemaining = useMemo(() => {
    return Math.max(0, totalItems - itemsLoadedSoFar)
  }, [totalItems, itemsLoadedSoFar])

  return {
    itemsLoadedSoFar,
    itemsRemaining,
  }
}
