// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aU7Tt{padding-bottom:24px}.sOBIK{color:var(--wpp-grey-color-700)}`, "",{"version":3,"sources":["webpack://./src/pages/templates/components/createNewTemplateModal/CreateNewTemplateModal.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,+BAAA","sourcesContent":[".container {\n  padding-bottom: 24px;\n}\n\n.greyColor700 {\n  color: var(--wpp-grey-color-700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `aU7Tt`,
	"greyColor700": `sOBIK`
};
export default ___CSS_LOADER_EXPORT___;
