import { SideModalFormConfig } from '@platform-ui-kit/components-library'
import { WppSideModal } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, ComponentProps } from 'react'

import { Portal } from 'components/common/portal/Portal'
import styles from 'components/surface/sideModal/SideModal.module.scss'

export type SideModalProps = Omit<ComponentPropsWithoutRef<typeof WppSideModal>, 'formConfig'> & {
  /**
   * If you pass this prop wrapper of dialog will be rendered as form.
   */
  formConfig?: Pick<ComponentProps<'form'>, 'onSubmit' | 'onReset'>
}

export const SideModal = forwardRef<HTMLWppSideModalElement, SideModalProps>(
  ({ formConfig, className, ...rest }, ref) => (
    <Portal>
      <WppSideModal
        ref={ref}
        className={clsx(styles.sideModal, className)}
        {...rest}
        formConfig={formConfig as SideModalFormConfig}
      />
    </Portal>
  ),
)
