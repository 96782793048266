import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'react-use'

import { useToast } from 'hooks/useToast'

export enum CopyType {
  PROJECT = 'PROJECT',
  TASK = 'TASK',
  FILE = 'FILE',
}

export const useCopyUrlToClipboard = (type: CopyType) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const [, copyToClipboard] = useCopyToClipboard()

  const i18 = useMemo(
    () => ({
      [CopyType.PROJECT]: t('project.toast_copy_to_clipboard_success_message')!,
      [CopyType.TASK]: t('project.tasks.toast_copy_to_clipboard_success_message')!,
      [CopyType.FILE]: t('project.files.toast_copy_to_clipboard_success_message')!,
    }),
    [t],
  )

  const copyToClipboardAction = useCallback(
    (salt?: any | string) => {
      copyToClipboard(`${window.location.href}${typeof salt === 'string' ? salt : ''}`)
      showToast({ type: 'success', message: i18[type] })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copyToClipboard],
  )

  return { copyToClipboardAction }
}
