import { projectApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'

interface CanvasLockParams {
  id: string
}

interface LockData {
  email: string
  expiredAt: number
}

interface LockErrorData {
  detail: {
    code: 'CANVAS_ALREADY_LOCKED'
    message: string
    data: LockData
  }
}

export enum LockStatus {
  Locked = 'Locked',
  Unlocked = 'Unlocked',
}

interface LockResponse {
  status: LockStatus
  data: LockData
}

export const canvasLockApi = ({ id }: CanvasLockParams): CancelableRequestProducer<LockResponse> => {
  return signal =>
    projectApi.client
      .put(`/projects/${id}/canvas/lock`, null, { signal })
      .then(response => {
        return {
          ...response,
          data: {
            status: LockStatus.Unlocked,
            data: response.data as LockData,
          },
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          const lockError = error.response.data as LockErrorData

          return {
            ...error.response,
            data: {
              status: LockStatus.Locked,
              data: lockError.detail.data,
            },
          }
        }

        return error
      })
}
