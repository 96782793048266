import { useEffect } from 'react'

interface Props {
  loadMoreRef: HTMLElement
  isFetchingNextPage: boolean
  fetchNextPage: () => Promise<any>
  hasNextPage?: boolean
}

export const useInfiniteFetchNextPage = ({ loadMoreRef, isFetchingNextPage, fetchNextPage, hasNextPage }: Props) => {
  useEffect(() => {
    if (!hasNextPage || !loadMoreRef) return
    const observer = new IntersectionObserver(entries =>
      entries.forEach(entry => entry.isIntersecting && !isFetchingNextPage && fetchNextPage()),
    )

    observer.observe(loadMoreRef)
    return () => {
      observer.disconnect()
    }
  }, [loadMoreRef, hasNextPage, fetchNextPage, isFetchingNextPage])
}
