import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { LinearData, mapCanvasData } from 'pages/project/components/canvas/utils'
import { FluidWorkflow, Workflow } from 'types/projects/workflow'

interface Params {
  id: string
}

export const fetchWorkflowApiLinear = ({ id }: Params) =>
  projectApi.get<LinearData>(`/projects/${id}/canvas/linear`, {
    transformResponse: (response: string, headers, status) => {
      let linearWorkflow: MayBeNull<Workflow> = null
      if (status === 200) {
        try {
          linearWorkflow = JSON.parse(response)
        } catch (e) {
          console.error('Unable to parse linear response data', e)
        }
      }

      return mapCanvasData(linearWorkflow)
    },
  })
export const fetchWorkflowApiFluid = ({ id }: Params) => projectApi.get<FluidWorkflow>(`/projects/${id}/canvas/fluid`)
