import { WppTypography, WppIconArrow } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { TaskPriority } from 'types/projects/tasks'

interface Props {
  hideLabel?: boolean
  priority?: TaskPriority
  large?: boolean
}

const iconColors = {
  [TaskPriority.HIGH]: 'var(--wpp-danger-color-500)',
  [TaskPriority.LOW]: 'var(--wpp-grey-color-700)',
}

export const TaskPriorityIndicator = ({ priority = TaskPriority.NORMAL, hideLabel, large }: Props) => {
  const { t } = useTranslation()

  return (
    <Flex gap={4} align="center">
      {priority !== TaskPriority.NORMAL && (
        <WppIconArrow
          data-testid="priority-icon"
          direction={priority === TaskPriority.HIGH ? 'top' : 'down'}
          color={iconColors[priority]}
        />
      )}
      {!hideLabel && (
        <WppTypography type={large ? 's-strong' : 's-body'} className={styles[priority]}>
          {t(`project.tasks.priority.${priority}`)!}
        </WppTypography>
      )}
    </Flex>
  )
}
