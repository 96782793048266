import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  title: string
  subTitle: ReactNode

  closeText?: string
  deleteText?: string

  onDelete(): void
}

const DeleteModal = ({ isOpen, title, subTitle, closeText, deleteText, onClose, onDelete, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const [isDeleting, setDeleting] = useState(false)

  const handleDelete = async () => {
    setDeleting(true)

    await onDelete()
    onClose()
  }

  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete}>
      <Flex gap={8} slot="header" align="start" data-testid="delete-group-modal-title">
        <WppTypography slot="header" type="xl-heading">
          {title}
        </WppTypography>
      </Flex>
      <WppTypography slot="body" type="s-body" data-testid="delete-group-modal-text">
        <WppTypography>{subTitle}</WppTypography>
      </WppTypography>
      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} size="s" variant="secondary">
          {closeText || t('common.btn_cancel')}
        </WppButton>
        <WppButton loading={isDeleting} onClick={handleDelete} size="s" variant="destructive">
          {deleteText || t('common.btn_delete')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteModal } = createNiceModal<Props>(DeleteModal, 'delete-modal')
