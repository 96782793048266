import { LabelConfig, LabelLocales } from '@platform-ui-kit/components-library'
import { DropdownConfig } from '@platform-ui-kit/components-library/dist/types/types/common'
import { useMemo } from 'react'

export const useCommonLabelProps = ({
  labelConfig,
  labelTooltipConfig,
}: {
  labelConfig?: LabelConfig
  labelTooltipConfig?: DropdownConfig
}) => {
  const labelLocales = useMemo<LabelLocales>(
    () => ({
      optional: 'Optional',
    }),
    [],
  )

  let labelConfigResult = labelConfig
  let labelTooltipConfigResult = labelTooltipConfig

  if (labelConfigResult) {
    const { text, description, icon, locales = labelLocales } = labelConfigResult

    labelConfigResult = {
      text,
      description,
      locales,
      icon,
      ...(!icon && !!description && { icon: 'wpp-icon-info' }),
    }

    if (labelTooltipConfigResult) {
      const { placement, ...rest } = labelTooltipConfigResult

      labelTooltipConfigResult = {
        ...rest,
        ...(!!description && !placement && { placement: 'right' }),
      }
    } else {
      if (description) {
        labelTooltipConfigResult = { placement: 'right' }
      }
    }
  }

  return { labelConfig: labelConfigResult, labelTooltipConfig: labelTooltipConfigResult }
}
