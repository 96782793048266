import { WppButton } from '@platform-ui-kit/components-library-react'

import { t } from 'i18next'
import { AppDetailsLaunchApp } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/detailsModal/AppDetailsLaunchApp'
import { MayBeNull } from 'types/common/utils'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: MayBeNull<ApplicationItem>
  onClose: () => void
  activityId?: string
  phaseId?: string
}

export const AppDetailsModalActions = ({ application, activityId, phaseId, onClose }: Props) => {
  return (
    <>
      {application ? (
        <AppDetailsLaunchApp application={application} activityId={activityId} phaseId={phaseId} onClose={onClose} />
      ) : (
        <WppButton variant="primary" size="m" onClick={onClose}>
          {t('common.btn_done')}
        </WppButton>
      )}
    </>
  )
}
