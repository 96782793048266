import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { NativeAppType } from 'types/products/nativeApp'
import { ApplicationItem } from 'types/projects/workflow'

export interface ItemReferenceDataCreate {
  id: string
  type: NativeAppType
  versionId: MayBeNull<string>
}

export interface ApplicationPostItemRequest {
  projectId: string
  phaseId?: string
  activityId?: string
  applications: {
    coordinateX?: number
    coordinateY?: number
    referenceData: ItemReferenceDataCreate
    name?: string
    startDate?: string
    endDate?: string
    assignUser?: string
  }[]
}

export interface ApplicationPostCollectionResponse {
  phaseId: MayBeNull<string>
  activityId: MayBeNull<string>
  applications: ApplicationItem[]
}

export const createApplication = (body: ApplicationPostItemRequest) =>
  projectApi.post<ApplicationPostCollectionResponse>('/applications', body)
