import { projectApi } from 'api'
import { FilesDTO } from 'types/projects/Files'

interface FileOrder {
  fileId: string
  pinOrder: number
}

interface Params {
  projectId: string
  filesOrder: FileOrder[]
}

export const orderPinnedFilesApi = ({ projectId, filesOrder }: Params) =>
  projectApi.patch<FilesDTO[]>(`/projects/${projectId}/files/pinned`, { files: filesOrder })
