import { Task, TaskDueDate, TaskStatus } from 'types/projects/tasks'

type GroupedTasks = Record<string, { [key: string]: Task[] }>

export const alwaysShowStatuses = [
  TaskStatus.TO_DO,
  TaskStatus.IN_PROGRESS,
  TaskStatus.COMPLETED,
] as const satisfies TaskStatus[]
export const allStatuses = [...alwaysShowStatuses, TaskStatus.ARCHIVED]

export const groupTasks = <T extends Task, K extends 'status' | 'assignedUser' | 'phase'>({
  tasks,
  groupBy = 'status' as K,
  statuses,
  useExternalStatuses,
}: {
  tasks: T[]
  statuses: string[]
  groupBy?: K
  useExternalStatuses?: boolean
}): GroupedTasks => {
  const groupedTasks: GroupedTasks = {}

  for (const task of tasks) {
    let groupKey: string

    const statusKey = useExternalStatuses ? task.wrike?.externalStatusId! : task.status

    switch (groupBy) {
      case 'status':
        groupKey = 'status'
        break
      case 'assignedUser':
        groupKey = task.assignUser
        break
      case 'phase':
        groupKey = task.location?.phase?.id ?? 'others'
        break
      default:
        // Ensures exhaustiveness check for K
        throw new Error(`Invalid groupBy option: ${groupBy}`)
    }

    if (!(groupKey in groupedTasks)) {
      groupedTasks[groupKey] = {}
    }

    statuses.forEach(status => {
      if (!groupedTasks[groupKey][status]) {
        groupedTasks[groupKey][status] = []
      }
    })

    if (!Array.isArray(groupedTasks[groupKey][statusKey])) {
      groupedTasks[groupKey][statusKey] = []
    }

    groupedTasks[groupKey][statusKey] = [...groupedTasks[groupKey][statusKey], task]
  }

  return groupedTasks
}

export const datesRangeMapping: Record<TaskDueDate, string> = {
  [TaskDueDate.OVERDUE]: 'project.tasks.filters.dates_overdue',
  [TaskDueDate.THIS_WEEK]: 'project.tasks.filters.dates_this_week',
  [TaskDueDate.NEXT_WEEK]: 'project.tasks.filters.dates_next_week',
  [TaskDueDate.THIS_MONTH]: 'project.tasks.filters.dates_this_month',
  [TaskDueDate.NEXT_MONTH]: 'project.tasks.filters.dates_next_month',
  [TaskDueDate.THIS_YEAR]: 'project.tasks.filters.dates_this_year',
  [TaskDueDate.NEXT_YEAR]: 'project.tasks.filters.dates_next_year',
}
