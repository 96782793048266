import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteActivityLinkApi } from 'api/canvas/mutation/useDeleteActivityLinkApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ACTION_ANALYTICS, CanvasItemType } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { ExternalLink } from 'types/projects/workflow'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  activityId: string
  link: ExternalLink
}

const DeleteLinkModal = ({ activityId, link, isOpen, onClose, onCloseComplete }: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const [isDeleting, setDeleting] = useState(false)
  const { me } = useHasProjectRole()
  const { trackAction } = useTrackAction()
  const projectContext = useProject()

  const { mutateAsync: deleteLink } = useDeleteActivityLinkApi()

  const deleteLinkAction = useCallback(async () => {
    setDeleting(true)
    try {
      await deleteLink({ activityId, linkId: link.id! })
      await invalidateCanvas()
      onClose()

      showToast({ type: 'success', message: t('project.remove_link.toast_success_message')! })

      if (projectContext) {
        const { project } = projectContext
        const analyticsData = {
          ...getCommonProjectAnalyticsData(project, me),
          type: CanvasItemType.LINK,
        }

        trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DELETE, analyticsData)
      }
    } catch (e) {
      showToast({ type: 'error', message: t('project.remove_link.toast_error_delete_message')! })
    } finally {
      setDeleting(false)
    }
  }, [activityId, deleteLink, link.id, me, onClose, projectContext, showToast, t, trackAction])

  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete}>
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center" data-testid="delete-group-modal-title">
          {t('project.remove_link.delete_link')}
        </Flex>
      </WppTypography>
      <WppTypography slot="body" type="s-body" data-testid="delete-group-modal-text">
        <WppTypography>{t('project.remove_link.delete_confirm')}</WppTypography>
      </WppTypography>
      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} size="s" variant="secondary">
          {t('common.btn_cancel')}
        </WppButton>
        <WppButton loading={isDeleting} onClick={deleteLinkAction} size="s" variant="destructive">
          {t('common.btn_delete')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteLinkModal } = createProjectModal<Props>(DeleteLinkModal, 'delete-link-modal')
