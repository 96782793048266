// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UvDnD::part(card){padding:4px 6px 4px 8px}.UvDnD.ENzMI::part(card){padding:5px 8px;box-shadow:none}.J8CFS{width:80%}.J8CFS:hover{text-decoration:underline;cursor:pointer}.qlNni{pointer-events:none}.qlNni:hover{text-decoration:none;cursor:auto}.wYspC{pointer-events:none !important}.wYspC:hover{text-decoration:none !important;cursor:auto !important}.H8qyY{margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/attachedFile/AttachedFile.module.scss"],"names":[],"mappings":"AACE,mBACE,uBAAA,CAGF,yBACE,eAAA,CACA,eAAA,CAIJ,OACE,SAAA,CAEA,aACE,yBAAA,CACA,cAAA,CAIJ,OACE,mBAAA,CAEA,aACE,oBAAA,CACA,WAAA,CAIJ,OACE,8BAAA,CAEA,aACE,+BAAA,CACA,sBAAA,CAIJ,OACE,gBAAA","sourcesContent":[".fileCard {\n  &::part(card) {\n    padding: 4px 6px 4px 8px;\n  }\n\n  &.previewCard::part(card) {\n    padding: 5px 8px;\n    box-shadow: none;\n  }\n}\n\n.fileName {\n  width: 80%;\n\n  &:hover {\n    text-decoration: underline;\n    cursor: pointer;\n  }\n}\n\n.preview {\n  pointer-events: none;\n\n  &:hover {\n    text-decoration: none;\n    cursor: auto;\n  }\n}\n\n.disabled {\n  pointer-events: none !important;\n\n  &:hover {\n    text-decoration: none !important;\n    cursor: auto !important;\n  }\n}\n\n.externalLinkMenu {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileCard": `UvDnD`,
	"previewCard": `ENzMI`,
	"fileName": `J8CFS`,
	"preview": `qlNni`,
	"disabled": `wYspC`,
	"externalLinkMenu": `H8qyY`
};
export default ___CSS_LOADER_EXPORT___;
